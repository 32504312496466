import React, { Component } from 'react';

import { 
    Box,
    Grid,
    Container,
    Divider,
    InputLabel,
    Select,
    Card,
    CircularProgress,
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ReactComponent as CalendarIcon } from '../../assets/img/icons/calendar.svg';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import CustomLabel from '../../components/CustomFields/CustomLabel';
import CustomInput from '../../components/CustomFields/CustomInput';
import IconButton from "../../components/Button/IconButton";
import CustomButton from '../../components/CustomFields/CustomButton';
import { RMIUploader } from "react-multiple-image-uploader";
import queryString from 'query-string';
import dataUrlToBlob from 'dataurl-to-blob'

import apiUtil from "../../api/apiUtil";
import wmsApi from "../../api/wmsApi";
import Api from "../../api/api";
import moment from 'moment'
import { number } from 'yup';

class MovementForm extends Component {
    constructor (porps) {
        super(porps)
        this.state = {
            dataItemId: '',
            isLoading: false,
            reason: '',
            movement_type: "stock",
            stock_on_hand: '',
            new_stock: '',
            new_stockValidated: false,
            new_price: '',
            new_priceValidated: false,
            stock_movement: '',
            stock_movementValidated: false,
            price_movement: '',
            price_movementValidated: false,
            
        }
    }
    
    componentDidMount () {
        
            let urlQuery = queryString.parse(window.location.search);
            if (urlQuery.id !== undefined) {
              this.setState({
                dataItemId: urlQuery.id
              });
              this.callDetailsApi(urlQuery.id, (data) => {
                if(data) {
                    this.setState({
                        stock_on_hand: data.stock_on_hand,
                    })
                }
              })
            }
            
      
    }

    callDetailsApi = (id = '', callback = null) => {
        const  accessToken = localStorage.getItem("access_token");
    
        this.setState({
          isLoading: true
        }, () => {
          wmsApi.read('items/' + id, null, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                this.setState({
                  isLoading: false,
                }, () => {
                  if(callback){
                    callback((data && data.result) ? data.result : null);
                  }
                });
              }, (error, type) => {
                this.setState({
                  isLoading: false,
                }, () => {
                  if(callback){
                    callback(null, []);
                  }
                });
              });
          });
        });
      }
    

   
    
    callCreateApi = (row, callback = null) => {
        const  accessToken = localStorage.getItem("access_token");
    
        this.setState({
          isLoading: true
        }, () => {
          wmsApi.create('item_movements', row, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                this.setState({
                  isLoading: false,
                }, () => {
                  if(callback){
                    callback();
                  }
                });
              }, (error, type) => {
                this.setState({
                  isLoading: false,
                }, () => {
                  apiUtil.toast(error, 'error');
                });
              });
          });
        });
      }
    
      
      /* END API */

  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;

    this.setState({
        new_stockValidated: false,
        stock_movementValidated: false,
    });
    
    if(this.state.new_stock === ''){
      this.setState({
        new_stockValidated: true
      });
    }
    if(this.state.stock_movement === ''){
      this.setState({
        stock_movementValidated: true
      });
    }

  

    if (form.checkValidity() === true) {
        let data = {
            item_id: this.state.dataItemId,
            reason: this.state.reason,
            new_stock: this.state.new_stock,
            stock_movement: this.state.stock_movement,
            movement_type: this.state.movement_type,
        }
          this.callCreateApi(data, () => {
            this.setState({
              isLoading: false
            }, () => {
              this.props.history.push({
                pathname: '/items/item-movements',
              });
              apiUtil.toast('Successfully Adjuested Movement', 'success');
            });
          });
          
    } else {
        this.setState({
            isLoading: false
        });
    }
  }
    itemDetailsForm = () => {
        return <Box className={'job-details-step'} >
        <Grid container>
            <Grid item xs={12}>
                <CustomLabel
                    label={'Item Movement'}
                    weight={'bold'}
                    size={'md'}
                />
                <Divider light />
                <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={1}>
                        <CustomLabel
                            label={'Select Movement Type'}
                        />
                        </Box>
                        <RadioGroup aria-label="speed" name="speed" style={{display: 'block'}} value={this.state.movement_type} 
                        onChange={ (e) => {
                            this.setState({
                                movement_type: e.target.value,
                            });
                        }}
                        >
                            <FormControlLabel style={{ display: 'inline-block',  width: "99px" }}  value="stock" control={<Radio />} label="Stock" />
                            <FormControlLabel style={{ display: 'inline-block',  width: "99px"}}  value="price" control={<Radio />} label="Price" />
                        
                        </RadioGroup>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={2}>
                            <CustomInput
                                label={'Reason'}
                                placeholder={'Enter Reason here...'}
                                value={this.state.reason}
                                multiline={true}
                                onChange={(e) => {
                                    this.setState({
                                      reason: e.target.value
                                    });
                                }}
                            />
                        </Box>
                    </Container>
                </Grid>
                {/* <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={2}>
                        <CustomInput
                            label={'Select Movement Date'}
                            placeholder={'DD/MM/YYYY'}
                            color={'secondary'}
                            type={"date"}
                            endAdornment={(obj) => {
                                return <Box
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                >
                                    <CalendarIcon />
                                </Box>
                            }}
                            value={this.state.move}
                            error={this.state.inbound_dateValidated}
                            required={true}
                            onChange={(e) => {
                                this.setState({
                                    inbound_date: e.target.value,
                                    inbound_dateValidated: (e.target.value !== '') ? false : true,
                                });
                            }}
                        />
                        </Box>
                    </Container>
                </Grid> */}
                <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={2}>
                            <CustomInput
                                label={'Stock On Hand'}
                                readOnly={true}
                                value={this.state.stock_on_hand}
                                type={'number'}
                                onChange={(e) => {
                                    this.setState({
                                      stock_on_hand: e.target.value,
                                    });
                                }}
                            />
                        </Box>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={2}>
                            <CustomInput
                                label={'New Stock'}
                                placeholder={'Enter New Stock  here...'}
                                value={this.state.new_stock}
                                error={this.state.new_stockValidated}
                                required={true}
                                type={'number'}
                                onChange={(e) => {
                                    let stock_movement = Number(e.target.value) - Number(this.state.stock_on_hand)
                                    this.setState({
                                        new_stock: e.target.value,
                                        stock_movement: stock_movement
                                    });
                                }}
                            />
                        </Box>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={2}>
                            <CustomInput
                                label={'Stock Movement'}
                                placeholder={'Enter Stock Movement here...'}
                                value={this.state.stock_movement}
                                error={this.state.stock_movementValidated}
                                required={true}
                                type={'number'}
                                onChange={(e) => {
                                  let new_stock = Number(e.target.value) + Number(this.state.stock_on_hand)
                                    this.setState({
                                        new_stock: new_stock,
                                        stock_movement: e.target.value,
                                    });
                                }}
                            />
                        </Box>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={2} mb={5}>
                        <CustomButton 
                        type={'submit'}
                        color={'secondary'}
                        isLoading={this.state.isImgLoading}
                        ><Box pl={1} pr={1}>Submit</Box>
                        </CustomButton>
                        </Box>
                    </Container>
                </Grid>
            </Grid>
        </Grid>
        </Box>
    }
    render () {
        return <Box className="dashboard-page">
        <StickyPageHeader>
          <Container>
          <BreadCrumb />
          <h1 className="page-title">Movement Form</h1>
          </Container>
        </StickyPageHeader>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Container maxWidth="lg" >
                <Box mt={5}>
                    {
                  (!this.state.isLoading)
                  ?
                  <Card mt={5}>
                    {this.itemDetailsForm()}
                  </Card>
                  :
                  <Card>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
                      <CircularProgress size={35} />
                    </Box>
                  </Card>
                }
                </Box>
                
            </Container>

        </form>
        </Box>
    }
}
export default MovementForm;