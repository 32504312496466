import React, { Component } from "react";
import { withRouter, Link } from 'react-router-dom';

import {
  Modal,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

import moment from 'moment'
import * as XLSX from "xlsx";
import apiUtil from "../../../api/apiUtil";
import CSVfile from "../../../assets/files/item-template.xls"

let dimensions = {
  dimensions:
    {
      key1 : '',
      key2 : '',
      key3 : '',
      unit : '',
    }
  
};  
class ImportItemDialog extends Component {
  constructor(props) {
    super(props);
    
    let modal = ((props.modal === true || props.modal === false) ? props.modal : false);

    this.state = {
      modal: modal,
      file: '',
      errMessages: []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modal !== this.state.modal) {
      this.setState({
        modal: nextProps.modal
      });
    }
  }

  handleClose = () => {
    this.setState({
      modal: false,
      errMessages : []
    });
    this.props.onClose();
  }

  filePathset = event => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];
    this.setState({file: file});
    // if (file.type !== "application/vnd.ms-excel" && file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
    //   apiUtil.toast("Only excel files allowed!", "error");
    //   this.setState({file: ""});
    // } else {
    //   this.setState({file: file});
    // }
  }

  readFile = () => {
    let f = this.state.file;
    if (f === "") {
      apiUtil.toast("No file is selected yet.", "error");
      return false;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      const bstr = event.target.result;
      const wb = XLSX.read(bstr, { type: "binary"});
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false });

      if (data.length <= 0) {
        apiUtil.toast('Empty data!', 'error');
        return false;
      }

      let res = [];
      data.map(function(v,k){
        res.push(v.join("|||"));
      });

      let jsonData = this.convertToJson(res);
      let errMessages = []
      this.setState({errMessages})
      jsonData.forEach((row, i) => {
        if(row['name'] == '' || row['name'] == null) {
            errMessages.push(`Item  Name is empty at row ${i+2}.`)
        }

        if(row['sku'] == '' || row['sku'] == null) {
           errMessages.push(`SKU is empty at row ${i+2}.`)
        }
        if(row['expire_date'] == '' || row['expire_date'] == null) {
          errMessages.push(`Expire Date is empty at row ${i+2}.`)
        }

        if(row['customer'] == '' || row['customer'] == null) {
          errMessages.push(`Customer is empty at row ${i+2}.`)
        }

        // if(row['cost_prices'] != '' && isNaN(row['cost_prices'])) {
        //   errMessages.push(`Cost Price is invalid at row ${i+2}.`)
        // }
        // if(row['selling_prices'] != '' && isNaN(row['selling_prices'])) {
        //   errMessages.push(`Selling Price is invalid at row ${i+2}.`)
        // }
        // if(row['opening_stock'] != '' && isNaN(row['opening_stock'])) {
        //   errMessages.push(`Peining Stock is invalid at row ${i+2}.`)
        // }
      })
      
      if (errMessages.length > 0 ) {
        this.setState({errMessages})
      } else {
      this.props.onImport(jsonData);
      this.setState({modal: false});
      }
      
    };
    reader.readAsBinaryString(f);
  }

  convertToJson = csv => {
    let lines = csv;
    let result = [];
    let headers = lines[0].split("|||");

    for (let i = 1; i < lines.length; i++) {
      let obj = {
        // id: null,
        // delete: null
      };
      let currentline = lines[i].split("|||");

      for (let j = 0; j < headers.length; j++) {
        let key;

        switch(headers[j]) {
          case "Name":
            key = "name";
            break;
          // case "Brand":
          //   key = "brand";
          //   break;
          case "SKU":
            key = "sku";
            break;
          case "Type":
            key = "type";
            break;
          case "Weight (kg)":
            key = "weight";
            break;
          case "Dimensions":
            key = "dimensions";
            break;
          // case "Inbound Date (mm/dd/yyyy)":
          //   key = "inbound_date";
          //   break;
          // case "Cost Price":
          //   key = "cost_prices";
          //   break;
          // case "Selling Price":
          //   key = "selling_prices";
          //   break;
          // case "Opening Stock":
          //   key = "opening_stock";
          //   break;
          case "Image":
            key = "image_url";
            break;
          // case "Status":
          //   key = "status";
          //   break;
          case "Warehouse":
            key = "warehouse";
            break;
          case "Batch Id":
            key = "batch_id";
            break;
          case "Customer":
            key = "customer";
            break;
          case "Expire Date (mm/dd/yyyy)":
            key = "expire_date";
            break;
          case "Dimensions (length)":
            key = "value1";
            break;
          case "Dimensions (width)":
            key = "value2";
            break;
          case "Dimensions (height)":
            key = "value3";
            break; 
          case "Dimensions (unit)":
            key = "value4";
            break;       
          default:
            key = "";
        }

        if (key !== "") {
          if (key == "expire_date") { 
            obj[key] = moment(currentline[j]).format(apiUtil.getDefaultDateFormat())
          } else {
            obj[key] = currentline[j];
          }
        
          if (key == "value1") {
            dimensions.dimensions.key1 = currentline[j];
          }
          if (key == "value2") {
            dimensions.dimensions.key2 = currentline[j];
          }
          if (key == "value3") {
            dimensions.dimensions.key3 = currentline[j];
          }
          if (key == "value4") {
            dimensions.dimensions.unit = currentline[j];
          }
        }
      }
      obj["dimensions"] = JSON.stringify(dimensions.dimensions);
      result.push(obj);
    }

    
    return result;
  }

  setErrorMessages = () => {
    const errMessages = this.state.errMessages
      if(errMessages.length > 0) {
          return <Box clone pt={1} pb={1}>
            <Grid item xs={12}>
                {errMessages.map((err) => (
                <Box style={{margin:'1px'}}>
                  <Alert severity="error">
                      {err}
                  </Alert>
                  </Box>
                  ))} 
              </Grid>
            
              
          </Box>
      }
    
  }
  render(){
    return(
      <Modal isOpen={this.state.modal} toggle={this.handleClose} size="m">
        <ModalBody>
          <p><strong>To import Items from excel:</strong></p>
          <ol>
            <li>Download import template by clicking the link below</li>
            <li>Upload the sheet and click "Import Items" button.</li>
          </ol>
          <input
            type="file"
            id="file"
            ref="fileUploader"
            accept=".xls,.xlsx"
            onChange={this.filePathset.bind(this)}
          />
          <br />
          <Link to={CSVfile} target="_blank" download style={{ textDecoration: 'none' }}>Download Template</Link>
          {/* <a href="/CSVfile" download>Download Template</a> */}
          <br />
          {this.state.errMessages && (this.setErrorMessages())}
        </ModalBody>

        <ModalFooter>
          <Button color="info" size="sm" outline={true} onClick={() => this.readFile()} >
            Import Items
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ImportItemDialog;