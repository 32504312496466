import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Password from "./ChangePassword";

const ChangePassword = ({ match }) => (
    <Switch>
      <Route
        path={`${match.url}`}
        render={props => <Password {...props} />}
      />
      <Redirect to="/password" />
    </Switch>
);

export default ChangePassword;
