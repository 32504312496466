import React, { Component } from 'react';
import { AuthContext } from "../../components/Auth/AuthDataProvider.js";
import BreadCrumb from "../../components/Navs/Breadcrumb"
import { 
  Box,
  Grid,
  CircularProgress
} from '@material-ui/core';
import TempImg from "../../../src/assets/img/default_item.jpg"
import apiUtil from "../../api/apiUtil";
import wmsApi from "../../api/wmsApi";
import axios from 'axios';
import PieChart from './PieChart';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import { Button, Row, Col, Container, Card} from 'react-bootstrap';
import moment from 'moment';
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker.jsx';
import { Chart } from "react-google-charts";
import Loading from "react-fullscreen-loading";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import CustomLabel from '../../components/CustomFields/CustomLabel';
/**
 * http://lds-api.taskk.me/admin/api/orders/job/stats?start_date=2019-05-01&end_date=2020-11-23
 * http://lds-api.taskk.me/admin/api/dashboard/recent_activity/?start_date=2019-05-01&end_date=2020-11-23
 * http://lds-api.taskk.me/admin/api/dashboard/delivery_perday/?start_date=2019-05-01&end_date=2020-11-23
 */


class Dashboard extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let rangeDate = {
      startDate: moment().subtract(1,'months'),
      endDate: moment()
    };
  
    let rangeDateItem = localStorage.getItem(this.pageName + '_rangeDate');
    if(rangeDateItem && rangeDateItem !== ''){
      try {
        rangeDate = JSON.parse(rangeDateItem);
      }catch(e){}
    }

    this.state = {
      jobTab: 0,
      jobTabTable: 0,

      rangeDate: '',
      rangeDate: rangeDate,
      startDate: rangeDate.startDate,
      endDate: rangeDate.endDate,

      isLoadingStatus: false,
      status: [],
      tableStatus: [],
      statusSelected: null,
      order_status: null,
      isLoading: false,
      isLoadingTopSelling: false,
      isLoadingItem: false,
      isStatLoading: false,
      grouping: true,
      // limit: apiUtil.getDefaultPageSize(),
      limit: 10,
      data: [],
      dataWareHouse: [],
      dataItems: [],
      total: 0,
      page: 1,
      total_delivery: 0,
      openDialog: false,
      openDialogItem: null,

      dashboardData : '',
      graphData : [],
      topSellingData : [],

      openPreviewDialog: false,
      openPreviewDialogItem: null,

      stats : [],
      recent_activity : [],
      delivery_perday : [],
    }
  }
 
  componentDidMount() {
    // this.getWareHouse();
    this.callReadApi();
  }

  callReadApi() {

    let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
    let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

    let errorMessage = "";
    let status = 0;
    const {
      REACT_APP_WMS_API_URL
    } = process.env;

    this.setState({
      isLoading: true
      });
      const PATH = 'admin/api/';
      const api = "dashboard_item";
      let URL = REACT_APP_WMS_API_URL + PATH + api;
      // let token = window.localStorage.getItem('access_token');
      const { accessToken } = this.context;

    let param = {
        stock_on_hand_total: true,
        return_quantity_total: true,
        warehouse_total: true,
        inbound_total: true,
        outbound_total: true,
        top_selling_items: true,
        quantity_to_be_receive: true,
        quantity_received: true,
        committed_stock_total: true,
        low_stock_item_total: true,
        graph: true,
        start_date: startDate,
        end_date: endDate,
    };
    axios.get(
        // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
        URL,
        {
            params: param,
            data: param,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
        .catch(error => {
            try {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
                this.setState({
                  isLoading: false,
              });

            } catch (err) {
                errorMessage = 'Service Unavailable!'
                this.setState({
                  isLoading: false,
              });
            }
        }).then((response) => {
            // console.log(response);
            if (response != null) {
             
              const start = new Date(startDate);
              const end = new Date(endDate);
              let array =[]
              let loop = new Date(start);
              let tempResult = [];
              while (loop <= end) {
                // itemArray.filter(row => row.item_id == item.item_id)
                let arrayExit = response.data.result[0].orders_by_day.filter(row => moment(row.order_date).format('L') == moment(loop).format('L'))
                
                if(arrayExit.length > 0) {
                  array.push({
                    date: moment(loop).format('L'), 
                    order:  arrayExit[0].total_sale_per_day
                });
                } else {
                  array.push({
                    date: moment(loop).format('L'), 
                    order:  0
                });
                }
               
                let newDate = loop.setDate(loop.getDate() + 1);
                loop = new Date(newDate);
              }
                this.setState({
                    dashboardData: response.data.result[0],
                    topSellingData: response.data.result[0].top_selling_items,
                    graphData: array,
                    isLoading: false,
                }, ()=> {
                  // var rv = {};
                  // for (var i = 0; i < this.state.graphData.length; ++i) {
                  //   rv[i] = this.state.graphData[i];
                  // }
                  // console.log('ae', rv);
                });

            }

        });
}

callTopSellingApi() {

  let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
  let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

  let errorMessage = "";
  let status = 0;
  const {
    REACT_APP_WMS_API_URL
  } = process.env;

  this.setState({
      isLoadingTopSelling: true
    });
    const PATH = 'admin/api/';
    const api = "dashboard_item";
    let URL = REACT_APP_WMS_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');

  let param = {
      top_selling_items: true,
      start_date: startDate,
      end_date: endDate,
  };
  axios.get(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
      {
          params: param,
          data: param,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
          }
      })
      .catch(error => {
          try {
              status = error.response.status;

              if (error.response) {
                  // Request made and server responded
                  errorMessage = error.response.data;
              } else if (error.request) {
                  // The request was made but no response was received
                  errorMessage = error.request;
              } else {
                  // Something happened in setting up the request that triggered an Error
                  errorMessage = error.message;
              }
              this.setState({
                isLoadingTopSelling: false,
            });

          } catch (err) {
              errorMessage = 'Service Unavailable!'
              this.setState({
                isLoadingTopSelling: false,
            });
          }
      }).then((response) => {
          // console.log(response);
          if (response != null) {
              this.setState({
                  topSellingData: response.data.result[0].top_selling_items ,
                  isLoadingTopSelling: false,
              });
          }

      });
}

callGraphApi() {

  let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
  let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

  let errorMessage = "";
  let status = 0;
  const {
    REACT_APP_WMS_API_URL
  } = process.env;

  this.setState({
      isLoadingTopSelling: true
    });
    const PATH = 'admin/api/';
    const api = "dashboard_item";
    let URL = REACT_APP_WMS_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');

  let param = {
      graph: true,
      start_date: startDate,
      end_date: endDate,
  };
  axios.get(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
      {
          params: param,
          data: param,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
          }
      })
      .catch(error => {
          try {
              status = error.response.status;

              if (error.response) {
                  // Request made and server responded
                  errorMessage = error.response.data;
              } else if (error.request) {
                  // The request was made but no response was received
                  errorMessage = error.request;
              } else {
                  // Something happened in setting up the request that triggered an Error
                  errorMessage = error.message;
              }
              this.setState({
                isLoadingTopSelling: false,
            });

          } catch (err) {
              errorMessage = 'Service Unavailable!'
              this.setState({
                isLoadingTopSelling: false,
            });
          }
      }).then((response) => {
        if (response != null) {
             
          const start = new Date(startDate);
          const end = new Date(endDate);
          let array =[]
          let loop = new Date(start);
          let tempResult = [];
          while (loop <= end) {
            // itemArray.filter(row => row.item_id == item.item_id)
            let arrayExit = response.data.result[0].orders_by_day.filter(row => moment(row.order_date).format('L') == moment(loop).format('L'))
            
            if(arrayExit.length > 0) {
              array.push({
                date: moment(loop).format('L'), 
                order:  arrayExit[0].total_sale_per_day
            });
            } else {
              array.push({
                date: moment(loop).format('L'), 
                order:  0
            });
            }
           
            let newDate = loop.setDate(loop.getDate() + 1);
            loop = new Date(newDate);
          }
            this.setState({
                graphData: array,
                isLoading: false,
            }, ()=> {
              // var rv = {};
              // for (var i = 0; i < this.state.graphData.length; ++i) {
              //   rv[i] = this.state.graphData[i];
              // }
              // console.log('ae', rv);
            });

        }
      });
}

  customToolbar = (e) => {
    return <Box>
      <Grid container alignItems={'center'}>
        <Box clone>
          <Grid item xs={12} md={true}>
            <CustomLabel
              label={'Recent Activities'}
              size={'lg'}
            />
          </Grid>
        </Box>
        <Box clone textAlign={'right'} pb={2} pr={2}>
          <Grid item xs={12} md={2}>

          </Grid>
        </Box>
      </Grid>
    </Box>
  }

  // customStickyPageHeader = () => {
  //   return <Box clone pl={4} pr={4} height={'100%'} alignItems='center'>
  //     <Grid container alignItems='center'>
  //       <BreadCrumb />
  //       <Box clone pr={{ xs: 0, md: 1 }} pb={{ xs: 2, md: 0 }} pt={{xs: 2, md: 0}} style={{display:'grid', justifyContent:'flex-end' }}>
  //           <Grid item xs={12} md={9} xl={9} style={{display:'grid', justifyContent:'flex-end'}}>
  //             <CustomDateRangePicker
  //               range={this.state.rangeDate}
  //               onChange={(range) => {
  //                 this.setState({
  //                   rangeDate: range,
  //                 }, () => {
  //                   // this.callInitApis();
  //                 });
  //               }}
  //             />
  //           </Grid>
  //         </Box>
  //     </Grid>
  //   </Box>
  // }

  render() {
    const {stats, delivery_perday, total_delivery} = this.state
    return <Box>
      <StickyPageHeader>
        <Container>
          <Row className="d-flex align-items-center">
        <Col xs={12} md={6} lg={9}>
          <BreadCrumb />
          <h1 className="page-title">Dashboard</h1>
          </Col>
           <Col xs={12} md={6} lg={3}>
            <CustomDateRangePicker
              className={'select-dates-step'}
              color={'primary'}
              range={this.state.rangeDate}
              onChange={(range) => {
                localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));

                this.setState({
                  rangeDate: range,
                  startDate: range.startDate,
                  endDate: range.endDate
                }, () => {
                  this.callReadApi();
              });
              }}
            />
          </Col>
          </Row>
          </Container>
      </StickyPageHeader>
      {this.state.isLoading? 
       <Loading loading={this.state.isLoading} background="#f5f5f5" loaderColor="#2e86aa"/>
      :
      <Container fluid>
        <Row className="dashoard-data">
          <Col xs={12} md={6} lg={6} xl={8} className="pb-2">
            <Row>
            <Col xs={12} className="ps-5 pt-4">
          <div>ACTIVITY</div>
          </Col>
          <Col xs={6} md={6} lg={6} xl={3} className="justify-content-center d-flex py-3">
          <Card className="dashboard-data-card">
            <div className="dashboard-data-num">{this.state.dashboardData.warehouse_total ? this.state.dashboardData.warehouse_total  : 0}</div>
            <div  className='text-center'>Warehouse</div>
          </Card>
          </Col>
          <Col xs={6} md={6} lg={6} xl={3} className="justify-content-center d-flex py-3">
          <Card className="dashboard-data-card">
            <div className="dashboard-data-num">{this.state.dashboardData.inbound_total ? this.state.dashboardData.inbound_total : 0}</div>
            <div  className='text-center'>To Pack</div>
          </Card>
          </Col>
          <Col xs={6} md={6} lg={6} xl={3} className="justify-content-center d-flex py-3">
          <Card className="dashboard-data-card">
            <div className="dashboard-data-num">{this.state.dashboardData.outbound_total ? this.state.dashboardData.outbound_total : 0}</div>
            <div className='text-center'>Ready to be Delivered</div>
          </Card>
          </Col>
          <Col xs={6} md={6} lg={6} xl={3} className="justify-content-center d-flex py-3">
          <Card className="dashboard-data-card">
            <div className="dashboard-data-num">{this.state.dashboardData.stock_on_hand_total ? this.state.dashboardData.stock_on_hand_total : 0}</div>
            <div className='text-center'>Instock</div>
          </Card>
          </Col>
            </Row>
          </Col>

          <Col xs={12}  md={6} lg={6} xl={4} className="border-left-sum align-items-center p-4">
            <div className="py-3">INVENTORY SUMMARY</div>
            <div className="border rounded p-3 m-2 bg-white d-flex justify-content-between"><span style={{fontSize:'14px'}}>QUANTITY IN HAND</span><span className="summany-right text-end fw-bold" style={{fontSize:'14px'}}>{this.state.dashboardData.stock_on_hand_total ? this.state.dashboardData.stock_on_hand_total : 0}</span></div>
            <div className="border rounded p-3 m-2 bg-white d-flex justify-content-between"><span style={{fontSize:'14px'}}>QUANTITIES TO BE RECEIVED</span><span className="summany-right text-end fw-bold" style={{fontSize:'14px'}}>{this.state.dashboardData.quantity_to_be_receive ? this.state.dashboardData.quantity_to_be_receive : 0}</span></div>
            <div className="border rounded p-3 m-2 bg-white d-flex justify-content-between"><span style={{fontSize:'14px'}}>QUANTITIES RECEIVED</span><span className="summany-right text-end fw-bold" style={{fontSize:'14px'}}>{this.state.dashboardData.quantity_received ? this.state.dashboardData.quantity_received : 0}</span></div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12} lg={6} className="justify-content-center d-flex py-3">
          <Card style={{ width: '95%' }} >
            <Card.Body className="border rounded">
              <Card.Title className="ps-0 ms-0"><div>PRODUCT DETAILS</div></Card.Title>
              <hr />
                  <Row className="align-items-center d-flex">
                    <Col xs={8}>
                      <Row className="align-items-center d-flex">
                          <Col xs={8} className="py-3">
                          <span className="w-100 text-warning fs-5">Low Stock Items</span>
                        </Col>
                        <Col xs={4}><a href="#" className="fs-3 text-warning fw-bold">{this.state.dashboardData.low_stock_item_total ? this.state.dashboardData.low_stock_item_total : 0}</a></Col>
                      </Row>
                      {/* <Row className="align-items-center d-flex">
                          <Col xs={8} className="py-3">
                          <span className="w-100 fs-5">Committed Stock</span>
                        </Col>
                        <Col xs={4}><a href="#" className="fs-3 text-dark fw-bold">{this.state.dashboardData.committed_stock_total ? this.state.dashboardData.committed_stock_total : 0}</a></Col>
                      </Row> */}
                      <Row className="align-items-center d-flex">
                          <Col xs={8} className="py-3">
                          <span className="w-100 fs-5 text-danger ">Number of SKUs</span>
                        </Col>
                        <Col xs={4}><a href="#" className="fs-3 text-danger fw-bold">{this.state.dashboardData.number_of_skus ? this.state.dashboardData.number_of_skus : 0}</a></Col>
                      </Row>
                    </Col>
                    <Col xs={4} className="p-0 m-0">
                        <p className="w-100 fs-6 text-center">Active Items</p>
                        <PieChart total={this.state.dashboardData.inbound_total + this.state.dashboardData.low_stock_item_total } all_item={this.state.dashboardData.inbound_total} low_stock={this.state.dashboardData.low_stock_item_total}/>
                    </Col>
                  </Row> 
            </Card.Body>
          </Card>
          </Col>
          <Col xs={12} md={12} lg={6} className="justify-content-center d-flex py-3">
            <Card style={{ width: '95%' }} >
            <Card.Body className="border rounded">
              <Card.Title className="d-flex justify-content-between align-items-center">
                <div  className="ps-0 ms-0"><div>TOP SELLING ITEMS</div></div>
                <div>
                <CustomDateRangePicker
                  className={'select-dates-step'}
                  color={'primary'}
                  range={this.state.rangeDate}
                  onChange={(range) => {
                    localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));

                    this.setState({
                      rangeDate: range,
                      startDate: range.startDate,
                      endDate: range.endDate
                    }, () => {
                      this.callTopSellingApi();
                  });
                  }}
                />
                </div>
                </Card.Title>
              <hr />
              {this.state.isLoadingTopSelling?
              <Row className="d-flex align-items-center justify-content-center">
              <CircularProgress size={60} />
              </Row>
              :
              this.state.topSellingData != '' ? <Row>
                <Col xs={8}>
                  <Row>
                  {this.state.topSellingData && this.state.topSellingData.map((tt, index) => (
                    
                    <Col xs={6} className="d-flex flex-column justify-content-center align-items-center ">
                      <a href={`/items/item-detail?id=${tt.item.id}`}><img src={TempImg} width="100%" alt="temp" className="text-center p-2"/></a>
                      <a href={`/items/item-detail?id=${tt.item.id}`}><p className="text-muted text-center py-2">{tt.item ? tt.item.name : ''}</p></a>
                      <p className="fs-5 fw-regular text-center"> {`Sold Count ${tt.tot}` }</p>
                    </Col>
                    
                    ))}

                  </Row>

                   

                </Col>
                
                <Col xs={4} className="d-flex flex-column justify-content-center align-items-center" style={{borderLeft:'1px solid #cccccc'}}>
                  <p className="fs-6 fw-regular text-center">SLOWEST MOVING ITEMS</p>
                  {this.state.dashboardData.slowest_movement_item ? <a href={`/items/item-detail?id=${this.state.dashboardData.slowest_movement_item.id}`}><img src={TempImg} width="70%" alt="temp" className="text-center p-2"/>
                  <p className="text-muted text-center py-2">{this.state.dashboardData.slowest_movement_item ? this.state.dashboardData.slowest_movement_item.name: ''}</p></a> : "No Item found for this Date!!"}
                  
                </Col></Row>
                : <Row>
                <Col xs={12} className="d-flex justify-content-center align-items-center ">
                  no items are found for this Date!!
                </Col>
                </Row>
                }
            </Card.Body>
          </Card>
          </Col>
        </Row>

        {/* <Card>
          <Row >
            <Col className="d-flex align-items-center justify-content-between">
        <div>SALES ORDER SUMMARY (IN SGD)</div>
        <div>
        <CustomDateRangePicker
              className={'select-dates-step width-adjust-time-picker'}
              color={'primary'}
              range={this.state.rangeDate}
              onChange={(range) => {
                localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));

                this.setState({
                  rangeDate: range,
                  startDate: range.startDate,
                  endDate: range.endDate
                }, () => {
                  this.callReadApi();
              });
              }}
            />
        </div>
        </Col>
        </Row>


        <Row className="border m-2 rounded" style={{height:'400px'}}>
          <Col xs={8} className="py-4" >
          <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width='100%'
                height='100%'
                data={this.state.graphData}
               
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="order" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </Col>
          <Col xs={4} className="mt-4">
            <div>Total Sale</div>
            <div className="px-4 py-2 m-2 saledata w-50">
              <div className="d-flex align-items-center">
              <p className='circleSpan'> </p>
              <p className="ps-3">Direct Sale</p>
              </div>
              <p className="fs-5 fw-bolder">SGD960.00</p>
            </div>
          </Col>
        </Row>
       
        </Card> */}
      </Container>
      }
    </Box>;
  }
}

export default Dashboard;
