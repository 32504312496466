import React, { Component } from 'react';

import { 
    Box,
    CircularProgress,
    Grid,
    Card,
    Container,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import IconButton from "../../components/Button/IconButton";
import apiUtil from "../../api/apiUtil";
import wmsApi from "../../api/wmsApi";
import queryString from 'query-string';
import moment from 'moment'
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={2}>
            {children}
          </Box>
        )}
      </div>
    );
}
const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`
    };
}

class CustomerDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: '',
            dataDepartment: [],
            isLoading: false,
            tabValue: 0,
            secondTab: 0
        }
    }
    
    componentDidMount() {
        let urlQuery = queryString.parse(window.location.search);
        if (urlQuery.id !== undefined) {
            this.setState({
              dataId: urlQuery.id
            });
            this.callDetailsApi(urlQuery.id, (data) => {
                if(data) {
                    this.setState({
                        data:data,
                        dataDepartment: data.departments
                    })
                }
            })
        }
    }
    callDetailsApi = (id = '', callback = null) => {
        const  accessToken = localStorage.getItem("access_token");

        this.setState({
            isLoading: true
        }, () => {
            wmsApi.read('customers/' + id, null, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if(callback){
                    callback((data && data.customer) ? data.customer : null);
                    }
                });
                // console.log('data result', data.result);
                }, (error, type) => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if(callback){
                    callback(null, []);
                    }
                });
                });
            });
        });
    }

    callParentWareHouseApi = ( callback = null) => {
        const  accessToken = localStorage.getItem("access_token");
        let data ={
            parent_warehouse_id : null,
        }
        this.setState({
            isLoading: true
        }, () => {
            wmsApi.read('warehouses', data, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if(callback){
                    callback((data && data.result) ? data.result : null);
                    }
                });
                // console.log('data result', data.result);
                }, (error, type) => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if(callback){
                    callback(null, []);
                    }
                });
                });
            });
        });
    }

    handleChange = (event, newValue) => {
        this.setState({
            tabValue: newValue
        })
    }

    itemDetailPage = () => {
        return <>
        <Box mt={5}>
            <Grid container xs={12}>
                <Grid item xs={8}>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Company Name</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.company_name  ? this.state.data.company_name :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Company Code</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.company_code ? this.state.data.company_code :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Postal Code</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.customer_addresses &&  this.state.data.customer_addresses.length > 0 && this.state.data.customer_addresses[0].postal_code  ? this.state.data.customer_addresses[0].postal_code :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Contact Number 1</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.contact_no1 ? this.state.data.contact_no1 :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Contact Number 2</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.contact_no2 ? this.state.data.contact_no2 :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Fax</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.company_fax ? this.state.data.company_fax :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>WMS PIC Email</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.company_email ? this.state.data.company_email :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    
                </Grid>
                
            </Grid>
            
        </Box>
        
        </>
    }

    getDetails = () => {
        return <>
        <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={3}>
        <Tabs value={this.state.tabValue} onChange={this.handleChange} aria-label="basic tabs example">
          <Tab label="Company Details" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <TabPanel value={this.state.tabValue} index={0}>
        {this.itemDetailPage()}
      </TabPanel>
    </Box>
        </>
    }

    getCompanyAddress = () => {
        return <>
        <Box mt={1}>
            <Grid container xs={12}>
                <Grid item xs={8}>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Address Line 1</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.customer_addresses && this.state.data.customer_addresses.length > 0 && this.state.data.customer_addresses[0].address_line1 ? this.state.data.customer_addresses[0].address_line1 :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Address Line 2</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.customer_addresses && this.state.data.customer_addresses.length > 0 && this.state.data.customer_addresses[0].address_line2 ? this.state.data.customer_addresses[0].address_line2 :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>City</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.customer_addresses && this.state.data.customer_addresses.length > 0 && this.state.data.customer_addresses[0].city ? this.state.data.customer_addresses[0].city :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Contact Name</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.customer_addresses && this.state.data.customer_addresses.length > 0 && this.state.data.customer_addresses[0].contact_name ? this.state.data.customer_addresses[0].contact_name :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    
                </Grid>
                
            </Grid>
            
        </Box>
        
        </>
    }

    getDepartment = () => {
        return <>
        <Box mt={1}>
            <Grid container xs={12}>
                <Grid item xs={12}>
                    {this.state.dataDepartment.map((department, i)=>{
                        return <Card className='p-3 mb-3'>
                            <Grid container xs={12} >
                                <Grid item xs={4} className='item-Details'>
                                    <Box mt={2}>
                                    <Typography variant='body2' style={{fontWeight:'900'}}>Department #{i+1}</Typography>
                                    </Box>
                                    
                                </Grid>
                                <Grid item xs={4}>
                                    <Box mt={2} style={{wordWrap: "break-word"}}>
                                    <Typography variant='body2' style={{fontWeight:'1000'}} >{department == '' || department == null ? '-' : department.name  } </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            {department.contact_people.map((person, index)=> <div>
                                <Grid container xs={12}>
                                    <Grid item xs={12} className='item-Details'>
                                        <Box mt={4}>
                                            <Typography variant='body2' style={{fontWeight:'900'}} >Contact Person {index + 1}</Typography>
                                        </Box>
                                   </Grid>
                                </Grid>
                                <div className='d-flex'>
                            <Grid container xs={4} >
                                <Grid item xs={12} className='item-Details'>
                                    <Box mt={2}>
                                    <Typography variant='body2' style={{fontWeight:'900'}} >Contact Person Name</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mt={2} style={{wordWrap: "break-word", padding: '4px'}}>
                                    <Typography variant='body2' >{person == '' || person == null ? '_' : `${person.first_name? person.first_name : ''} ${person.last_name? person.last_name : ''}`} </Typography>
                                    </Box>
                                    
                                </Grid>
                            </Grid>
                            <Grid container xs={4} >
                                <Grid item xs={12} className='item-Details'>
                                    <Box mt={2} >
                                    <Typography variant='body2' style={{fontWeight:'900'}} >Contact Number </Typography>
                                    </Box>
                                    
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mt={2} style={{wordWrap: "break-word",  padding: '4px'}}>
                                    <Typography variant='body2' >{person == '' || person == null ? '_' : (person.contact_no? person.contact_no: '')  } </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container xs={4} >
                                <Grid item xs={12} className='item-Details'>
                                    <Box mt={2}>
                                    <Typography variant='body2' style={{fontWeight:'900'}} >Contact Email</Typography>
                                    </Box>
                                    
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mt={2} style={{wordWrap: "break-word",  padding: '4px'}}>
                                    <Typography variant='body2' >{person == '' || person == null ? '_' : (person.email? person.email : '') } </Typography>
                                    </Box>
                                    
                                </Grid>
                            </Grid>
                            </div>
                            {/* <hr /> */}
                            </div>
                            )}
                            </Card>
                       
                        }
                    )}
                </Grid>
                
            </Grid>
            
        </Box>
        
        </>

    }

    getAdminAccount = () => {

        return <>
        <Box mt={1}>
            <Grid container xs={12}>
                {this.state.data.admins == '' || this.state.data.admins == null ?
                <Grid item xs={8} > There is no admin </Grid>
                :
                <Grid item xs={8}>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>First Name</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.admins == '' || this.state.data.admins == null ? "-" : this.state.data.admins[0].first_name } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Last Name </Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.admins == '' || this.state.data.admins == null ? "-" :  this.state.data.admins[0].last_name } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Email</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.admins == '' || this.state.data.admins == null ? "-" : this.state.data.admins[0].email } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    
                </Grid>
                }
            </Grid>
            
        </Box>
        
        </>
    }

    handelTabCahnge = (event, newValue) =>{
        this.setState({
            secondTab: newValue
        })
    }
    getTabDetails = () => {
        return <>
        <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={3}>
        <Tabs value={this.state.secondTab} onChange={this.handelTabCahnge} aria-label="basic tabs example">
            <Tab label="Company Address" {...a11yProps(0)} />
            <Tab label="Department" {...a11yProps(1)} />
            <Tab label="Admin" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={this.state.secondTab} index={0}>
        {this.getCompanyAddress()}
      </TabPanel>
      <TabPanel value={this.state.secondTab} index={1}>
        {this.getDepartment()}
      </TabPanel>
      <TabPanel value={this.state.secondTab} index={2}>
        {this.getAdminAccount()}
      </TabPanel>
    </Box>
        </>

    }
    render() {
        return <Box><StickyPageHeader>
            <Container>
                <BreadCrumb />
                <h1 className="page-title">Company Details</h1>
                <IconButton className="btn-theme btn-theme-1" label="Add New" icon="add" onClick={() => this.props.history.push('./create-customer')} />
                <IconButton className="btn-theme btn-theme-1" label="Edit " icon="edit" onClick={() => this.props.history.push('./edit-customer?id='+this.state.dataId)} />
                </Container>
            </StickyPageHeader>
            <Container maxWidth="lg" >
                <Box mt={2}>
                    {
                  (!this.state.isLoading)
                  ?
                  <Box mt={2} >
                   {/* <Typography variant="h5"> {this.state.data.name}</Typography> */}
                   {this.getDetails()}
                   {this.getTabDetails()}
                  </Box>
                  :
                  <Card>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
                      <CircularProgress size={35} />
                    </Box>
                  </Card>
                }
                </Box>
                
            </Container>
      </Box>

    }
}

export default CustomerDetails;