import React, { Component } from 'react';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.js";
import apiUtil from "../../../api/apiUtil.js";
import wmsApi from "../../../api/wmsApi.jsx";

import { 
  Box,
  Grid,
  Container
} from '@material-ui/core';

import CustomInput from '../../../components/CustomFields/CustomInput.js';
import CustomLabel from '../../../components/CustomFields/CustomLabel.js';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';

export class WarehouseForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);


    this.state = {
        isLoading: false,
        name: '',
        location: '',
        postalCode:"",
        address: '',
        contactNumber: '',
        locationValidated: false,
        nameValidated: false,
        addressValidated: false,
        postalCodeValidated: false,
        contactNumber: false,

    }
  }
  

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.state.isLoading) {
        let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
        this.setState({
            isLoading: isLoading
        });
    }
    // if (nextProps.item !== this.state.item) {
    //     let item = (nextProps.item) ? nextProps.item : null;
    //     this.setState({
    //         item: item
    //     });
    // }
  }


  /* API */
  callCreateApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      wmsApi.create('warehouses', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            if(error && error !== ''){
                apiUtil.toast(error, 'warning', 'error');
            }

            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END API */


  /* FORM */
  setForm = () => {
    return <Box>
        <Grid container>
          <Grid item xs={12} p={4}>
              <Grid item xs={12}>
                  <Container maxWidth={'xs'}>
                      <Box clone pt={2}>
                          <CustomInput
                              label={'Name'}
                              placeholder={'Enter Name here...'}
                              value={this.state.name}
                              error={this.state.nameValidated}
                              required={true}
                              onChange={(e) => {
                                  this.setState({
                                      name: e.target.value,
                                      nameValidated: (e.target.value !== '') ? false : true,
                                  });
                              }}
                          />
                      </Box>
                  </Container>
              </Grid>
              <Grid item xs={12}>
                  <Container maxWidth={'xs'}>
                      <Box clone pt={1}>
                          <CustomInput
                              label={'Location'}
                              placeholder={'Enter location here...'}
                              value={this.state.location}
                              error={this.state.locationValidated}
                              required={true}
                              onChange={(e) => {
                                  this.setState({
                                      location: e.target.value,
                                      locationValidated: (e.target.value !== '') ? false : true,
                                  });
                              }}
                          />
                      </Box>
                  </Container>
              </Grid>
              <Grid item xs={12}>
                  <Container maxWidth={'xs'}>
                      <Box clone pt={1}>
                          <CustomInput
                              label={'Postal Code'}
                              placeholder={'Enter postal code here...'}
                              value={this.state.postalCode}
                              error={this.state.postalCodeValidated}
                              type={'number'}
                              onChange={(e) => {
                                  this.setState({
                                    postalCode: Math.max(0, parseInt(e.target.value) ).toString().slice(0,6),
                                  });
                              }}
                          />
                      </Box>
                  </Container>
              </Grid>
              <Grid item xs={12}>
                  <Container maxWidth={'xs'}>
                      <Box clone pt={1}>
                          <CustomInput
                              label={'Address'}
                              placeholder={'Enter address here...'}
                              error={this.state.addressValidated}
                              value={this.state.address}
                              onChange={(e) => {
                                  this.setState({
                                      address: e.target.value,
                                      addressValidated: (e.target.value !== '') ? false : true,
                                  });
                              }}
                          />
                      </Box>
                  </Container>
              </Grid>
              <Grid item xs={12}>
                  <Container maxWidth={'xs'}>
                      <Box clone pt={1}>
                          <CustomInput
                              label={'Contact Number'}
                              placeholder={'Enter Contact Number here...'}
                              error={this.state.contactNumberValidated}
                              value={this.state.contactNumber}
                              onChange={(e) => {
                                  this.setState({
                                      contactNumber: e.target.value,
                                  });
                              }}
                          />
                      </Box>
                  </Container>
              </Grid>
          </Grid>
      </Grid>
    </Box>
  }
  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'center'} >
            <Box clone textAlign={'right'} pr={2}>
                <Grid item xs={true}>
                    <CustomButton 
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={'auto'}>
                    <CustomButton 
                        type={'submit'}
                        color={'secondary'} 
                        isLoading={this.state.isLoading}
                    >
                        Add Warehouse
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;

    this.setState({
        nameValidated: false,
        locationValidated: false,
        addressValidated: false,

        postalCodeValidated: false,
        contactNumberValidated: false,
    });
    
    if(this.state.name === ''){
      this.setState({
        nameValidated: true
      });
    }
    if(this.state.location === ''){
      this.setState({
        locationValidated: true
      });
    }
    if(this.state.address === ''){
      this.setState({
        addressValidated: true
      });
    }

    if(this.state.postalCode === ''){
        this.setState({
            postalCodeValidated: true
        });
    }
    let contactNumber = this.state.contactNumber;
    let phoneRegEx = /^[+]?\d+$/;
    if(!phoneRegEx.test(contactNumber) || contactNumber === '' || contactNumber.length < 8 || contactNumber.length > 13 ){

      this.setState({
        contactNumberValidated: true,
        isLoading: false,
      });
      return
    }

    if (form.checkValidity() === true) {
        this.callCreateApi(this.getForm(), (data) => {
            if(data){
                if(this.props.onSave){
                    this.props.onSave( data);
                }
            }
        });
        
    } else {
        this.setState({
            isLoading: false
        });
    }
  }

  
  getForm = () => {
        let contactNumber = this.state.contactNumber.length === 8 ? '+65' +this.state.contactNumber : this.state.contactNumber ;
        let data = {
            name: this.state.name,
            location: this.state.location,
            address: this.state.address,
            postal_code: this.state.postalCode,
            contact_number: contactNumber,
    }

    return data;
  }
  /* END SUBMIT */
      
  render() {
    return <Box>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container>
                <Box clone pt={2} pb={2}>
                    <Grid item xs={12}>
                        {this.setForm()}
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        {this.setButtons()}
                    </Grid>
                </Box>
            </Grid>
        </form>
    </Box>
  }
}

export default WarehouseForm;