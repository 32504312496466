import axios from 'axios';

const { 
  REACT_APP_WMS_API_URL
} = process.env;

const salesOrderApi = {
	get: async (request) => {
		let errorMessage = "";
    let params = "handling_in=true&customer_id=" + request.customer_id + "&warehouse_id=" + request.warehouse_id +`&start_date=${request.start_date}&end_date=${request.end_date}`;

    if (request.optional_filter_by === "serial_no") {
      params = params + "&serial_no=" + request.optional_query;
    }

    if (request.inbound_order ) {
      params = params + "&inbound_order=" + request.inbound_order;
    }

    if (request.outbound_order ) {
      params = params + "&outbound_order=" + request.outbound_order;
    }

    if (request.optional_filter_by === "lot_no") {
      params += "&reference_no=" + request.optional_query;
    }

    if (request.optional_filter_by === "part_no") {
      params += "&part_no=" + request.optional_query;
    }

    if (request.optional_filter_by === "reference_no") {
      params += "&reference_no=" + request.optional_query;
    }

    if (request.optional_filter_by === "container_receipt") {
      params += "&container_receipt=" + request.optional_query;
    }

    if (request.optional_filter_by === "hawb") {
      params += "&hawb=" + request.optional_query;
    }

    const URL = REACT_APP_WMS_API_URL + "admin/api/orders?" + params;

    let access_token = localStorage.getItem("access_token");
    let result = [];

    await axios.get(
      URL,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
    .then(res => {
      if (res.status === 200 && res.data.result.length > 0) {
        for (let i = 0; i < res.data.result.length; i++) {
          result[i] = res.data.result[i];
          // result[i]['end_user_name'] = res.data.result[i].end_user.end_user;
        }
      }
    }).catch( error => {
      errorMessage = error.message;
    });

    return { data: result, errorMessage: errorMessage };
	},

  show: async (id) => {
    let errorMessage = "";
    let status = 200;

    const URL = REACT_APP_WMS_API_URL + "admin/api/orders/" + id;

    let access_token = localStorage.getItem("access_token");

    const getResult = await axios.get(
      URL,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
    .catch( error => {
      status = error.response.status;
      errorMessage = error.message;
    });

    return { data: getResult.data, errorMessage: errorMessage, status: status};
  },

  create: async (request) => {
    let errorMessage = "";
    let status = 200;

    const URL = REACT_APP_WMS_API_URL + "admin/api/orders";

    let access_token = localStorage.getItem("access_token");

    const result = await axios.post(
      URL,
      request,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
    .catch( error => {
      status = error.response.status;
      errorMessage = error.message;
    });
    return { data: result, errorMessage: errorMessage, status: status};
  },

  update: async (id, request) => {
    let errorMessage = "";
    let status = 200;
    let error = ''

    const URL = REACT_APP_WMS_API_URL + "admin/api/orders/" + id;
    const access_token = localStorage.getItem("access_token");

    const result = await axios.put(
      URL,
      request,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${access_token}`
        }
      })
    .catch( error => {
      status = error.response.status;
      errorMessage = error.response.data ? error.response.data.error : error.message;
    });

    return { data: result, errorMessage: errorMessage, status: status};
  },

  delete: async (id) => {
    let errorMessage = "";
    let status = 0;

    const URL = REACT_APP_WMS_API_URL + "admin/api/orders/" + id;
    const access_token = localStorage.getItem("access_token");

    const result = await axios.delete(
      URL,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${access_token}`
        }
      })
    .catch( error => {
      status = error.response.data.status;
      errorMessage = error.response.error;
    });

    return { data: result.data, errorMessage: errorMessage, status: status};
  },

  getDetail: async (params) => {
    let errorMessage = "";
    let status = 200;

    const URL = REACT_APP_WMS_API_URL + "admin/api/order_details";
    let access_token = localStorage.getItem("access_token");

    const getResult = await axios.get(
      URL,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        },
        params: params
      })
    .catch( error => {
      status = error.response.status;
      errorMessage = error.message;
    });

    return { data: getResult.data, errorMessage: errorMessage, status: status};
  }
}

export default salesOrderApi;