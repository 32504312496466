import React, { Component } from 'react';
import uuid from 'react-uuid'

import { AuthContext } from "../../components/Auth/AuthDataProvider";
import apiUtil from '../../api/apiUtil';
import Api from "../../api/api";

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import { 
  Box,
  IconButton,
  CircularProgress,
  Typography,
} from '@material-ui/core';

import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import defImg from '../../assets/img/default_image.jpg';


class CustomCropper extends Component {
	static contextType = AuthContext;
  
    constructor(props){
        super(props);

        this.labelRef = React.createRef();
        this.cropper = null;

        this.id = uuid();

        this.defaultImg = (props.defaultImg) ? props.defaultImg : defImg;
        let initialImg = (props.initialImg) ? props.initialImg : this.defaultImg;
        let src = (props.src) ? props.src : null;
        let aspectRatio = (props.aspectRatio) ? props.aspectRatio : 1;
        let width = (props.width) ? props.width : 200;
        let height = (props.height) ? props.height : 200;
        let dragMode = (props.dragMode) ? props.dragMode : 'move';
        let viewMode = (props.viewMode || props.viewMode === 0) ? props.viewMode : 0;
        let path = (props.path) ? props.path : '';
        let className = (props.className) ? props.className : '';

        let guides = ((props.guides === true || props.guides === false) ? props.guides : true);
        let responsive = ((props.responsive === true || props.responsive === false) ? props.responsive : true);
        let restore = ((props.restore === true || props.restore === false) ? props.restore : true);
        let modal = ((props.modal === true || props.modal === false) ? props.modal : true);
        let center = ((props.center === true || props.center === false) ? props.center : true);
        let movable = ((props.movable === true || props.movable === false) ? props.movable : true);
        let disable = ((props.disable === true || props.disable === false) ? props.disable : false);
        let isCircle = ((props.isCircle === true || props.isCircle === false) ? props.isCircle : true);
        

        this.state = {
            className: className,

            initialImg: initialImg,
            src: src,
            selectedFile: null,
            aspectRatio: aspectRatio,
            width: width,
            height: height,
            dragMode: dragMode,
            viewMode: viewMode,

            guides: guides,
            responsive: responsive,
            restore: restore,
            modal: modal,
            center: center,
            movable: movable,
            disable: disable,
            isCircle: isCircle,

            path: path,
            isUploading: false,
            progress: 0,
        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.isLoading !== this.state.isLoading) {
            this.setState({
                isLoading: nextProps.isLoading
            });
        }
        if (nextProps.initialImg !== this.state.initialImg) {
            let initialImg = (nextProps.initialImg) ? nextProps.initialImg : this.defaultImg;
            this.setState({
                initialImg: initialImg
            });
        }
    }

  
    /* API */
    callUploadFilesApi = async (file) => {
        const { accessToken } = this.context;

        let result = "";

        this.setState({
            disabled: true,
            isUploading: true,
            progress: 0,
        });

        if(file){
            let fName = (this.state.selectedFile && this.state.selectedFile.name !== '') ? this.state.selectedFile.name : '';
            let fileExt = fName.split('.').pop();
            let filename = fName.substr(0, fName.lastIndexOf("."))
            
            let getResult = await Api.uploadFile(
                'upload',
                file, 
                'AUTH',
                this.state.path, 
                filename,
                fileExt,
                accessToken, 
                (progress) => {
                    this.setState({
                        progress: progress
                    });
                }
            );

            apiUtil.parseResult(getResult, (data) => {
                result = data;
            }, (error) => {
                apiUtil.toast(error, 'check_circle', 'error');
            });

            this.setState({
                disabled: false,
                isUploading: false,
                progress: null,
            });
        } else {
            apiUtil.toast('Please select a file!', 'check_circle', 'error');
        }

        return result;
    }
    /* END API */


    /* EVENTS */
    crop = () => {
        this.setState({
            initialImg: this.cropper.getCroppedCanvas().toDataURL()
        }, () => {
            this.cropper.getCroppedCanvas().toBlob((blob) => {
                this.callUploadFilesApi(blob).then(result => {
                    if(this.props.onUpload){
                        this.props.onUpload(result);
                    }
                    this.close();
                });
            });
        });
    }
    close = () => {
        this.setState({
            src: null,
            selectedFile: null,
        }, () => {
            if(this.props.onEnd){
                this.props.onEnd();
            }
        });
    }
    /* END EVENTS */


    /* ELEMENTS */
    setCropper = () => {
        return <Box className={'cropper-wrapper'}>
            <Cropper
                onInitialized={(cropper) => {
                    this.cropper = cropper;
                }}

                src={this.state.src}
                style={{
                    width: this.state.width,
                    height: this.state.height,
                    margin: '0 auto',
                    // position: 'absolute',
                }}
                aspectRatio={this.state.aspectRatio}
                dragMode={this.state.dragMode}
                viewMode={this.state.viewMode}

                responsive={this.state.responsive}
                guides={this.state.guides}
                restore={this.state.restore}
                modal={this.state.modal}
                center={this.state.center}
                movable={this.state.movable}
                disable={this.state.disable}
            />
            {this.setProgressCircle()}
        </Box>
    }
    setDefaultImg = () => {
        return <Box className={"default-image"}
            onClick={() => {
                this.labelRef.current.click();
            }}
        >
            <img 
                style={{
                    width: this.state.width,
                    height: this.state.height
                }}
                src={this.state.initialImg} 
                alt={'Default'} 
            />
        </Box>
    }
    setAddImgBtn = () => {
        return <Box className={"add-image-btn"}>
            <input 
                accept="image/*" 
                style={{ 
                    display: 'none' 
                }} 
                id={"icon-button-file-" + this.id} 
                type="file" 
                onChange={(event) => {
                    var selectedFile = event.target.files[0];
                    var reader = new FileReader();
                  
                    reader.onload = (event) => {
                      this.setState({
                          src: event.target.result,
                          selectedFile: selectedFile
                      }, () => {
                        if(this.props.onLoad){
                            this.props.onLoad();
                        }
                      });
                    };
                  
                    reader.readAsDataURL(selectedFile);
                }}
            />
            <label ref={this.labelRef} htmlFor={"icon-button-file-" + this.id}>
                <IconButton component="span">
                    <CameraAltOutlinedIcon />
                </IconButton>
            </label>
        </Box>
    }
    setCropImgBtn = () => {
        if(!this.state.isUploading){
            return <Box className={"crop-image-btn"}
                style={{
                    top: (this.state.height / 2) + 'px'
                }}
            >
                <IconButton component="span"
                    onClick={this.close}
                >
                    <CloseOutlinedIcon />
                </IconButton>
                <IconButton component="span"
                    onClick={this.crop}
                >
                    <SaveOutlinedIcon />
                </IconButton>
            </Box>
        }
    }
    setProgressCircle = () => {
        if(this.state.isUploading){
            return <div className={"progress-circle"}
                style={{
                    width: this.state.width,
                    height: this.state.height,
                }}
            >
                <CircularProgress variant="static" value={this.state.progress} />
                <Typography className={"progress-circle-text"} variant="caption" component="div" color="textSecondary">{this.state.progress}%</Typography>
            </div>
        }
    }
    /* END ELEMENTS */
    

    render() {
        return <Box className={"custom-cropper-component " + this.state.className + " " + ((this.state.isCircle === false) ? 'no-circle' : '')}
            style={{
                width: this.state.width,
                margin: '0 auto',
            }}
        >
            {
                this.state.src
                ?
                <>
                    {this.setCropper()}
                    {this.setCropImgBtn()}
                </>
                :
                <>
                    {this.setDefaultImg()}
                    {this.setAddImgBtn()}
                </>
            }
        </Box>;
    }
}

export default CustomCropper;
