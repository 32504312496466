import React, { Component } from "react";
import { AuthContext } from "../../components/Auth/AuthDataProvider";

import { 
  Box,
  CircularProgress,
  Typography,
  Grid
} from '@material-ui/core';
import Button from '@material-ui/core/Button';

import MUIDataTable from "mui-datatables";
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";

import Select from 'react-select';
import moment from 'moment';
import { Container, Row, Label, InputGroup, InputGroupButtonDropdown, DropdownToggle,DropdownMenu, DropdownItem, Input, Card, CardBody, Col } from "reactstrap";

import apiUtil from "../../api/apiUtil";
import customerApi from "../../api/Customer";
import balanceEnquiryApi from "../../api/BalanceEnquiry";
import { locationData } from '../../data/misc';
import GroupButton from "../../components/GroupButton/GroupButton";
import CustomDateRangePicker from "../../components/CustomFields/CustomDateRangePicker";
import CustomLabel from "../../components/CustomFields/CustomLabel";
import wmsApi from "../../api/wmsApi";


class BalanceEnquiryList extends Component {
  static contextType = AuthContext
  constructor(props) {
    super(props);

    this.state = {
      tableState: {
        page: 0,
        count: 1,
        rowsPerPage: 50,
        sortOrder: {}
      },
      rangeDate: {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
      },
      selectedTab: 0,
      data: [],
      isLoading: false,
      customerList: [],
      selectedCustomer: {
        id: '',
        name: ''
      },
      selectedWarehouse: {
        id: '',
        name: ''
      },
      isFilterOpen: false,
      filter: {
        customerId: "",
        text: 'Choose..',
        field: 'query',
        value: ''
      }
    };

    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

    changePage = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  sort = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  search = (page, query) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.search = query;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }


  getData = async (customerId = null, warehouse = null) => {
    if (!customerId) {
      if (!this.state.selectedCustomer.id) {
        // apiUtil.toast('No customer selected.', 'error');
        // return false;
        customerId= "";
      }else{
        customerId = this.state.selectedCustomer.id;
      }
    }
    if (!warehouse) {
      if (!this.state.selectedWarehouse.id) {
        warehouse= "";
      }else{
        warehouse = this.state.selectedWarehouse.id;
      }
    }
    let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
    let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
    const { accessToken } = this.context;
    this.setState({ isLoading: true });

    let params = {
      enquiry: "in",
      customer_id: customerId,
      warehouse_id: warehouse,
      optional_filter_by: this.state.filter.field,
      optional_query: this.state.filter.value,
      start_date: startDate,
      end_date: endDate
    };

    if (this.state.selectedTab == 1) {
      params.enquiry = 'out'
    }
    await balanceEnquiryApi.get(params, accessToken).then(data => {


      data.data.map(function(vv,kk){
        locationData.map(function(v,k){ if(v.value==vv.location){ vv.location = v.label;} });       
      });

      this.setState({
        isLoading: false,
        data: data.data
      });
    }).catch(error => console.log(error));
  }

  getCustomer = async () => {
    const { accessToken } = this.context;
    await customerApi.get('', accessToken).then(data => {
      if (Array.isArray(data.data)) {
        let customerList = [];

        data.data.forEach((val) => {
          customerList.push({
            value: val.id,
            code: val.company_code,
            label: val.company_name
          });
        });

        this.setState({
          customerList: customerList
        });
      } else {
        apiUtil.toast('Failed to get customer data.', 'error');
      }
    }).catch(error => console.log(error));
  }

  getWareHouse = (callback = null) => {
      // const  accessToken = localStorage.getItem("access_token");

      const {  accessToken} = this.context;

      this.setState({
        isLoading: true
      }, () => {
        let param = {
        };

        wmsApi.read('warehouses', param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              let warehouseList = []
              if (data.result && data.result.length > 0) {
                data.result.forEach((val) => {
                  warehouseList.push({
                    value: val.id,
                    label: val.name,
                    name: val.name
                  });
                });

              }
              
              this.setState({
                warehouseList: warehouseList,
                isLoading: false,
              });
              if (callback) {
                  callback(data)
              }
            }, (error, type) => {
              this.setState({
                data: [],
                total: 0,
                isLoading: false,
              });
            });
        });
      });
  }
  toggleFilter = () => {
    let isFilterOpen = this.state.isFilterOpen;

    this.setState({
      isFilterOpen: !isFilterOpen
    });
  }

  handleCustomerChange = (event) => {
    this.setState({
      selectedCustomer: {
        id: event.value,
        name: event.name
      }
    });
    this.getData(event.value);
  }

  handleWarehouseChange = (event) => {
    this.setState({
      selectedWarehouse: {
        id: event.value,
        name: event.name
      }
    });
    console.log(event)
    this.getData('', event.value);
  }

  handleFilterChange = (event) => {
    let filter = this.state.filter;
    filter.text = event.target.innerHTML;
    filter.field = event.target.value;

    this.setState({
      filter: filter
    });
  }

  handleFilterFieldChange = (event) => {
    let filter = this.state.filter;
    filter.value = event.target.value;
    
    this.setState({
      filter: filter
    });
  }

  componentDidMount = () => {
    document.title = "Balance Enquiry | WMS";
    window.scrollTo(0, 0);
    this.getCustomer();
    this.getWareHouse();
    this.getData();
  }

  render() {
    const columns = [
      {
        name: "created_by",
        label: "Customer",
        options: {
          customBodyRender: (value) => {
            let data = this.state.data.filter(dd => dd.created_by == value)
            return data && data[0].customer? data[0].customer.company_name: '-'
          }
        }
      },
      {
        name: "sku_count",
        label: "SKU List"
      },
      {
        name: "stock_amount",
        label: "Stock Amount"
      },
      // {
      //   name: "expiry_date",
      //   label: "Expiry Date",
      //   options: {
      //     customBodyRender: (value) => {
      //       return value?moment(value).format('DD-MMM-YYYY'):'';
      //     }
      //   }
      // },
      {
        name: "created_by",
        label: "Warehouse",
        options: {
          customBodyRender: (value) => {
            let data = this.state.data.filter(dd => dd.created_by == value)
            return data[0].customer && data[0].customer.items[0]  && data[0].customer.items[0].warehouse ? data[0].customer.items[0].warehouse.name : '-'
          }
        }
      },
      // {
      //   name: "location",
      //   label: "Location"
      // },
      // {
      //   name: "created_at",
      //   label: "Date In",
      //   options: {
      //     customBodyRender: value => moment(value).format('DD-MMM-YYYY')
      //   }
      // },
      // {
      //   name: "balance",
      //   label: "Bal"
      // }
    ];

    const options = {
      selectableRows: 'none',
      download: false,
      rowsPerPage: this.state.tableState.rowsPerPage,
      rowsPerPageOptions: [10, 15, 50, 100],
      responsive: "standard", // standard | vertical | simple
      serverSide: false,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page, tableState.sortOrder);
            break;
          case 'sort':
            this.sort(tableState.page, tableState.sortOrder);
            break;
          default:
            console.log('action not handled.');
        }
      },      
      onDownload: (buildHead, buildBody, columns, data) => {
          data.map(function(item, index){ 
            if(item.data[3]){ item.data[3] = moment(item.data[3]).format('DD-MMM-YYYY');}
            item.data[6] = moment(item.data[6]).format('DD-MMM-YYYY');
          });
          return "\uFEFF" + buildHead(columns) + buildBody(data); 
      },
      downloadOptions:{filename: 'Balance Enquiry - '+this.state.selectedCustomer.name+'.csv', separator: ','}
    }

    let tabs = ['Enquiry In', 'Enquiry Out']

    return <Box className="dashboard-page">
      <StickyPageHeader>
        <Container>
          <Row>
            <Col className="col-12 col-xs-12">
              <BreadCrumb />
              <h1 className="page-title">Balance Enquiry</h1>
            </Col>
            <Col className="col-12 col-xs-12 pt-3">
            <div className='d-flex justify-content-end align-items-center'>
            <GroupButton
            className={'head-tabs'}
            color={'secondary'}
            selected={this.state.selectedTab}
            buttons={tabs}
            onClick={(selected, btn) => {
                if(selected === 1){
                // this.props.history.push('/items/inventory');
                this.setState({selectedTab :1},() => {
                  this.getData()
                })
                } else {
                  this.setState({selectedTab : 0},() => {
                    this.getData()
                  })
                }
            }}
            />       
          </div>
            </Col>
          </Row>
       
          

          </Container>
      </StickyPageHeader>
      <Container>
      <Card>
        <CardBody>
          <Row>
            {/* <Col md={{size: 12}}> */}
              <Grid item md={4} sm={4} xs={12}>
              <div className="clearfix">
              <CustomLabel
                  label={'Company'}
              />
                <Select className="has-margin no-border" options={this.state.customerList} onChange={(e) => this.handleCustomerChange(e)} />
              </div>
              </Grid>
              {/* <div className="clearfix">
                <Label className="control-label">
                  Company Name
                </Label>
                <Input className="form-control has-margin form-input" defaultValue={this.state.selectedCustomer.name} disabled="" readOnly="readonly" />
              </div> */}
              <Grid item md={4} sm={4} xs={12}>
              <div className="clearfix">
              <CustomLabel
                  label={'Warehouse'}
              />
                <Select className=" has-margin no-border" options={this.state.warehouseList} onChange={(e) => this.handleWarehouseChange(e)} />
              </div>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
              <div className="clearfix">
              <CustomLabel
                  label={'Date Range'}
              />
                <CustomDateRangePicker
                  className={'has-margin no-border'}
                  range={this.state.rangeDate}
                  onChange={(range) => {
                    localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));

                    this.setState({
                      rangeDate: range,
                      startDate: range.startDate,
                      endDate: range.endDate
                    },() => {
                      this.getData();
                    });
                  }}
                />
              </div>
              </Grid>
              {/* <div className="clearfix">
                <Label className="control-label">
                  Search By
                </Label>
                <InputGroup className="form-input-group no-border">
                  <InputGroupButtonDropdown addonType="prepend" isOpen={this.state.isFilterOpen} toggle={this.toggleFilter}>
                  <DropdownToggle caret>{this.state.filter.text}</DropdownToggle>
                  <DropdownMenu>
                  <DropdownItem value="query" onClick={this.handleFilterChange}>Choose..</DropdownItem>
                  <DropdownItem value="lot_no" onClick={this.handleFilterChange}>Lot No</DropdownItem>
                  <DropdownItem value="serial_no" onClick={this.handleFilterChange}>Serial No</DropdownItem>
                  <DropdownItem value="part_no" onClick={this.handleFilterChange}>Part No</DropdownItem>
                  </DropdownMenu>
                  </InputGroupButtonDropdown>
                  <Input placeholder="optional.." onChange={this.handleFilterFieldChange} />
                </InputGroup>

                <Button
                  className="btn-theme form-input-btn"
                  onClick={() => this.getData()} 
                  color="primary"
                  variant="contained">Search</Button>
              </div> */}
            {/* </Col> */}
          </Row>
        </CardBody>
      </Card>
      
      <Card className="pt-0">
        <CardBody>
          <Row>
            <Col md="12">
              <MUIDataTable
                title={
                  <Typography variant="h6">
                    Balance Enquiry
                    {this.state.isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />}
                  </Typography>}
                data={this.state.data}
                columns={columns}
                options={options}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      </Container>
    </Box>;
  }
}

export default BalanceEnquiryList;