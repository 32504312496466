import React, { Component } from "react";

import { Box } from '@material-ui/core';
import { Container, Row, Label, Card, Form, CardBody, Col, Input, Table, ButtonGroup } from "reactstrap";
import * as Yup from "yup";

import wmsApi from "../../api/wmsApi";
import defaultImg from '../../assets/img/default_image.jpg';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import ProgressButton from "../../components/Button/ProgressButton";
import Loading from "react-fullscreen-loading";

import { 
  Grid,
  Typography,
  Modal
 } from '@material-ui/core';
import CustomInput from '../../components/CustomFields/CustomInput';

import queryString from 'query-string';
// import { Formik, Form, Field } from "formik";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';

import apiUtil from '../../api/apiUtil';
import customerApi from "../../api/Customer";
import salesOrderApi from "../../api/SalesOrder";
import moment from "moment";
import CustomButton from "../../components/CustomFields/CustomButton";
import EditItemDialog from './Partial/EditItemDialog';
import AddItemForm from "./Partial/AddItemForm";
import { locationData } from '../../data/misc';
import CustomFormDialog from "../../components/Dialog/CustomFormDialog";
import WarehouseForm from "./Partial/WarehouseForm";

const SalesOrderSchema = Yup.object({
  container_receipt: Yup.string().required('Please input value'),
  hawb: Yup.string(),
  end_user_name: Yup.string(),
  total_kg: Yup.number()
    .typeError('Value must be a number')
    .positive('Value must be greater than zero'),
  total_package: Yup.number()
    .typeError('Value must be a number')
    .positive('Value must be greater than zero'),
  remarks: Yup.string()
});

let itemArray = [];
let subTotal = 0;
class SalesOrderForm extends Component {
 
  constructor(props) {
    
    super(props);
    this.state = {
      modal: false,
      editItemData: null,
      dataId: '',
      shipingCharge: 0,
      adjustmentPrice: 0,
      adjustmentName: '',
      isLoading: false,
      pageLoading: false,
      orderTotal: 0,
      itemRow: [],
      data_item:[],
      item_qty:0,
      customerList: [],
      customerData: [],
      warehouseList: [],
      dataItem: [],
      order_detail: [],
      balance : 0,
      delivery_method: '',
      expected_delivery_time:'',
      contact_number:'',
      selectedCustomer: {
        id: '',
        name: '',
        code: ''
      },
      selectedWarehouse: {
        id: '',
        name: ''
      },
      inbound_date: '',
      data_item_all : [],
      handling_in_date: '',
      container_receipt: '',
      hawb: '',
      end_user_id: '',
      end_user_name: '',
      total_kg: '',
      total_package: '',
      remarks: '',
      admin: '',
      order_details: [],
      order_items: []
    };
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
  }

  componentDidMount = () => {
    let user  = localStorage.getItem('user');
    let userName = JSON.parse(user);

    let urlQuery = queryString.parse(window.location.search);
    if (urlQuery.id !== undefined) {
      this.setState({
        dataId: urlQuery.id
      }, () => {
        this.callReadApi();
      });
      document.title = "Edit Outbound Order | WMS";
    } else {
      document.title = "Create Outbound Order | WMS";
      // this.callItemApi();
    }

    this.setState({
      admin :userName.owner_info.first_name
    });
    window.scrollTo(0, 0);
    localStorage.removeItem('currentItem');
    this.getCustomer();
    this.getWareHouse()
    
  }



  getWareHouse = (callback = null) => {
    const  accessToken = localStorage.getItem("access_token");

    this.setState({
      isLoading: true
    }, () => {
      let param = {
      };

      wmsApi.read('warehouses', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let warehouseList = []
            if (data.result.length > 0) {
              data.result.forEach((val) => {
                warehouseList.push({
                  value: val.id,
                  label: val.name,
                  name: val.name
                });
              });
            }
            this.setState({
              warehouseList: warehouseList,
              isLoading: false,
            });
            if (callback) {
                callback(data)
            }
          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              isLoading: false,
            });
          });
      });
    });
  }

  handleWarehouseChange = (event) => {
    this.setState({
      selectedWarehouse: {
        id: event.value,
        name: event.name
      }
    });
  }

  getCustomer = async () => {
    await customerApi.get().then(data => {
      if (Array.isArray(data.data)) {
        let customerList = [];

        data.data.forEach((val) => {
          customerList.push({
            value: val.id,
            label: val.company_name,
            name: val.company_code
          });
        });

        this.setState({
          customerList: customerList
        });
        
        if (this.state.dataId !== "") {
          this.getData(this.state.dataId);
        } else {
          this.setState({
            pageLoading: false,
          });
        }
      } else {
        apiUtil.toast('Failed to get customer data.', 'error');
      }
    }).catch(error => console.log(error));
  }
  getCustomerById = (cus_id) => {

    const { 
      REACT_APP_WMS_API_URL
    } = process.env;
    let errorMessage = '';
    let id = cus_id;
    const URL = REACT_APP_WMS_API_URL + "admin/api/customers/"+id;

    let access_token = localStorage.getItem("access_token");

    axios.get(
      URL,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
    .then(res => {
        let end_user_name = res.data.customer.departments != '' ? res.data.customer.departments[0].contact_people[0].first_name : ' ';
        let end_user_id = res.data.customer.departments != '' ? res.data.customer.departments[0].contact_people[0].id : ' ';
        this.setState({
          end_user_name : end_user_name,
          end_user_id : end_user_id
        });

     
    }).catch( error => {
      errorMessage = error.message;
    });
  }

  handleInputChange = event => {
    let formState = this.state.formData;
    formState[event.target.name] = event.target.value;

    this.setState({
      formData: formState
    });
  }

  handleCustomerChange = (event) => {
    itemArray = [];
    this.setState({
      data_item: [],
      data_item_all: [],
      itemRow: [],
      order_items: [],
      selectedCustomer: {
        id: event.value,
        name: event.label,
        code: event.name
      }
     
    }, ()=> {
      this.callItemApi(event.value)
      this.getCustomerById(this.state.selectedCustomer.id)
    });
    
  }


  callItemApi = (customer_id = '') => {
    const  accessToken = localStorage.getItem("access_token");

    this.setState({
      isItemLoading: true
    }, () => {
      let param = {
        sort: "latest",
        customer_id: customer_id,
        inbound_item: true
      };

      wmsApi.read('items', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let temp_data = (data.result && data.result.length > 0) ? data.result : [];
            let use_data = [];
            temp_data.map ((data) => {
              // if(data.stock_on_hand - data.committed_stock > 0)
                use_data.push(data);
              })
            this.setState({
              data_item_all : (data.result && data.result.length > 0) ? data.result : [],
              data_item: use_data,
              isItemLoading: false,
            }, ()=>{
              this.handleItem();
            });
            
          }, (error, type) => {
            this.setState({
              data_item: [],
              total: 0,
              isItemLoading: false,
            });
          });
      });
    });
  }

  // -------------------------
  // handling Items Start here
  // -------------------------

  handleItem = () => {
    console.log("handleItem")

  }

  handleItemChange = (e) => {
    let item = e.target.value
    if(item !== '') {
      let data_item = this.state.data_item
      let itemRow = this.state.itemRow
      let filter_data = data_item.filter(data => data.id != item )
      let selected_item = data_item.filter(data => data.id == item )
      let order_items = this.state.order_items
      let order_item = {}
      order_item.item_id = item
      order_item.item_qty = 1
      order_items.push(order_item)
      itemRow.push(selected_item[0])
      this.setState({
        data_item: filter_data,
        itemRow: itemRow,
        order_items: order_items
      })

    }

  }

  handleRemoveItem = (itemId) => {
    let data_item = this.state.data_item
    let itemRow = this.state.itemRow
    let filter_data = itemRow.filter(data => data.id != itemId)
    let selected_item = itemRow.filter(data => data.id == itemId)
    data_item.push(selected_item[0])
    let order_items = this.state.order_items.filter(ot => ot.item_id != itemId)
    this.setState({
      data_item: data_item,
      itemRow: filter_data,
      order_items: order_items
    })

    console.log("handleRemoveItem", itemId)

  }

  handleTotal = () => {
    let total = parseInt(this.state.shipingCharge) + parseInt(this.state.adjustmentPrice) + parseInt(subTotal);

    this.setState({
      orderTotal: total
    })
  }

  handleQuantity = (value, index) => {    
    
    let order_items = this.state.order_items
    order_items.forEach(val=>{
      if (val.item_id == index) {
        val.item_qty = value
      }
    })
    this.setState({
      order_items
    })

  }

  handleSKU = (value, index) => {    
    let objIndex = itemArray.findIndex((obj => obj.id == index));  //Find index of specific object using findIndex method.
    // console.log("Before update: ", itemArray[objIndex]) 
    itemArray[objIndex].sku = value; //Update object's name property.

    this.setState({
      itemRow : itemArray,
    })
  }

  handleRate = (value, index) => {    
    let objIndex = itemArray.findIndex((obj => obj.id == index));  //Find index of specific object using findIndex method.
    // console.log("Before update: ", itemArray[objIndex]) 
    itemArray[objIndex].selling_prices = value; //Update object's name property.
    
    subTotal -= itemArray[objIndex].total;
    itemArray[objIndex].total = itemArray[objIndex].selling_prices * itemArray[objIndex].stock_on_hand;
    subTotal += itemArray[objIndex].total;

    this.setState({
      itemRow : itemArray,
    })
    this.handleTotal();
  }

  handleDiscount = (value, index) => {    
    let objIndex = itemArray.findIndex((obj => obj.id == index));  //Find index of specific object using findIndex method.
    // console.log("Before update: ", itemArray[objIndex]) 
    itemArray[objIndex].cost_prices = value; //Update object's name property.

    this.setState({
      itemRow : itemArray,
    })
  }

  handleTax = (value, index) => {    
    let objIndex = itemArray.findIndex((obj => obj.id == index));  //Find index of specific object using findIndex method.
    // console.log("Before update: ", itemArray[objIndex]) 
    itemArray[objIndex].stock_on_hand = value; //Update object's name property.

    this.setState({
      itemRow : itemArray,
    })
  }

  // -------------------------
  // handling Items end here
  // -------------------------

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.order_items.length < 1) {
      apiUtil.toast('No item in this Inbound order.', 'error');
      return
    }


    if (this.state.inbound_date == '') {
      apiUtil.toast('Date need to be choose', 'error');
      return
    }

    if (this.state.selectedCustomer.id == '') {
      apiUtil.toast('No customer is selected.', 'error');
      return
    }

    if (this.state.selectedWarehouse.id == '') {
      apiUtil.toast('No warehouse is selected.', 'error');
      return
    }

    let data = {
      inbound_date: this.state.inbound_date,
      received_by: this.state.received_by,
      inbound_items : this.state.order_items,
      warehouse_id : this.state.selectedWarehouse.id ,
    }
    

    if (this.state.dataId === "") { // create

      this.callCreateApi(data, () => {
        this.setState({
          isLoading: false
        }, () => {
          this.props.history.push({
            pathname: '/inbound-orders',
          });
          apiUtil.toast('Successfully Created', 'success');
        });
      });
    }

  }

  callCreateApi = (row, callback = null) => {
    const  accessToken = localStorage.getItem("access_token");

    this.setState({
      isLoading: true
    }, () => {
      wmsApi.create('inbound_orders', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              apiUtil.toast(error, 'error');
            });
          });
      });
    });
  }

  changeDate = date => {
    let formData = this.state.formData;
    formData.handling_in_date = date;
    this.setState({
      formData: formData
    });
  }

  editItemDialog = () => {
    return <EditItemDialog
      modal={this.state.modal}
      data={this.state.editItemData}
      onUpdate={(data) => {
        let orderData = this.state.order_details;

        if (typeof data === 'object' && data !== null) {
          let index = data.index;
          data.volume = this.calculateVolume(data.width, data.length, data.height);

          delete data.index;
          
          orderData[index] = data;
        }

        this.setState({
          modal: false,
          editItemData: null,
          order_details: orderData
        });

        localStorage.setItem('currentItem', JSON.stringify(orderData));
      }}
    />
  }

  editItemOrder = index => {
    let orderData = this.state.order_details;
    orderData[index]['index'] = index;
    this.setState({
      modal: true,
      editItemData: orderData[index]
    });
  }

  checkItem = item => {
    if (
      isNaN(parseFloat(item.width)) ||
      isNaN(parseFloat(item.height)) ||
      isNaN(parseFloat(item.length))
    ) {
      return {
        pass: false,
        message: 'Width / Length / Height is not a number'
      };
    }

    return {
      pass: true,
      message: ''
    };
  }

  addItemToOrder = (item) => {
    let oldData = localStorage.getItem('currentItem')?JSON.parse(localStorage.getItem('currentItem')):this.state.order_details;
    if (Array.isArray(item)) {
      let error = false;

      item.forEach(i => {
        let checkItem = this.checkItem(i);
        if (!checkItem['pass']) {
          error = true;
          apiUtil.toast(checkItem['message'], 'error');
          return false;
        }

        i.volume = this.calculateVolume(i.width, i.length, i.height);
        locationData.map(function(v,k){          
          if(typeof i.location == "string" && i.location.toLowerCase() == v.label.toLowerCase()){
            i.location = v.value;            
          } 
        });

        if(i.expiry_date){ let dataDate = i.expiry_date.split("/");
        i.expiry_date = dataDate[1]+"/"+dataDate[0]+"/"+dataDate[2]; }
        

      });

      if (!error) {
        this.setState({
          order_details: item
        });
      }
    } else {
      let checkItem = this.checkItem(item);
      if (!checkItem['pass']) {
        apiUtil.toast(checkItem['message'], 'error');
        return false;
      }

      item.volume = this.calculateVolume(item.width, item.length, item.height);

      this.setState({
        order_details: [...oldData, item]
      })

      localStorage.setItem('currentItem', JSON.stringify([...oldData, item]));
    }
  }

  deleteItemOrder = index => {
    let orders = this.state.order_details;
    if (orders[index]['id']) {
      orders[index]['delete'] = true;
    } else {
      delete orders[index];
      orders.splice(index, 1);
    }
    this.setState({
      order_details: orders
    });
    localStorage.setItem('currentItem', JSON.stringify(orders));
  }

  calculateVolume = (width, length, height) => {
    let val = (parseFloat(width) * parseFloat(length) * parseFloat(height) / 1000000);
    let splitter = val.toString().split(".");
    return splitter.length > 1 && splitter[1].length > 6? parseFloat(val.toFixed(6)) : val;
  }

  calculateVolumeTotal = (volume, qty) => { 
    let val = (parseFloat(volume) * parseFloat(qty));
    let splitter = val.toString().split(".");
    return splitter.length > 1 && splitter[1].length > 6? parseFloat(val.toFixed(6)) : val;
  }

  calculateVolumeGrandTotal = () => {
    let result = 0;
    this.state.order_details.map(function (item, index) {
      result += (parseFloat(item.volume) *parseFloat(item.item_qty));
    });

    let splitter = result.toString().split(".");
    return splitter.length > 1 &&  splitter[1].length > 6? parseFloat(result.toFixed(6)) : result;
  }

  calculateQtyTotal = () => {
    let result = 0;
    this.state.order_details.map(function (item, index) {
      result += parseFloat(item.item_qty);
    });

    return result;
  }

  callReadApi( ) {
    let errorMessage = "";
    let status = 0;
    this.setState({
      pageLoading:true
    })
    const {
      REACT_APP_WMS_API_URL
    } = process.env;
    let dataid = this.state.dataId;
    // console.log('dataid', dataid);
    const PATH = 'admin/api/';
    const api = `orders/`;
    const URL = REACT_APP_WMS_API_URL + PATH + api + dataid;
    let token = window.localStorage.getItem('access_token');

    let param = {
      // handling_in:true,
      // take: this.state.limit,
      // search: "",
      // start_date: startDate,
      // end_date: endDate,
      // sorting_array: ['created_at DESC'],
    };
    axios.get(
      URL,
      {
        params: param,
        // data: param,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .catch(error => {
        this.setState({
          pageLoading: false
        })
        try {
          status = error.response.status;
          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = error.message;
          }
        } catch (err) {
          errorMessage = 'Service Unavailable!'
        }
      }).then((response) => {
          this.setState({
            data: response.data.result,
            handling_in_date :moment(response.data.result.handling_in_date).format('YYYY-MM-DD'),
            end_user_id :response.data.result.end_user_id,
            selectedCustomer: {
              id: response.data.result.customer ? response.data.result.customer.id : '',
              name: response.data.result.customer.company_name? response.data.result.customer.company_name : '',
              code: response.data.result.customer.company_code? response.data.result.customer.company_code: '',
            },
            remarks :response.data.result.remarks,
            total_kg :response.data.result.total_kg,
            total_package :response.data.result.total_package,
            end_user_id :response.data.result.end_user_id,
            delivery_method :response.data.result.delivery_method,
            expected_delivery_time :moment(response.data.result.expected_delivery_time_slot).format('YYYY-MM-DD'),
            contact_number :response.data.result.contact_number,
            end_user_name :response.data.result.end_user ? response.data.result.end_user.end_user:'',
            dataItem : response.data.result.order_items,
            pageLoading:false,
            inbound_date: moment(response.data.result.inbound_date).format('YYYY-MM-DD'),
            received_by: response.data.result.received_by
          }, ()=>{
            this.handleItem();
            this.callItemApi(response.data.result.customer.id )
          });
      });
  }
  
  getData = (dataId) => {
    salesOrderApi.show(dataId).then(data => {
      if (data.status === 200) {
        // this.populateData(data.data.result);
      } else {
        apiUtil.toast(data.message, 'error');
      }
    });
  }

  populateData = (data) => {
    let formData = this.state.formData;
    for (const prop in formData) {
      if (data[prop] !== undefined) {
        if (prop === 'handling_in_date') {
          formData.handling_in_date = new Date(data.handling_in_date);
        } else {
          formData[prop] = data[prop];
        }
      }
    }

    if (data.end_user !== undefined) {
      formData.end_user_id = data.end_user.id;
      formData.end_user_name = data.end_user.end_user;
    }

    let orderDetails = data.order_details;

    this.setState({
      pageLoading: false,
      formData: formData,
      order_details: orderDetails,
      selectedCustomer: {
        id: data.customer.id,
        name: data.customer.company_name,
        code: data.customer.company_code
      }
    });
  }

  handleClosePopup = () => {
    this.setState({
      showPopup: false,
    })
  }

  createWarehouseDialog = () => {
    return <CustomFormDialog 
        open={this.state.showPopup}
        title={'Create New Warehouse'}
        onClose={() => {
          this.setState({
              showPopup: false,
          });
        }}
    >
        <WarehouseForm
          onSave={( data) => {
             let warehouse  = data.result
              this.setState({
                selectedWarehouse: {
                  id: warehouse.id,
                  name: warehouse.name,
                  
                },
                showPopup: false,
              }, () => {
                this.getWareHouse();
              });
          }}
          onClose={() => {
              this.setState({
                showPopup: false,
              });
          }}
        />
    </CustomFormDialog>
}


  render() {
    const theClass = this;
    return (
      <Box className="dashboard-page">
        <Loading loading={this.state.isLoading} background="#f5f5f5" loaderColor="#e89cae" />
        <StickyPageHeader>
          <Container>
            <Row>
            <BreadCrumb />
            <h1 className="page-title">{this.state.dataId !== "" ? "Update Inbound Order" : "Create Inbound Order"}</h1>
          </Row>
          </Container>
        </StickyPageHeader>

        <Container>
        <Row>
          
              <Form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
                <Col md={{ size: 12 }}>
                  

                  <Card>
                    <CardBody>
                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Inbound Date*
                          </Label>
                          <div className="form-input">
                           <CustomInput
                            placeholder={'Enter Recipeint Name here...'}
                            value={this.state.inbound_date}
                            required={true}
                            type='date'
                            onChange={(e) => {
                              this.setState({
                                inbound_date: e.target.value
                              });
                            }}
                          />
                          </div>
                        </Col>
                      </Row> 

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                        <div className="option-select" >
                            <Label className="control-label">
                              Choose Company Name
                            </Label>
                            <Select value={{label: this.state.selectedCustomer.name, value: this.state.selectedCustomer.id}} className="form-input option-select customer-option" options={this.state.customerList} onChange={(e) => this.handleCustomerChange(e)} />
                          </div>
                        </Col>
                      </Row>
                      
                    <Row className="mb-2">
                      <Col xs="12" className="mt-3 d-flex">
                        <Label className="control-label">
                       Received By
                        </Label>
                        <div className="form-input">
                           <CustomInput
                           disabled
                            placeholder={'Enter Contact Number here...'}
                            value={this.state.admin ? this.state.admin : ''}
                            // onChange={(e) => {
                            //   this.setState({
                            //     received_by: e.target.value
                            //   });
                            // }}
                          />
                          </div>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                        <div className="option-select " >
                            <Label className="control-label">
                              Warehouse
                            </Label>
                            <Select value={{label: this.state.selectedWarehouse.name, value: this.state.selectedWarehouse.id}} className="form-input option-select" options={this.state.warehouseList} onChange={(e) => this.handleWarehouseChange(e)} />
                            
                          </div>
                        </Col>
                        <Col md={{size: 9, offset: 3}}> <a href="#" onClick={e=>this.setState({showPopup: true})}>Create new warehouse?</a></Col>
                      </Row>

                    {/* <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Total Packages
                          </Label>
                          <Field className="form-control form-input" name="total_package" value={this.state.formData.total_package || ''} onChange={this.handleInputChange} placeholder="quantity" />
                          {
                            errors.total_package && touched.total_package ? (
                              <div className="invalid-feedback d-block">
                              {errors.total_package}
                              </div>
                              ) : null
                          }
                        </Col>
                      </Row> */}

                    {/* <Row className="mb-2">
                      <Col xs="12" className="mt-3 d-flex">
                        <Label className="control-label">
                          Remarks
                        </Label>
                        <div className="form-input">
                          <CustomInput
                            placeholder={'Enter Remarks here...'}
                            value={this.state.remarks}
                            error={this.state.remarksValidated}
                            required={true}
                            onChange={(e) => {
                              this.setState({
                                remarks: e.target.value,
                                remarksValidated: (e.target.value !== '') ? false : true,
                              });
                            }}
                          />
                        </div>
                      </Col>
                    </Row> */}

                      {/* <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Remarks
                          </Label>
                          <Field className="form-control form-input" name="remarks" component="textarea" value={this.state.formData.remarks || ''} onChange={this.handleInputChange} rows="5" />
                          {
                            errors.remarks && touched.remarks ? (
                              <div className="invalid-feedback d-block">
                              {errors.remarks}
                              </div>
                              ) : null
                          }
                        </Col>
                      </Row> */}
                    </CardBody>
                  </Card>

                  


                  <Card className="mt-2">
                    <CardBody>
                      <Col xs="12">
                        <h4 className="fw-bolder fs-5 my-4">Items in this Order</h4>
                      </Col>
                      <Table className="mb-5 item-table-header">
                        <thead>
                          <tr>
                            <th>Item Details</th>
                            <th>Quantity</th>
                            <th>Rate</th>
                            <th>Discount</th>
                            <th>Tax</th>
                            <th>Amount</th>
                          </tr>
                      </thead>
                      <tbody>
           
                      {this.state.itemRow && this.state.itemRow != ''?
                      <>
                      {this.state.itemRow &&this.state.itemRow.filter(row => row.delete != true ).map((item) => {
     
                      return <tr>
                        <td>{item.name} <br />
                        <Label>SKU</Label>
                        <Input
                        value={item.sku} 
                        disabled     
                        onChange={(e) => this.handleSKU(e.target.value, item.id)}
                        placeholder="1.00"
                        />
                        </td>

                        <td> <Input
                        value={this.state.order_items.filter(dd=>dd.item_id == item.id)[0].item_qty} 
                        type="number"
                        onChange={(e) => this.handleQuantity(e.target.value , item.id)}
                        placeholder="1.00"
                        /></td>

                        <td><Input
                        // value={item.selling_prices} 
                        disabled                       
                        onChange={(e) => this.handleRate(e.target.value , item.id)}
                        placeholder="0.00"
                        /></td>

                        <td><div className="d-flex"><Input
                        // value={item.cost_prices} 
                        disabled                       
                        onChange={(e) => this.handleDiscount(e.target.value , item.id)}
                        placeholder="0.00"
                        />
                        <select className="border-0 bg-light" disabled onChange={(e) => this.handleCustomerChange(e)} >
                         <option value={1}> % </option>
                         <option value={2}> SGD </option>
                         </select>
                         </div>
                        </td>

                        <td>   
                        <Input
                        // value={item.cost_prices} 
                        disabled
                        onChange={(e) => this.handleTax(e.target.value , item.id)}
                        placeholder="0.00"
                        />
                        </td>
                        
                        <td className="fw-bold">
                          {item.total}
                          </td>
                        <td className="d-flex align-items-center justify-content-center h-100 border-0"><div className="remove-icon border-0"><span 
                        onClick={(e) => this.handleRemoveItem(item.id)}
                        > X </span></div></td>
                      </tr>
                      
                      })}

                       <tr>
                       <td>
                       <select onChange={(e) => this.handleItemChange(e)} className="w-100 bg-light border-0 h-100 py-2">
                       <option value="" selected="true" >{this.state.isItemLoading? 'Item loading....' : 'Type or click to select an item'}</option>
                      {this.state.data_item && this.state.data_item.map((dt)=> (
                           <option key={dt.id} value={dt.id}>{dt.name}</option>)
                        )}
                      </select>  
                       </td>
                       <td> <Input
                       disabled
                       // value={this.state.quantity} 
                       placeholder="1.00"
                       /></td>
                       <td> <Input
                       // value={this.state.quantity} 
                       disabled
                       placeholder="0.00"
                       /></td>
                      <td className="d-flex"> <Input
                       // value={this.state.quantity} 
                       disabled
                       placeholder="0.00"
                       />
                       <select className="no-border bg-light" disabled onChange={(e) => this.handleCustomerChange(e)} >
                         <option value={1}> % </option>
                         <option value={2}> SGD </option>
                         </select>
                       </td>
                       <td> <Input 
                        placeholder="0.00"
                        disabled
                        /></td>
                       <td className="fw-bold">0.00</td>
                     </tr>
                     </>
                      :
                      <tr>
                        <td>
                        <select onChange={(e) => this.handleItemChange(e)} className="w-100 bg-light border-0 h-100 py-2">
                        <option value="" selected>{this.state.isItemLoading? 'Item loading....' : 'Type or click to select an item'}</option>
                       {this.state.data_item && this.state.data_item.map((dt)=>
                         <option key={dt.id} value={dt.id}>{dt.name}</option>
                       )}
                       </select>  
                        </td>
                        <td> <Input
                        // value={this.state.quantity} 
                        placeholder="1.00"
                        /></td>
                        <td> <Input
                        // value={this.state.quantity} 
                        disabled
                        placeholder="0.00"
                        /></td>
                       <td className="d-flex"> <Input
                       disabled
                        // value={this.state.quantity} 
                        placeholder="0.00"
                        />
                        <select className="no-border bg-light" disabled onChange={(e) => this.handleCustomerChange(e)} >
                          <option value={1}> % </option>
                          <option value={2}> SGD </option>
                          </select>
                        </td>
                        <td> <Input
                        disabled
                        placeholder="0.00"
                        /></td>
                        <td className="fw-bold">0.00</td>
                      </tr>
                      }
                      </tbody>
                      </Table>
                      {/* <div className="form-input">
                          <CustomInput
                            placeholder={'Enter Remarks here...'}
                            value={this.state.remarks}
                            error={this.state.remarksValidated}
                            required={true}
                            onChange={(e) => {
                              this.setState({
                                remarks: e.target.value,
                                remarksValidated: (e.target.value !== '') ? false : true,
                              });
                            }}
                          />
                        </div> */}
                      {/* <Row>
                        <Col md={6} xs={12} className="p-2 align-self-end">
                          <Label className="w-100 mb-2">Remarks</Label>
                          <textarea
                            rows="4"
                            value={this.state.remarks}
                            error={this.state.remarksValidated}
                            onChange={(e) => {
                              this.setState({
                                remarks: e.target.value,
                                remarksValidated: (e.target.value !== '') ? false : true,
                              });
                            }}
                           className="w-100 border rounded p-3"
                        placeholder=""
                        required={this.state.remarksValidated}
                        />
                        </Col>
                        <Col md={6} xs={12} className="p-2 bg-light rounded">
                          <Row className="d-flex justify-content-between p-3">
                            <Col>Sub Total</Col>
                            <Col className="text-end">
                              {subTotal}
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-between p-3">
                            <Col className="d-flex">
                              <Label>Shipping Charge</Label>
                            <Input
                            value={this.state.shipingCharge} 
                            disabled
                            onChange={(e) => 
                            this.setState({
                              shipingCharge: e.target.value
                            }, ()=> {
                              this.handleTotal();
                            })}
                            />
                            </Col>
                            <Col className="text-end">
                              {this.state.shipingCharge != 0 ? this.state.shipingCharge : 0}
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-between p-3">
                            <Col className="d-flex"><Input
                            placeholder="Adjustment"
                            className="border-0"
                            disabled
                            defaultValue={this.state.adjustmentName == ""? this.state.adjustmentName: "Adjustment"} 
                            onChange={(e) => {
                              this.setState({
                                adjustmentName: e.target.value,
                              })
                            }}
                            />
                            <Input
                            value={this.state.adjustmentPrice}
                            disabled 
                            onChange={(e) => 
                              this.setState({
                                adjustmentPrice: e.target.value,
                              }, ()=> {
                                this.handleTotal();
                              })
                            }
                            />
                            </Col>
                            <Col className="text-end">
                              {this.state.adjustmentPrice != 0 ? this.state.adjustmentPrice : 0}
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-between p-3">
                            <Col className="d-flex ">
                              <Label className="fw-bold">Total ( SGD )</Label>
                            </Col>
                            <Col className="text-end fw-bold">
                              {subTotal}
                            </Col>
                          </Row>
                        </Col>
                      </Row> */}
                      </CardBody>
                      </Card>


                  <Card className="mt-4">
                    <ProgressButton 
                    type={'submit'}
                    color={'secondary'}
                    isLoading={this.state.isLoading}>
                      <Box pl={2} pr={2}>Submit</Box>
                    </ProgressButton>
                  </Card>
                </Col>
              </Form>
            {/* )}
          </Formik> */}
        </Row>
        </Container>
        {this.editItemDialog()}
        {this.createWarehouseDialog()}
      </Box>
    );
  }
}

export default SalesOrderForm;