import React, { Component } from "react";

import { 
 Grid,
 Box,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import jsPDF from "jspdf";
import 'jspdf-autotable';

import { ReactComponent as MenuIcon } from '../../assets/img/icons/menu.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import { Row, Container,Table, Card, CardBody, Col, CardTitle, CardSubtitle } from "reactstrap";
import { ReactComponent as ExportIcon } from '../../assets/img/icons/export.svg';
import customerApi from "../../api/Customer";
import apiUtil from "../../api/apiUtil";
import wmsApi from "../../api/wmsApi";
import DropdownMenu from "../../components/Dropdown/DropdownMenu";
import CustomTable from '../../components/Table/Table.jsx';
import CustomButton from "../../components/CustomFields/CustomButton";
import GroupButton from "../../components/GroupButton/GroupButton";
import CustomDateRangePicker from "../../components/CustomFields/CustomDateRangePicker";
import Select from 'react-select';
import moment from "moment";
import defImg from '../../assets/img/default_item.jpg';

class InventoryReport extends Component {
    constructor(porps) {
        super(porps)
        this.state = {
            reportTab: 1,
            rangeDate: {
              startDate: moment().startOf('month'),
              endDate: moment().endOf('month'),
              },
            render_table: 'inventory_summary',
            seletctedTable: 'Inventory Summary',
            isLoading: false,
            page: 0,
            limit: 20,
            total: 0,
            customerList: [],
            selectedCustomer: {
              id: '',
              name: ''
            },
            data: [],
            openDialog: false,
            openDialogItem: null,
        }
    }

    componentDidMount = () => {
        this.callReadApi('inventory_summary');
        this.getCustomer();
    }

    
    callReadApi = (search) => {
        const  accessToken = localStorage.getItem("access_token");
        let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
        let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

        this.setState({
          isLoading: true
        }, () => {
          let param = {
            page: this.state.page,
            take: this.state.limit,
            sort: "latest",
            search: search,
            start_date: startDate,
            end_date: endDate,
            inbound_item: true,
            customer_id: this.state.selectedCustomer? this.state.selectedCustomer.id : ''
          };

    
          // if (search == 'inventory_summary' ) {
          //   param['inventory_summary'] = true
          // }
          // if (search == 'committed_stock') {
          //   param['committed_stock'] = true
          // }

          // if (search == 'inventory_valuation') {
          //   param['inventory_valuation'] = true
          // }
          wmsApi.read('items', param, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                this.setState({
                  data: (data.result && data.result.length > 0) ? data.result : [],
                  total: data.total,
                  isLoading: false,
                });
              }, (error, type) => {
                this.setState({
                  data: [],
                  total: 0,
                  isLoading: false,
                });
              });
          });
        });
      }

      getCustomer = async () => {
        this.setState({ isLoading: true });
        const { accessToken } = this.context;
        let params = {
        
        };
    
        await customerApi.get(params, accessToken).then(data => {
          if (data.errorMessage !== '') {
            apiUtil.toast(data.errorMessage, 'error');
          } else {
            let customerList = []
            if (data.data.length > 0) {
              customerList.push({
                value: null,
                label: 'Select Warehouse',
                name: 'Select Warehouse'
              })
              data.data.forEach((val) => {
                customerList.push({
                  value: val.id,
                  label: val.company_name,
                  name: val.company_code
                });
              });
            }
            this.setState({
              customerList: customerList,
              isLoading: false,
            });
          }
        }).catch(error => console.log(error));
      }

      handleCustomerChange = (event) => {
        this.setState({
          selectedCustomer: {
            id: event.value,
            name: event.company_name,
            label: event.company_code
          }
        }, () => {
          this.callReadApi(this.state.render_table);
        });
      }     

       /* TABLE */
  customTable = () => {
    return <CustomTable 
      ref={this.refTable}
      className={'custom-table-list custom-table-sale-return'}
      isLoading={this.state.isLoading}
      grouping={false}
      selection={false}
      page={this.state.page}
      total={this.state.total}
      limit={this.state.limit}
      data={this.state.data}
      // paging={false}
      onPageChange={(page,limit) => {
        this.setState({
          limit:limit,
          page:page
        }, () => {
          this.callReadApi();
        });
        }}
      onSelectionChange={(selectedRows) => {
        // console.log("selectedRows", selectedRows);
      }}
      Row={this.customRow}
      columns={[
        
        { 
            title: "Item Name ", 
            field: "name",
            
        },  
        { 
            title: "SKU", 
            field: "sku",
        },
        { 
          title: "Customer", 
          field: "customer",
          render: (row, type) => {
            return (
              <div >
               {row.customer.company_name ? row.customer.company_name : ''}
              </div>
            );
          }
      },
      { 
        title: "Weight", 
        field: "weight",
        render: (row, type) => {
          return (
            <div >
             {row.weight ? row.weight : 0}
            </div>
            );
          }
      },
      { 
        title: "Creation Date", 
        field: "created_date",
        render: (row, type) => {
          return (
            <div >
            {row.created_at ? moment(row.created_at).format('YYYY-MM-DD') : ''}
            </div>
            );
          }
      },
      { 
        title: "Expire Date", 
        field: "expire_date",
        render: (row, type) => {
          return (
            <div >
            {row.expire_date ? moment(row.expire_date).format('YYYY-MM-DD') : ''}
            </div>
          );
        }
      },
      ]}
    />
  }

  committedStockTable = () => {
    return <CustomTable 
      ref={this.refTable}
      className={'custom-table-list'}
      isLoading={this.state.isLoading}
      grouping={false}
      selection={false}
      page={this.state.page}
      total={this.state.total}
      limit={this.state.limit}
      data={this.state.data}
      paging={true}
      onSelectionChange={(selectedRows) => {
        // console.log("selectedRows", selectedRows);
      }}
      // Row={this.customRow}
      columns={[
        
        { 
            title: "Item Name ", 
            field: "name",
            
        },
      { 
        title: "Committed Stock", 
        field: "committed_stock",
        render: (row, type) => {
          return (
            <div >
             {row.committed_stock ? row.committed_stock : 0}
            </div>
          );
        }
      }
      ]}
    />
  }

  inventoryValuationTable = () => {
    return <CustomTable 
      ref={this.refTable}
      className={'custom-table-list custom-table-sale-return'}
      isLoading={this.state.isLoading}
      grouping={false}
      selection={false}
      page={this.state.page}
      total={this.state.total}
      limit={this.state.limit}
      data={this.state.data}
      paging={false}
      onSelectionChange={(selectedRows) => {
        // console.log("selectedRows", selectedRows);
      }}
      // Row={this.customRow}
      columns={[
        
        { 
            title: "Item Name ", 
            field: "name",
            
        },  
        { 
            title: "SKU", 
            field: "sku",
        },
        { 
          title: "Stock On Hand", 
          field: "stock_on_hand",
        },
        { 
          title: "Inventory Asset Value", 
          field: "asset_value",
        }
      ]}
    />
  }

  // callExportXLSApi = () => {
  //   const  accessToken = localStorage.getItem("access_token");

  //   this.setState({
  //       downloadIsLoading: true
  //   }, () => {
  //     let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
  //     let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
  //     let render_table = this.state.render_table

  //     let param = {
  //       start_date: startDate,
  //       end_date: endDate,
  //       customer_id: this.state.selectedCustomer? this.state.selectedCustomer.id : ''
  //     };

  //     if (render_table == 'inventory_summary' ) {
  //       param['inventory_summary'] = true
  //     }
  //     if (render_table == 'committed_stock') {
  //       param['committed_stock'] = true
  //     }

  //     if (render_table == 'inventory_valuation') {
  //       param['inventory_valuation'] = true
  //     }

  //     wmsApi.exportXLS('admin/api/export/report_items', param, accessToken)
  //       .then((result) => {
  //         apiUtil.parseResult(result, (response) => {
  //           apiUtil.saveToFile(response, `${render_table} ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.xls`);
    
  //           this.setState({
  //             downloadIsLoading: false,
  //           });
  //         }, (error, type) => {
  //           this.setState({
  //             downloadIsLoading: false,
  //           });
  //         });
  //     });
  //   });
  // }

   /* EXPORT API */
  //  callExportPDFApi = () => {
  //   let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
  //         let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());
  //         let pdf = new jsPDF("p", "mm", "a4");
  //         let render_table = this.state.render_table
  //         console.log('render_table', render_table)
  //         var col = [];
  //         if (render_table == 'inventory_summary' ) {
  //           col = ["Item Name", "SKU", "Warehouse Name", "Quantity in", "Quantity out", "Stock On Hand", "Committed Stock", "Available For Sale"];
  //         }
  //         else if (render_table == 'committed_stock') {
  //           col = ["Item Name", "Committed Stock"];
  //         }
    
  //         else if (render_table == 'inventory_valuation') {
  //           col = ["Item Name", "SKU", "Stock On Hand", "Inventory Asset Value"];
  //         }
          
  //         var rows = [];

  //          let data = this.state.data;
  //           console.log('data', data)
  //          data.forEach(element => { 
  //           var temp = [];  
  //           if (render_table == 'inventory_summary' ) {
  //             temp = [element.name, element.sku, element.warehouse.name, element.opening_stock, element.quantity_out, element.stock_on_hand, element.committed_stock, element.available_sale];
  //           }
  //           else if (render_table == 'committed_stock') {
  //             temp = [element.name, element.committed_stock];
  //           }
  //           else if (render_table == 'inventory_valuation') {
  //             temp = [element.name, element.sku, element.stock_on_hand, element.asset_value]
  //           }
  //           rows.push(temp);
    
  //       });        
    
  //           pdf.autoTable(col, rows, { 
  //             // startY: 10,
  //             margin: { horizontal: 10 },
  //             styles: { overflow: "linebreak" },
  //             bodyStyles: { valign: "top" },
  //             columnStyles: { email: { columnWidth: "wrap" } },
  //             theme: "striped",
  //             // showHead: "everyPage",
  //             didDrawPage: function (data) {
  //               // Header
  //               pdf.setFontSize(16);
  //               pdf.setTextColor("#161C22");
        
  //               // Footer
  //               let str = "" + pdf.internal.getNumberOfPages();
  //               pdf.setFontSize(10);
        
  //               // jsPDF 1.4+ uses getWidth, <1.4 uses .width
  //               let pageSize = pdf.internal.pageSize;
  //               let pageHeight = pageSize.height
  //                 ? pageSize.height
  //                 : pageSize.getHeight();
  //               // pdf.text("Footer text", data.settings.margin.left, pageHeight - 10);
  //               pdf.text(575, 830, str);
  //             } });

  //             if (render_table == 'inventory_summary' ) {
  //               pdf.save(`inventory_summary ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.pdf`);
  //             }
  //             else if (render_table == 'committed_stock') {
  //               pdf.save(`committed_stock ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.pdf`);
  //             }
  //             else if (render_table == 'inventory_valuation') {
  //               pdf.save(`inventory_valuation ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.pdf`);
  //             }
  //              else {
  //               pdf.save(`${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.pdf`);
  //             }
  // }
  /* END EXPORT API */

        /* EXPORT API */
        callExportXLSApi = () => {
          const  accessToken = localStorage.getItem("access_token");
  
          this.setState({
              downloadIsLoading: true
          }, () => {
            let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
            let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());
  
            let param = {
              start_date: startDate,
              end_date: endDate,
              customer_id: this.state.selectedCustomer? this.state.selectedCustomer.id : ''
            };
  
            wmsApi.exportXLS('admin/api/export/items', param, accessToken)
              .then((result) => {
                apiUtil.parseResult(result, (response) => {
                  apiUtil.saveToFile(response, `Items ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.xls`);
          
                  this.setState({
                    downloadIsLoading: false,
                  });
                }, (error, type) => {
                  this.setState({
                    downloadIsLoading: false,
                  });
                });
            });
          });
        }
        /* END EXPORT API */
  
          /* EXPORT API pdf*/
          callExportPDFApi = () => {
            let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
            let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());
            let pdf = new jsPDF("p", "mm", "a4");
            var col = ["Name","SKU", "weight", "Client Name", "Creation Date", "Expire Date"];
            // var col1 = [ "Brand"];
            var rows = [];
            // var rows1 = [];
  
             let data = this.state.data;
  
             data.forEach(element => {      
                var temp = [element.name? element.name : '', element.sku, element.weight, element.customer? element.customer.company_name : '', moment(element.created_at).format('DD-MMM-YYYY'), moment(element.expire_date).format('DD-MMM-YYYY')];
                // var temp1 = [];
                rows.push(temp);
                // rows1.push(temp1);
              });        
      
              pdf.autoTable(col, rows, { 
                // startY: 10,
                margin: { horizontal: 10 },
                styles: { overflow: "linebreak" },
                bodyStyles: { valign: "top" },
                columnStyles: { email: { columnWidth: "wrap" } },
                theme: "striped",
                // showHead: "everyPage",
                didDrawPage: function (data) {
                  // Header
                  pdf.setFontSize(16);
                  pdf.setTextColor("#161C22");
          
                  // Footer
                  let str = "" + pdf.internal.getNumberOfPages();
                  pdf.setFontSize(10);
          
                  // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                  let pageSize = pdf.internal.pageSize;
                  let pageHeight = pageSize.height
                    ? pageSize.height
                    : pageSize.getHeight();
                  // pdf.text("Footer text", data.settings.margin.left, pageHeight - 10);
                  pdf.text(575, 830, str);
                } });
             
                // pdf.autoTable(col1, rows1, { 
                //   // startY: 60,
                //   margin: { horizontal: 10 },
                //   styles: { overflow: "linebreak" },
                //   bodyStyles: { valign: "top" },
                //   columnStyles: { email: { columnWidth: "wrap" } },
                //   theme: "striped",
                //   // showHead: "everyPage",
                //   didDrawPage: function (data) {
                //     // Header
                //     pdf.setFontSize(16);
                //     pdf.setTextColor("#161C22");
            
                //     // Footer
                //     let str = "" + pdf.internal.getNumberOfPages();
                //     pdf.setFontSize(10);
            
                //     // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                //     let pageSize = pdf.internal.pageSize;
                //     let pageHeight = pageSize.height
                //       ? pageSize.height
                //       : pageSize.getHeight();
                //     pdf.text(575, 830, str);
                //   } });
              pdf.save(`Items ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.pdf`);
  
            // const  accessToken = localStorage.getItem("access_token");
            
            // this.setState({
            //     downloadIsLoading: true
            // }, () => {
            //   let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
            //   let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());
    
            //   let param = {
            //     start_date: startDate,
            //     end_date: endDate,
            //   };
    
  //             wmsApi.exportXLS('admin/api/export/items', param, accessToken)
  //               .then((result) => {
  //                 apiUtil.parseResult(result, (response) => {
  // console.log(response,"afds")
  //                   let pdf = new jsPDF("l", "mm", [62, 100]);
  //                   // apiUtil.saveToFile(response, `Items ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.pdf`);
            
  //                   this.setState({
  //                     downloadIsLoading: false,
  //                   });
  //                 }, (error, type) => {
  //                   this.setState({
  //                     downloadIsLoading: false,
  //                   });
  //                 });
  //             });
  //           });
          }
          /* END EXPORT API pdf*/
  
    render () {
        let tabs = ['Item Movement', 'Inventory']
        return  <Box className="dashboard-page">
        <StickyPageHeader>
          <Container>
          <BreadCrumb />
          <Row>
          <Col className="col-12 col-md-12 col-lg-4"> <h1 className="page-title">Inventory Reports</h1></Col>
            <Col className="col-12 col-md-12 col-lg-8">
            <Row>
          {/* <Col className="col-12 col-md-6 col-lg-5 d-flex justify-content-center align-items-center">
                <DropdownMenu
                  variant = 'primary'
                  text={this.state.seletctedTable}
                  className={'status-btn  w-100'}
                  rightIcon={<ArrowDownIcon />}
                  color={"none"}
                  items={[
                      {text:"Inventory Summary", table:"inventory_summary"},
                      {text:"Committed Stock Details", table:"committed_stock"}, 
                      {text:"Inventory Valuation Summary", table:"inventory_valuation"}
                    ]}
                  onClick={(item) => {
                      this.setState({
                        render_table: item.table,
                        seletctedTable: item.text,
                      }, () => {
                        this.callReadApi(item.table)
                      })
                      
                  }}
                />
          </Col> */}
          <Col className="col-12 col-md-12 col-lg-12 d-flex justify-content-end align-items-center">
          <CustomDateRangePicker
              className={'select-dates-step me-2'}
              range={this.state.rangeDate}
              onChange={(range) => {
                localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));

                this.setState({
                  rangeDate: range,
                  startDate: range.startDate,
                  endDate: range.endDate
                },() => {
                    this.callReadApi(this.state.render_table);
                  });
              }}
            />
             <label class="dropdown me-3 py-3">
              <div class="dd-button-rr secondary">
              <MenuIcon width="29px" height="auto" />
              </div>

              <input type="checkbox" class="dd-input" id="test" />

              <ul class="dd-menu">
                  <li onClick={()=> this.callExportXLSApi()}><ExportIcon width="18px" height="auto" className="mx-2"/> Export CSV</li>
                  <li onClick={()=> this.callExportPDFApi()}><ExportIcon width="18px" height="auto" className="mx-2"/> Export PDF</li>
              </ul>
            </label>
            </Col>
            {/* <Col className="col-12 col-md-6 col-lg-4 border"> */}
           
            {/* <CustomButton 
              type={'submit'}
              color={'primary'}
              isLoading={this.state.downloadIsLoading}
              onClick={e=>{
                this.callExportXLSApi()
              }}
            >
              <ExportIcon width="18px" height="auto" className="mx-2"/>Export CSV
            </CustomButton> */}
            {/* </Col> */}
            </Row>
            </Col>
            </Row>
         
       
            <Box clone pr={1} ml={4} mt={3}>
                <Grid xs={12} lg={10}>
                    <GroupButton
                    className={'head-tabs'}
                    color={'secondary'}
                    selected={this.state.reportTab}
                    buttons={tabs}
                    onClick={(selected, btn) => {
                        if(selected === 0){
                        this.props.history.push('/report/sales');
                        }
                    }}
                    />
                </Grid>
            </Box>
            </Container>
        </StickyPageHeader>
        {this.state.render_table == "inventory_summary" && <Box mt={3}>
            <Box style={{"textAlign": "center"}}>
              <h3 className="report-header">Inventory Summary</h3>
              <p>from {moment(this.state.rangeDate.startDate).format('DD-MMM-YYYY')} to {moment(this.state.rangeDate.endDate).format('DD-MMM-YYYY')}</p>
            </Box>
            <Card>
                <CardBody>
                    <Row>
                        <Col xs="12" md="6" lg="6" className="d-flex align-items-center">
                            <div>
                            Customer Name
                            </div>
                            <div className="ms-2 my-1 w-50">
                              <Select className="option-select" value={{label: this.state.selectedCustomer.name, value: this.state.selectedCustomer.id}} options={this.state.customerList} onChange={(e) => this.handleCustomerChange(e)} />
                            </div>
                        </Col>
                        <Col md="12">
                        {this.customTable()}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Box>}
        {/* {this.state.render_table == "committed_stock" && <Box mt={3}>
            <Box style={{"textAlign": "center"}}>
              <h1 className="report-header">Committed Stock Details</h1>
              <p>from {moment(this.state.rangeDate.startDate).format('DD-MMM-YYYY')} to {moment(this.state.rangeDate.endDate).format('DD-MMM-YYYY')}</p>
            </Box>
            <Card>
                <CardBody>
                    <Row>
                        <Col md="12">
                        {this.committedStockTable()}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Box>}
        {this.state.render_table == "inventory_valuation" && <Box mt={3}>
            <Box style={{"textAlign": "center"}}>
              <h1 className="report-header">Inventory Valuation Summary</h1>
              <p>from {moment(this.state.rangeDate.startDate).format('DD-MMM-YYYY')} to {moment(this.state.rangeDate.endDate).format('DD-MMM-YYYY')}</p>
            </Box>
            <Card>
                <CardBody>
                    <Row>
                        <Col md="12">
                        {this.inventoryValuationTable()}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Box>} */}
        {/* {this.state.render_table == "stock_summary" && <Box mt={3}>
            <Box style={{"textAlign": "center"}}>
              <h1 className="report-header">Stock Summary Report</h1>
              <p>from {moment(this.state.rangeDate.startDate).format('DD-MMM-YYYY')} to {moment(this.state.rangeDate.endDate).format('DD-MMM-YYYY')}</p>
            </Box>
            <Card>
                <CardBody>
                    <Row>
                        <Col md="12">
                        {this.customTable()}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Box>} */}
        
      </Box>
    }
}

export default InventoryReport;