import React, { useCallback } from 'react';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';

import './assets/scss/main.scss';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthDataProvider from "./components/Auth/AuthDataProvider";
import RouteLayout from "./layout/Main/RouteLayout";

import PageNotFound from "./views/PageNotFound/PageNotFound";
import { RoleAccessService } from './data/role-access';

import Customers from "./views/Customer";
import SalesOrder from "./views/SalesOrder";
import InboundOrder from "./views/InboundOrder"
import SaleReturn from "./views/SaleReturn";
import DeliveryOrder from "./views/DeliveryOrder";
import SelfCollection from "./views/SelfCollection";
import BalanceEnquiry from "./views/BalanceEnquiry";
import Report from "./views/Report";
import ItemColection from "./views/Item";
import ItemMovement from './views/Item/itemMovement';
import ItemDetail from './views/Item/itemDetails';
import ItemList from './views/Item/list';
import ItemForm from './views/Item/form';
import WareHouse from './views/warehouse/warehouse';
import AddWareHouse from './views/warehouse/addWareHouse';
import WareHouseDetails from './views/warehouse/wareHouseDetail';
import ItemMovementDetail from './views/Item/movementDetail';
import SalesOrderList from "./views/SalesOrder/list";
import SalesOrderForm from "./views/SalesOrder/form";
import SaleOrderDetails from "./views/SalesOrder/saleOrderDetail";
import InboundOrderList from './views/InboundOrder/list';
import InboundOrderForm from './views/InboundOrder/form';
import InboundEditForm from './views/InboundOrder/inboundEditForm';
// import InboundOrderDetail from './views/InboundOrder/saleOrderDetail'
import InboundOrderDetail from './views/InboundOrder/inboundDetails'
import AgingReport from './views/Report/aging';
import InventoryReport from './views/Report/InventoryReport';
import GeneralReport from './views/Report/general';
// import OrderCollection from "./views/order"
import Dashboard from './views/Dashboard/Dashboard'
import WareHouseCollection from './views/warehouse';
import ChangePassword from './views/Account';
import MovementForm from './views/Item/movementForm';
import BalanceEnquiryList from './views/BalanceEnquiry/list';

var isClicked = false;

function App() {

  let grantedAccess = new RoleAccessService();
  let currentRole = window.localStorage.getItem('current_role');
  var path = window.localStorage.getItem('access_token');
  if(path == null){
    if( ! (window.location.pathname == "/Sign-in"  || window.location.pathname == "/Sign-up") ) {
      window.location.href ="Sign-in";
    }
  }
  
  const beforeinstallpromptHandler = useCallback(
    (deferredPrompt) => {
      if(!isClicked){
        toast(
          <button 
            onClick={() => {
              deferredPrompt.prompt();
    
              deferredPrompt.userChoice
                .then((choiceResult) => {
                  isClicked = true;
              });
            }}  
          >Install</button>
        );
      }
    }, []
  );

  window.addEventListener('beforeinstallprompt', beforeinstallpromptHandler);

  return (
    <AuthDataProvider>
      <div className="App">
        <BrowserRouter basename="/">
          <Switch>
            <Route exact path="/">
              {/* <Redirect to="/dashboard" /> */}
              {
                grantedAccess.isPermissionActionAllowed(currentRole, 'Show Dashboard')? <Redirect to="/dashboard" /> :(
                grantedAccess.isShouldShowMenu(currentRole, 'Outbound Order List')? <Redirect to="/sales-orders" /> :(
                grantedAccess.isPermissionActionAllowed(currentRole, "Customer Browse")? <Redirect to="/customers" /> :(
                grantedAccess.isShouldShowMenu(currentRole, 'Item List')? <Redirect to="/items/all-items" /> : <Redirect to="/dashboard" /> )))
              } 
            </Route>
            <Route exact path="/items">
              <Redirect to="/items/all-items" />
            </Route>
            <RouteLayout path="/customers" component={Customers}  menu_name={"Customer Browse"}/>
            <RouteLayout path="/dashboard" component={Dashboard} menu_name={"Show Dashboard"}/>
            {/* <RouteLayout path="/order" component={OrderCollection} /> */}
            {/* <RouteLayout path="/transaction/sales-order" component={SalesOrder} /> */}
            <Route exact path="/sales-order">
              <Redirect to="/sales-orders" />
            </Route>
            <RouteLayout path="/sales-orders" component={SalesOrderList} menu_name={"Outbound Order List"}/>
            <RouteLayout path="/sales-order/create" component={SalesOrderForm} menu_name={"Outbound Order Add"}/>
            <RouteLayout path="/sales-order/edit" component={SalesOrderForm} menu_name={"Outbound Order Edit"}/>
            <RouteLayout path="/sales-order/order-detail" component={SaleOrderDetails} menu_name={"Outbound Order Read"}/>
            {/* <RouteLayout path="/transaction/inbound-order" component={InboundOrder} /> */}
            <Route exact path="/inbound-order">
              <Redirect to="/inbound-orders" />
            </Route>
            <RouteLayout path="/inbound-orders" component={InboundOrderList} menu_name={"Outbound Order List"}/>
            <RouteLayout path="/inbound-order/create" component={InboundOrderForm} menu_name={"Outbound Order Add"}/>
            <RouteLayout path="/inbound-order/edit" component={InboundEditForm} menu_name={"Outbound Order Edit"}/>
            <RouteLayout path="/inbound-order/order-detail" component={InboundOrderDetail} menu_name={"Outbound Order Read"}/>

            <Route exact path="/sale-return">
              <Redirect to="/sale-return/all" />
            </Route>
            <RouteLayout path="/sale-return" component={SaleReturn}  menu_name={"Outbound Order List"}/>

            <RouteLayout path="/transaction/delivery-order" component={DeliveryOrder} />
            <RouteLayout path="/transaction/self-collection" component={SelfCollection} />
            <Route exact path="/items/balance-enquiry">
              <Redirect to="/items/balance-enquiry/list" />
            </Route>
            <RouteLayout path="/items/balance-enquiry/list" component={BalanceEnquiryList} menu_name={"Balance Enquiry List"} />
            <Route exact path="/warehouse">
              <Redirect to="/warehouses" />
            </Route>
            <RouteLayout path="/warehouses" component={WareHouse} menu_name={"Warehouse List"} />
            <RouteLayout path="/warehouse/create" component={AddWareHouse} menu_name={"Warehouse Add"}/>
            <RouteLayout path="/warehouse/edit" component={AddWareHouse} menu_name={"Warehouse Edit"}/>
            <RouteLayout path="/warehouse/warehouse-detail" component={WareHouseDetails} menu_name={"Warehouse Read"}/>

            {/* <RouteLayout path="/report" component={Report} /> */}
            <Route exact path="/report">
              <Redirect to="/reports" />
            </Route>
            <RouteLayout path="/reports" component={GeneralReport} menu_name={"Item Report"} />
            <RouteLayout path="/report/sales" component={GeneralReport} menu_name={"Item Report"} />
            <RouteLayout path="/report/aging" component={AgingReport} menu_name={"Aging Report"} />
            <RouteLayout path="/report/inventory" component={InventoryReport} menu_name={"Item Report"} />

            {/* <RouteLayout path="/items" component={ItemColection}  /> */}
            <RouteLayout path="/items/item-movements" component={ItemMovement} menu_name={"Item Movement List"} />
            <RouteLayout path="/items/item-movement-detail" component={ItemMovementDetail} menu_name={"Item Movement Show"} />
            <RouteLayout path="/items/all-items" component={ItemList} menu_name={"Item List"} />
            <RouteLayout path="/items/create" component={ItemForm} menu_name={"Item Add"} />
            <RouteLayout path="/items/edit" component={ItemForm} menu_name={"Item Edit"} />
            <RouteLayout path="/items/movement-form" component={MovementForm} />
            <RouteLayout path="/items/item-detail" component={ItemDetail} menu_name={"Item Read"} />
            <RouteLayout path="/password" component={ChangePassword} menu_name = {"password"} />   
            <Route component={PageNotFound} />
          </Switch>
        </BrowserRouter>
        <ToastContainer autoClose={false} />
      </div>
      {/*<SessionTimeout />*/}
    </AuthDataProvider>
  );
}


export default App;
