import React, { Component } from "react";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { 
  Box,
  CircularProgress,
  Typography
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { AuthContext } from "../../components/Auth/AuthDataProvider";
import { ReactComponent as MenuIcon } from '../../assets/img/icons/menu.svg';
import { ReactComponent as ImportIcon } from '../../assets/img/icons/import.svg';
import { ReactComponent as ExportIcon } from '../../assets/img/icons/export.svg';
import MUIDataTable from "mui-datatables";
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import IconButton from "../../components/Button/IconButton";
import RemoveDialog from '../../components/Dialog/RemoveDialog';
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker';

import Select from 'react-select';
import { Row, Label, InputGroup, InputGroupButtonDropdown, DropdownToggle,DropdownMenu, DropdownItem, Input, Card, CardBody, Col , Container} from "reactstrap";

import apiUtil from "../../api/apiUtil";
import wmsApi from "../../api/wmsApi";
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import Table from '../../components/Table/Table.jsx';
import ImportItemDialog from "./Partial/ImportItemDialog";
import moment from "moment";
import { RoleAccessService } from "../../data/role-access";
import defImg from '../../assets/img/default_item.jpg';

class ItemList extends Component {
  static contextType = AuthContext;
    constructor(porps) {
        super(porps)

        let rangeDate = {
          startDate: moment().startOf('month'),
          endDate: moment().endOf('month'),
        };
        let grantedAccess = new RoleAccessService();
        this.state = {
            grantedAccess : grantedAccess,
            currentRole: window.localStorage.getItem('current_role') || null,
            isLoading: false,
            importModal: false,
            page: 0,
            limit: 20,
            total: 0,
            data: [],
            openDialog: false,
            openDialogItem: null,
            rangeDate: rangeDate,
            startDate: rangeDate.startDate,
            endDate: rangeDate.endDate,
            selectedRows: []
        }
    }

    componentDidMount = () => {
        this.callReadApi()
    }

    
    callReadApi = (search = '') => {
        // const  accessToken = localStorage.getItem("access_token");
        const { accessToken } = this.context;

        let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
        let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

        this.setState({
          isLoading: true
        }, () => {
          let param = {
            page: this.state.page,
            take: this.state.limit,
            sort: "latest",
            search: search,
            start_date: startDate,
            end_date: endDate,
            inbound_item: true
          };
    
          wmsApi.read('items', param, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                this.setState({
                  data: (data.result && data.result.length > 0) ? data.result : [],
                  total: data.total,
                  isLoading: false,
                });
              }, (error, type) => {
                this.setState({
                  data: [],
                  total: 0,
                  isLoading: false,
                });
              });
          });
        });
      }

      /* EXPORT API */
      callExportXLSApi = () => {
        const  accessToken = localStorage.getItem("access_token");

        this.setState({
            downloadIsLoading: true
        }, () => {
          let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
          let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());

          let param = {
            start_date: startDate,
            end_date: endDate,
          };

          wmsApi.exportXLS('admin/api/export/items', param, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (response) => {
                apiUtil.saveToFile(response, `Items ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.xls`);
        
                this.setState({
                  downloadIsLoading: false,
                });
              }, (error, type) => {
                this.setState({
                  downloadIsLoading: false,
                });
              });
          });
        });
      }
      /* END EXPORT API */

        /* EXPORT API pdf*/
        callExportPDFApi = () => {
          let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
          let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());
          let pdf = new jsPDF("p", "mm", "a4");
          var col = ["Name","SKU", "weight", "Client Name", "Date"];
          // var col1 = [ "Brand"];
          var rows = [];
          // var rows1 = [];

           let data = this.state.data;

           data.forEach(element => {      
              var temp = [element.name? element.name : '', element.sku, element.weight, element.customer? element.customer.company_name : '', moment(element.created_at).format('DD-MMM-YYYY')];
              // var temp1 = [];
              rows.push(temp);
              // rows1.push(temp1);
            });        
    
            pdf.autoTable(col, rows, { 
              // startY: 10,
              margin: { horizontal: 10 },
              styles: { overflow: "linebreak" },
              bodyStyles: { valign: "top" },
              columnStyles: { email: { columnWidth: "wrap" } },
              theme: "striped",
              // showHead: "everyPage",
              didDrawPage: function (data) {
                // Header
                pdf.setFontSize(16);
                pdf.setTextColor("#161C22");
        
                // Footer
                let str = "" + pdf.internal.getNumberOfPages();
                pdf.setFontSize(10);
        
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                let pageSize = pdf.internal.pageSize;
                let pageHeight = pageSize.height
                  ? pageSize.height
                  : pageSize.getHeight();
                // pdf.text("Footer text", data.settings.margin.left, pageHeight - 10);
                pdf.text(575, 830, str);
              } });
           
              // pdf.autoTable(col1, rows1, { 
              //   // startY: 60,
              //   margin: { horizontal: 10 },
              //   styles: { overflow: "linebreak" },
              //   bodyStyles: { valign: "top" },
              //   columnStyles: { email: { columnWidth: "wrap" } },
              //   theme: "striped",
              //   // showHead: "everyPage",
              //   didDrawPage: function (data) {
              //     // Header
              //     pdf.setFontSize(16);
              //     pdf.setTextColor("#161C22");
          
              //     // Footer
              //     let str = "" + pdf.internal.getNumberOfPages();
              //     pdf.setFontSize(10);
          
              //     // jsPDF 1.4+ uses getWidth, <1.4 uses .width
              //     let pageSize = pdf.internal.pageSize;
              //     let pageHeight = pageSize.height
              //       ? pageSize.height
              //       : pageSize.getHeight();
              //     pdf.text(575, 830, str);
              //   } });
            pdf.save(`Items ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.pdf`);

          // const  accessToken = localStorage.getItem("access_token");
          
          // this.setState({
          //     downloadIsLoading: true
          // }, () => {
          //   let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
          //   let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());
  
          //   let param = {
          //     start_date: startDate,
          //     end_date: endDate,
          //   };
  
//             wmsApi.exportXLS('admin/api/export/items', param, accessToken)
//               .then((result) => {
//                 apiUtil.parseResult(result, (response) => {
// console.log(response,"afds")
//                   let pdf = new jsPDF("l", "mm", [62, 100]);
//                   // apiUtil.saveToFile(response, `Items ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.pdf`);
          
//                   this.setState({
//                     downloadIsLoading: false,
//                   });
//                 }, (error, type) => {
//                   this.setState({
//                     downloadIsLoading: false,
//                   });
//                 });
//             });
//           });
        }
        /* END EXPORT API pdf*/

    callDeleteApi = (id, callback = null) => {
      const  accessToken = localStorage.getItem("access_token");
  
      this.setState({
        isLoading: true
      }, () => {
        let param = {
        };
  
        wmsApi.delete('items/' + id, param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback();
                }
              });
            }, (error, type) => {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback();
                }
              });
            });
        });
      });
    }
    /*Bulkdelte DIALOG */
    customDialogBulkDelete = () => {
      return <CustomDialog 
        open={this.state.openBlukDeleteDialog}
        title={'Bulk Delete'}
        onClose={() => {
          this.setState({
            openBlukDeleteDialog: false,
            openDialogItem: null,
          });
        }}
        onOk={() => {
          let selectedRow = this.state.selectedRows;
          let ids = '';
          if(selectedRow.length > 0) {
            selectedRow.map((item, i) => { ids += item.id +','; });
          } else {
            apiUtil.toast('Select the items first', 'error');
            this.setState({
              openBlukDeleteDialog: false
            })
            return
          }
          ids = ids.slice(0, -1);
          this.callDeleteApi(ids, () => {
            this.setState({
              openBlukDeleteDialog: false,
              openDialogItem: null,
            }, () => {
              this.callReadApi();
              apiUtil.toast('Successfully Deleted', 'success');
            });
          });
        }}
      >
        <Box>Are you sure you want to delete selected items?</Box>
      </CustomDialog>
    }

    /* DIALOG */
    customDialog = () => {
      return <CustomDialog 
        open={this.state.openDialog}
        title={'Delete'}
        onClose={() => {
          this.setState({
            openDialog: false,
            openDialogItem: null,
          });
        }}
        onOk={() => {
          let row = this.state.openDialogItem;
          this.callDeleteApi(row, () => {
            this.setState({
              openDialog: false,
              openDialogItem: null,
            }, () => {
              this.callReadApi();
              apiUtil.toast('Successfully Deleted', 'success');
            });
          });
        }}
      >
        <Box>Are you sure you want to delete?</Box>
      </CustomDialog>
    }

    deleteData = id => {
      this.setState({
        openDialog: true,
        openDialogItem: id
      });
    }

    callImportApi = (row, callback = null) => {
      const  accessToken = localStorage.getItem("access_token");
      let param = {
        _json : row
      }
  
      this.setState({
        isLoading: true
      }, () => {
        wmsApi.create('create/items/multiple', param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(data);
                }
              });
            }, (error, type) => {
              this.setState({
                isLoading: false,
              }, () => {
                apiUtil.toast(error, 'error');
              });
            });
        });
      });
    }

    importDialog = () => {
      return <ImportItemDialog
        modal={this.state.importModal}
        onImport={(form) => {
          this.setState({
            importModal: false
          }, () => {
            this.callImportApi(form, (data) => {
              let all_jobs = (form && form.length > 0) ? form.length : 0;
              let failed_items  = (data && data.failed_items && data.failed_items.length > 0) ? data.failed_items.length : 0;
              let invalid_batch_id = (data && data.invalid_batch_id && data.invalid_batch_id.length > 0) ? data.invalid_batch_id.length : 0;
              let invalid_customers = (data && data.invalid_customers && data.invalid_customers.length > 0) ? data.invalid_customers.length : 0;
              let saved_items  = (data && data.saved_items  && data.saved_items .length > 0) ? data.saved_items .length : 0;
              
              if(failed_items > 0){
                apiUtil.toast(failed_items + ' of ' + all_jobs + ' items have not successfully imported', 'error');
              }

              if(invalid_batch_id > 0){
                data.invalid_batch_id.map((invalid)=>  {
                return apiUtil.toast("batch id " + invalid.batch_id + ' already exit, items have not successfully imported',  'error');
                 } )
              }
  
              if(invalid_customers > 0){
                apiUtil.toast(invalid_customers + ' of ' + all_jobs + ' customer does not match, items have not successfully imported',  'error');
              }

              if(saved_items > 0){
                apiUtil.toast(saved_items + ' of ' + all_jobs + ' items successfully imported', 'success');
              }
  
              this.callReadApi();
  
              this.setState({
                importModal: false
              });
            });
          });
          // console.log(form, "the excel data")
        }}
        onClose={() => {
          this.setState({
            importModal: false
          });
        }}
      />
    }

    /* TABLE */
    customTable = () => {
      return <Table 
        ref={this.refTable}
        className={'custom-table-list'}
        isLoading={this.state.isLoading}
        grouping={false}
        selection={true}
        page={this.state.page}
        total={this.state.total}
        limit={this.state.limit}
        onPageChange={(page,limit) => {
          this.setState({
            limit:limit,
            page:page
          }, () => {
            this.callReadApi();
          });
          }}
        data={this.state.data}
        onSelectionChange={(selectedRows) => {
          // console.log("selectedRows", selectedRows);
          this.setState({selectedRows})
        }}
        // Row={this.customRow}
        columns={[
          { 
              title: "Name ", 
              field: "name",
              cellStyle: {
                minWidth: 200,
                maxWidth: 200
              },
              render: (row, type) => {
                return (
                  <div >
                    {/* {row.item_images.length > 0 ? <img src={row.item_images[0].image_url} width="30px" height="auto" style={{margin:"2px", marginRight: "5px"}}/> : <img src={defImg} width="30px" height="auto" style={{margin:"2px", marginRight: "5px"}}/>} */}
                    <a href={'./item-detail?id=' + row.id}>
                      {row.name}
                    </a>
                  </div>
                );
              }
              
          },
        { 
            title: "SKU", 
            field: "sku",
            cellStyle: {
              minWidth: 200,
              maxWidth: 200
            },
        },  
        // { 
        //     title: "Stock On Hand", 
        //     field: "stock_on_hand",
        // },
        // { 
        //   title: "Brand", 
        //   field: "brand",
        //   render: (row, type) => {
        //         return (
        //           <div >
        //            {row.brand ? row.brand : ''}
        //           </div>
        //         );
        //       }
        // },
        { 
          title: "Weight", 
          field: "weight",
        },
        { 
          title: "Client Name", 
          field: "client_name",
          render: (row, type) => {
                return (
                  <div >
                   {row.customer ? row.customer.company_name : ''}
                  </div>
                );
              }
        },
        // { 
        //   title: "Committed Stock", 
        //   field: "committed_stock",
        //   render: (row, type) => {
        //     return (
        //       <div >
        //        {row.committed_stock ? row.committed_stock : 0}
        //       </div>
        //     );
        //   }
        // },
        // { 
        //   title: "Available For Sale", 
        //   field: "available_stock",
        //   render: (row, type) => {
        //     return (
        //       <div >
        //        {row.stock_on_hand - row.committed_stock}
        //       </div>
        //     );
        //   }
        // },
        { 
            title: "Date", 
            field: "created_at",
            cellStyle: {
              minWidth: 200,
              maxWidth: 200
            },
            render: (row, type) => {
                if(type === 'row'){
                    return <Box>
                    {moment(row.created_at).format('DD-MMM-YYYY')}
                  </Box>;
                } else {
                    return row;
                }
            }
        },
        {
          title: "Actions",
          field: "action",
          render: (row, type) => {
            return (
              <div className="btn-group">
                {this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Item Edit") && <a href={'./edit?id=' + row.id}>
                  <EditIcon className="fix-icon-color" />
                </a> }
                {this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Item Delete") && <button type="button" onClick={() => this.deleteData(row.id)}>
                  <DeleteIcon />
                </button>}
              </div>
            );
          }
        }
        ]}
      />
    }


    render () {
        return <Box className="dashboard-page">
        <StickyPageHeader>
        <Container>
          <Row className="d-flex align-items-center">
              <Col xs={12} lg={6}>
                <BreadCrumb />
                <h1 className="page-title">Items</h1>
              </Col>
              <Col xs={12} lg={6}>
              <div className='d-flex justify-content-end align-items-center'>
          <CustomDateRangePicker
              className={'select-dates-step width-adjust-time-picker mx-3'}
              range={this.state.rangeDate}
              onChange={(range) => {
                localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));

                this.setState({
                  rangeDate: range,
                  startDate: range.startDate,
                  endDate: range.endDate
                }, ()=> {
                  this.callReadApi()
                });
              }}
            />
          {this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Item Add")  && <IconButton className="btn-theme btn-theme-1 py-2" label="Add New" icon="add" onClick={() => this.props.history.push('./create')} /> }
          <label class="dropdown me-3">
              <div class="dd-button-rr secondary">
              <MenuIcon width="26px" height="auto" />
              </div>

              <input type="checkbox" class="dd-input" id="test" />

              <ul class="dd-menu">
                  {/* <li><a href="./import-items" style={{color: "#000"}}><ImportIcon width="18px" height="auto" className="mx-2"/> Import Items</a></li> */}
                  <li onClick={()=> this.setState({importModal:true})}><ImportIcon width="18px" height="auto" className="mx-2"/> Import Items</li>
                  <li onClick={()=> this.callExportXLSApi()}><ExportIcon width="18px" height="auto" className="mx-2"/> Export CSV</li>
                  <li onClick={()=> this.callExportPDFApi()}><ExportIcon width="18px" height="auto" className="mx-2"/> Export PDF</li>
                  <li onClick={()=> this.setState({openBlukDeleteDialog:true})}><DeleteIcon width="15px" height="auto" className="mx-2"/> Bulk Delete</li>
              </ul>
            </label>
            </div>
              </Col>
          </Row>
          </Container> 
        </StickyPageHeader>
        <Card>
        <CardBody>
          <Row>
            <Col md="12">
              {this.customTable()}
            </Col>
          </Row>
        </CardBody>
      </Card>
      {this.customDialog()}
      {this.importDialog()}
      {this.customDialogBulkDelete()}
      </Box>

    }

}

export default ItemList;