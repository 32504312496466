import React from 'react';

import {
  Col,
  Row,
  Label,
  Button,
  ButtonGroup,
  FormGroup,
  Input
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { locationData } from '../../../data/misc';
import ImportItemDialog from "./ImportItemDialog";

export default class AddItemForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      importModal: false,
      displayDate: '',
      formData: {
        id: null,
        delete: null,
        part_no: '',
        description: '',
        lot_no: '',
        serial_no: '',
        quantity: '',
        width: '',
        height: '',
        length: '',
        remarks: '',
        location: '',
        expiry_date: ''
      }
    };
  }

  changeDate = date => {
    let formData = this.state.formData;
    formData.expiry_date = date.toLocaleDateString('en-US');
    this.setState({
      displayDate: date,
      formData: formData
    });
  }

  handleInputChange = event => {
    let formData = this.state.formData;
    formData[event.target.name] = event.target.value;
    this.setState({
      formData: formData
    });
  }

  handleLocationChange = event => {
    let formData = this.state.formData;
    formData.location = event.value;
    this.setState({
      formData: formData
    });
  }

  addItem = event => {
    this.props.addItem(this.state.formData);
  }

  toggleImportModal = show => {
    this.setState({
      importModal: true
    });
  }

  importDialog = () => {
    return <ImportItemDialog
      modal={this.state.importModal}
      onImport={(data) => {
        this.setState({
          importModal: false
        });
        this.props.addItem(data);
      }}
      onClose={() => {
        this.setState({
          importModal: false
        });
      }}
    />
  }

  render() {
    return (
      <Row>
        <Col xs="12">
          <h4 className="font-weight-bold mb-4">Add New Item</h4>
        </Col>

        <Col xs="6">
          <FormGroup>
            <Label className="control-label">Part No*</Label>
            <Input name="part_no" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.part_no} />
          </FormGroup>

          <FormGroup>
            <Label className="control-label">Description*</Label>
            <Input name="description" component="textarea" rows="5" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.description} />
          </FormGroup>

          <FormGroup>
            <Label className="control-label">Lot No*</Label>
            <Input name="lot_no" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.lot_no} />
          </FormGroup>

          <FormGroup>
            <Label className="control-label">Serial No*</Label>
            <Input name="serial_no" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.serial_no} />
          </FormGroup>

          <FormGroup>
            <Label className="control-label">Expiry Date</Label>
            <div className="form-input">
              <DatePicker 
                dateFormat="dd/MM/yyyy"
                className="form-control" 
                selected={this.state.displayDate} 
                onChange={date => this.changeDate(date)} 
              />
            </div>
          </FormGroup>

          <FormGroup>
            <Label className="control-label">Location</Label>
            <Select className="form-input no-border" options={locationData} onChange={this.handleLocationChange} />
          </FormGroup>
        </Col>

        <Col xs="6">
          <FormGroup>
            <Label className="control-label">Quantity*</Label>
            <Input name="quantity" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.quantity} />
          </FormGroup>

          <FormGroup>
            <Label className="control-label">Volume*</Label>
            <Input name="length" placeholder="Length (cm)" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.length} />
            <Input name="width" placeholder="Width (cm)" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.width} />
            <Input name="height" placeholder="Height (cm)" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.height} />
          </FormGroup>

          <FormGroup>
            <Label className="control-label">Remarks</Label>
            <Input name="remarks" component="textarea" rows="5" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.remarks} />
          </FormGroup>
        </Col>

        <Col xs="12" className="mt-4">
          <ButtonGroup>
            <Button 
              className="btn-multiple-state mr-3" 
              color="success" 
              outline={true}
              type="button"
              onClick={(e) => this.addItem(e)}>
              Add Item
            </Button>

            <Button 
              className="btn-multiple-state mr-3" 
              color="info" 
              outline={true}
              type="button"
              onClick={() => this.toggleImportModal(true)}>
              Import from Excel
            </Button>
          </ButtonGroup>
        </Col>
        {this.importDialog()}
      </Row>
    );
  }
}