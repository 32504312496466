import React, { Component } from 'react';

import { 
    Box,
    CircularProgress,
    Grid,
    Card,
    Container,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';

import { Row, Label, Input, Col, Table, ButtonDropdown, Dropdown} from "reactstrap";
// import Table from '../../components/Table/Table.jsx';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import IconButton from "../../components/Button/IconButton";
import apiUtil from "../../api/apiUtil";
import wmsApi from "../../api/wmsApi";
import queryString from 'query-string';
import moment from 'moment'
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={2}>
            {children}
          </Box>
        )}
      </div>
    );
}
const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`
    };
}

class SaleReturnDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: '',
            isLoading: false,
            tabValue: 0,
            tempItemOrder: { 
                id : 1,
                date : '12-02-2022',
                sale_order : '0Q-A122',
                customer_name : 'cus',
                quantity : '1.00',
                price : 'SGD1.00',
                total : 'SGD2.00',
                status : 'void',
                payment: 'Unpaid',
                shipment: 'pending',
                refernece: '1234',
                order_date: '11 jan 2022',
                payment_term: 'a dfado =ads',
                delivery_method: "adf adsf",
                subTotal : 600.00,
                item : [
                    {
                        item : "adsf",
                        order: "asdf",
                        warehouse: "evfy-data",
                        status : "asfd",
                        rate : 2.00,
                        discount: 10,
                        amount : 200.00,
                    },
                    {
                        item : "adsf 2",
                        order: "asdf 2",
                        warehouse: "evfy-data",
                        status : "asfd",
                        rate : 2.00,
                        discount: 10,
                        amount : 200.00,
                    },
                    {
                        item : "adsf 3",
                        order: "asdf 3",
                        warehouse: "evfy-data",
                        status : "sd",
                        rate : 3.00,
                        discount: 10,
                        amount : 200.00,
                    }
                ]},
            tempReturn: [{ 
                id : 1,
                date : '12-02-2022',
                sale_order : '0Q-A122',
                customer_name : 'cus',
                rma : 'RMA-0023',
                status : 'approved',
                receive_status : 'received',
                refund_status : ' ',
                returnd : ' ',
                amount_ref : 'SGD 12.33'
              }
          ]
        }
    }
    
    componentDidMount() {
        let urlQuery = queryString.parse(window.location.search);
        if (urlQuery.id !== undefined) {
            this.setState({
              dataId: urlQuery.id
            });
            this.callDetailsApi(urlQuery.id, (data) => {
                if(data) {
                    this.setState({
                        data
                    })
                }
            })
        }
    }
    callDetailsApi = (id = '', callback = null) => {
        const  accessToken = localStorage.getItem("access_token");

        this.setState({
            isLoading: true
        }, () => {
            wmsApi.read('items/' + id, null, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if(callback){
                    callback((data && data.result) ? data.result : null);
                    }
                });
                }, (error, type) => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if(callback){
                    callback(null, []);
                    }
                });
                });
            });
        });
    }

    handleChange = (event, newValue) => {
        this.setState({
            tabValue: newValue
        })
    }

    itemDetailPage = () => {
        return <>
        <Box mt={5}>
            <Grid container>
                <Grid item xs={12}>
                    <div className="d-flex justify-content-start align-items-center mt-5 mb-3">
                        <Typography className="pe-5 text-muted">24 Mar 2022 05:19 PM</Typography>
                        <Typography>Outbound Return RMA-00002 created. <span className="text-muted">by user</span></Typography>
                    </div>
                    <div className="d-flex justify-content-start align-items-center mb-5">
                        <Typography className="pe-5 text-muted">24 Mar 2022 05:19 PM</Typography>
                        <Typography>Outbound Return RMA-00002 created. <span className="text-muted">by user</span></Typography>
                    </div>
                </Grid> 
            </Grid>
            
        </Box>
        
        </>
    }

    SaleReturnPage = () => {
        return <>
        <Box>
            <Grid Container className="card-style">
            <Grid item xs={12}>
                   <h2 className='fs-3'>ORDER REUTRN</h2>
                   <p>Outbound Order# {this.state.tempItemOrder && this.state.tempItemOrder.sale_order}</p>
                   <h2 className='mt-5 fs-4'>Status</h2>
                   <div className="border-status">
                       <div className="status-width d-flex"><p className="w-50 fw-bold">Return Status</p> <p className='bg-info text-white py-2 px-4'>{this.state.tempReturn[0].status}</p></div>
                       <div className="status-width d-flex"><span className="w-50 fw-bold">Receive Status</span> <span className="text-muted">{this.state.tempReturn[0].receive_status}</span></div>
                   </div>
                   <div className="status-width-long d-flex mt-3"><p className="w-50 fw-bold">ORDER DATE</p> <p className=''>{this.state.tempReturn[0].date}</p></div>


                      <Table className="mb-5 item-table-header">
                        <thead>
                          <tr>
                              <th>#</th>
                            <th>ITEMS & DESCRIPTION</th>
                            <th>QUANTITY</th>
                            <th>RATE</th>
                          </tr>
                      </thead>
                        <tbody>
                        {this.state.tempItemOrder && this.state.tempItemOrder.item.map((item) => 
                      <tr>
                        <td>{item.id}</td>
                        <td>{item.item}</td>
                        <td>{item.discount}</td>
                        <td>{item.rate}</td>
                      </tr>
                      )}
                      </tbody>
                      </Table>



               </Grid>
            </Grid>
        </Box>
        </>
    }
    creditNote = () => {
        return <>
            <Box mt={5}>
                <Grid container className="d-flex justify-content-center pb-5">
                <Typography>No Credit Notes created yet.</Typography>
                </Grid>
            </Box>
        </>
    }

    
    Receive = () => {
        return <>
            <Box mt={5}>
            <Table className="mb-5 item-table-header">
                        <thead>
                          <tr>
                              <th>Receive#</th>
                            <th>Received On</th>
                          </tr>
                      </thead>
                        <tbody>
                      <tr>
                        <td>{this.state.tempItemOrder.id}</td>
                        <td className='d-flex justify-content-between px-3 align-items-center'>{this.state.tempItemOrder.date}
                        <DeleteIcon/>
                        </td>
                      </tr>
                      </tbody>
                      </Table>
            </Box>
        </>
    }


    importedImglist = () => {
        return <>
        {this.state.data && this.state.data.item_images.length > 0 ?
        
        this.state.data.item_images.map((img)=>{
            
           return<img src={img.image_url} width="100px" height="auto" style={{margin:"5px"}}/>
        }) : "" }

        </>
    }
    getDetails = () => {
        return <>
        <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={3} className="card-style">
        <Tabs value={this.state.tabValue} onChange={this.handleChange} aria-label="basic tabs example">
          <Tab label="COMMENT" {...a11yProps(0)} />
          <Tab label="RECEIVE" {...a11yProps(1)} />
          <Tab label="CREDIT NOTES" {...a11yProps(2)} />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={this.state.tabValue} index={0}>
        {this.itemDetailPage()}
      </TabPanel>
      <TabPanel value={this.state.tabValue} index={1}>
        {this.Receive()}
      </TabPanel>
      <TabPanel value={this.state.tabValue} index={2}>
        {this.creditNote()}
      </TabPanel>
      {/* <TabPanel value={this.state.tabValue} index={2}>
        Item Three
      </TabPanel> */}
    </Box>
        </>
    }
    render() {
        return <Box><StickyPageHeader>
              <Container>
                <BreadCrumb />
                <h1 className="page-title">Sales Return Details</h1>
                {/* <IconButton className="btn-theme btn-theme-1" label="Add New" icon="add" onClick={() => this.props.history.push('./create')} />
                <IconButton className="btn-theme btn-theme-1" label="Edit " icon="edit" onClick={() => this.props.history.push('./edit?id='+this.state.dataId)} />
                <IconButton className="btn-theme btn-theme-1" label="Adjust" icon="edit" onClick={() => this.props.history.push('./movement-form?id='+this.state.dataId)} /> */}
            </Container>
            </StickyPageHeader>
            <Container maxWidth="lg" >
                <Box mt={2}>
                    {
                  (!this.state.isLoading)
                  ?
                  <Box mt={2} >
                   <Typography variant="h5"> {this.state.data.name}</Typography>
                   {this.getDetails()}
                   {this.SaleReturnPage()}
                  </Box>
                  :
                  <Card>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
                      <CircularProgress size={35} />
                    </Box>
                  </Card>
                }
                </Box>
                
            </Container>
      </Box>

    }
}

export default SaleReturnDetail;