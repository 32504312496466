import React, { Component } from "react";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { 
  Box,
  Grid,
  CircularProgress,
  Typography,
  Container
} from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { ReactComponent as MenuIcon } from '../../assets/img/icons/menu.svg';
import { ReactComponent as ImportIcon } from '../../assets/img/icons/import.svg';
import { ReactComponent as ExportIcon } from '../../assets/img/icons/export.svg';
import MUIDataTable from "mui-datatables";
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import wmsApi from "../../api/wmsApi";

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import IconButton from "../../components/Button/IconButton";
import RemoveDialog from '../../components/Dialog/RemoveDialog';
import CustomDialog from "../../components/Dialog/CustomDialog";
import CustomInput from "../../components/CustomFields/CustomInput";
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker';
import DropdownMenu from "../../components/Dropdown/DropdownMenu";
import ImportOrderDialog from "./Partial/ImportOrderDialog";
import Select from 'react-select';
import { Row, Label, InputGroup, InputGroupButtonDropdown, DropdownToggle, DropdownItem, Card, CardBody, Col } from "reactstrap";
import { RoleAccessService } from "../../data/role-access";
import Table from '../../components/Table/Table.jsx';

import moment from 'moment';

import apiUtil from "../../api/apiUtil";
import customerApi from "../../api/Customer";
import salesOrderApi from "../../api/SalesOrder";

class InboundOrderList extends Component {
  constructor(props) {
    super(props);

    let rangeDate = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
    };

    let grantedAccess = new RoleAccessService();
    this.state = {
      grantedAccess : grantedAccess,
      currentRole: window.localStorage.getItem('current_role') || null,
      rangeDate: rangeDate,
      startDate: rangeDate.startDate,
      endDate: rangeDate.endDate,
      tableState: {
        page: 0,
        count: 1,
        rowsPerPage: 15,
        sortOrder: {}
      },
      data: [],
      isLoading: false,
      customerList: [],
      selectedCustomer: {
        value: '0',
        name: '',
        label: ''
      },
      isFilterOpen: false,
      warehouseList: [],
      selectedWarehouse: {
        id: '',
        name: ''
      },
      filter: {
        customerId: "",
        text: 'Choose..',
        field: 'query',
        value: ''
      }
    };

    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

    changePage = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  sort = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  search = (page, query) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.search = query;

    this.setState({
      isLoading: true,
      tableState: tableState,
    });

    this.getData();
  }

  handleWarehouseChange = (event) => {
    this.setState({
      selectedWarehouse: {
        id: event.value,
        name: event.name
      }
    }, ()=> {
      this.callReadApi();
    });
  }

  getData = async () => {
    let customerId = this.state.selectedCustomer.id? this.state.selectedCustomer.id : '';
    if (!customerId) {
      if (!this.state.selectedCustomer.id) {
        // apiUtil.toast('No customer selected.', 'error');
        // return false;
        customerId= "";
      }else{
        customerId = this.state.selectedCustomer.id;
      }
    }

    let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
    let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

    this.setState({ isLoading: true });

        let params = {
          customer_id: customerId,
          optional_filter_by: this.state.filter.field,
          optional_query: this.state.filter.value,
          warehouse_id: this.state.selectedWarehouse.id? this.state.selectedWarehouse.id : '',
          start_date: startDate,
          end_date: endDate,
          inbound_order: true,
        };
    

    await salesOrderApi.get(params).then(data => {
      this.setState({
        isLoading: false,
        data: data.data
      });
    }).catch(error => console.log(error)); 
    
  }

  callReadApi = (search = '') => {
    const  accessToken = localStorage.getItem("access_token");

    let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
    let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

    this.setState({
      isLoading: true
    }, () => {
      let param = {
        page: this.state.page,
        take: this.state.limit,
        sort: "latest",
        search: search,
        customer_id:this.state.selectedCustomer.id? this.state.selectedCustomer.id: '',
        warehouse_id:this.state.selectedWarehouse.id? this.state.selectedWarehouse.id: '',
        start_date: startDate,
        end_date: endDate,
        inbound_item_list: true
      };

      wmsApi.read('items', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              data: (data.result && data.result.length > 0) ? data.result : [],
              total: data.total,
              isLoading: false,
            });
          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              isLoading: false,
            });
          });
      });
    });
  }

  getCustomer = async () => {
    await customerApi.get().then(data => {
      if (Array.isArray(data.data)) {
        let customerList = [];

        customerList.push({
          value: null,
          label: 'Select Company',
          name: 'Select Company'
        })

        data.data.forEach((val) => {
          customerList.push({
            value: val.id,
            label: val.company_name,
            name: val.company_name
          });
        });

        this.setState({
          customerList: customerList
        });

        let customerFilter = window.localStorage.getItem('wms_customerFilter') || false;

        if (customerFilter) {
          customerFilter = JSON.parse(customerFilter);
          this.setState({
            selectedCustomer: {
              id: customerFilter.id,
              name: customerFilter.name,
              label: customerFilter.label
            }
          });
          // this.getData(customerFilter.id);
        }
      } else {
        apiUtil.toast('Failed to get customer data.', 'error');
      }
    }).catch(error => console.log(error));
  }

  /* EXPORT API */
  callExportXLSApi = () => {
    const  accessToken = localStorage.getItem("access_token");

    this.setState({
        downloadIsLoading: true
    }, () => {
      let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
      };

      wmsApi.exportXLS('admin/api/export/sale_orders_inbound', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (response) => {
            apiUtil.saveToFile(response, `Inbound Orders ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.xls`);
    
            this.setState({
              downloadIsLoading: false,
            });
          }, (error, type) => {
            this.setState({
              downloadIsLoading: false,
            });
          });
      });
    });
  }
  /* END EXPORT API */
    /* EXPORT API */
    callExportPDFApi = () => {
      let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
            let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());
            let pdf = new jsPDF("p", "mm", "a4");
            var col = ["Name","SKU", "Inbound Date", "Stock on Hand", "Committed Stock", "Availabel For Sale", "Warehouse", "Weight", "Client Name", "Date"];

            var rows = [];
  
             let data = this.state.data;
              console.log('data', data)
             data.forEach(element => {      

              var temp = [element.name? element.name : '', element.sku, moment(element.inbound_date).format('DD-MMM-YYYY'), element.stock_on_hand ? element.stock_on_hand:0, element.committed_stock ? element.committed_stock:0, (element.stock_on_hand ? element.stock_on_hand : 0) - (element.committed_stock ? element.committed_stock : 0), element.warehouse? element.warehouse.name:"", element.weight, element.customer? element.customer.company_name : '', moment(element.created_at).format('DD-MMM-YYYY')];
              rows.push(temp);
      
          });        
      
              pdf.autoTable(col, rows, { 
                // startY: 10,
                margin: { horizontal: 10 },
                styles: { overflow: "linebreak" },
                bodyStyles: { valign: "top" },
                columnStyles: { email: { columnWidth: "wrap" } },
                theme: "striped",
                // showHead: "everyPage",
                didDrawPage: function (data) {
                  // Header
                  pdf.setFontSize(16);
                  pdf.setTextColor("#161C22");
          
                  // Footer
                  let str = "" + pdf.internal.getNumberOfPages();
                  pdf.setFontSize(10);
          
                  // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                  let pageSize = pdf.internal.pageSize;
                  let pageHeight = pageSize.height
                    ? pageSize.height
                    : pageSize.getHeight();
                  // pdf.text("Footer text", data.settings.margin.left, pageHeight - 10);
                  pdf.text(575, 830, str);
                } });
  
              pdf.save(`Inbound Orders ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.pdf`);
    }
    /* END EXPORT API */

  toggleFilter = () => {
    let isFilterOpen = this.state.isFilterOpen;

    this.setState({
      isFilterOpen: !isFilterOpen
    });
  }

  handleCustomerChange = (event) => {
    this.setState({
      selectedCustomer: {
        id: event.value,
        name: event.name,
        label: event.label
      }
    }, () => {
      this.callReadApi();
    });

    window.localStorage.setItem('wms_customerFilter', JSON.stringify({
      id: event.value,
      name: event.name,
      label: event.label
    }));

    // this.getData(event.value);
  }

  handleFilterChange = (event) => {
    let filter = this.state.filter;
    filter.text = event.target.innerHTML;
    filter.field = event.target.value;

    this.setState({
      filter: filter
    });
  }

  handleFilterFieldChange = (event) => {
    let filter = this.state.filter;
    filter.value = event.target.value;
    
    this.setState({
      filter: filter
    });
  }

  componentDidMount = () => {
    document.title = "All Inbound Order | WMS";

    this.setState({
      selectedCustomer: ''
    }, ()=> {
      // this.getData();
      this.callReadApi()
    });

    window.scrollTo(0, 0);
    this.getCustomer();
    this.getWareHouse();
  
  }

  getWareHouse = (callback = null) => {
    const  accessToken = localStorage.getItem("access_token");

    this.setState({
      isLoading: true
    }, () => {
      let param = {
      };

      wmsApi.read('warehouses', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let warehouseList = []
            if (data.result.length > 0) {
              warehouseList.push({
                value: null,
                label: 'Select Warehouse',
                name: 'Select Warehouse'
              })
              data.result.forEach((val) => {
                warehouseList.push({
                  value: val.id,
                  label: val.name,
                  name: val.name
                });
              });
            }
            this.setState({
              warehouseList: warehouseList,
              isLoading: false,
            });
            if (callback) {
                callback(data)
            }
          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              isLoading: false,
            });
          });
      });
    });
  }

  callChangeStatusApi = (id, status, callback = null) => {
    const  accessToken = localStorage.getItem("access_token");

    this.setState({
      isLoading: true
    }, () => {
      let param = {
        order_status: status,
        order_reason: this.state.order_reason
      };

      wmsApi.update('orders/change_status/' + id, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                apiUtil.toast(error, 'error');
                callback();
              }
            });
          });
      });
    });
  }
  customCompleteDialog = () => {
    return <CustomDialog 
      open={this.state.completeDialog}
      title={'Complete'}
      onClose={() => {
        this.setState({
          completeDialog: false,
          openDialogItem: null,
          order_reason: null
        });
      }}
      onOk={() => {
        let row = this.state.openDialogItem;
        this.callChangeStatusApi(row, "Completed", (data) => {
          this.setState({
            completeDialog: false,
            openDialogItem: null,
            order_reason: null
          }, () => {
            this.getData();
            if(data) {
              apiUtil.toast('Successfully Completed', 'success');
            }
            
          });
        });
      }}
    >
      <Box>
        <Grid item xs={12}>
            <CustomInput
                label={'Reasons'}
                className="input-label--horizontal"
                value={this.state.order_reason}
                multiline={true}
                rows={3}
                onChange={e => {
                    this.setState({
                      order_reason: e.target.value
                    })
                }}
            />
        </Grid>

      </Box>
    </CustomDialog>
  }

  customVoidDialog = () => {
    return <CustomDialog 
      open={this.state.openVoidDialog}
      title={'Void'}
      onClose={() => {
        this.setState({
          openVoidDialog: false,
          openDialogItem: null,
          order_reason: null
        });
      }}
      onOk={() => {
        let row = this.state.openDialogItem;
        this.callChangeStatusApi(row, "Void", (data) => {
          this.setState({
            openVoidDialog: false,
            openDialogItem: null,
            order_reason: null
          }, () => {
            this.getData();
            if(data){
              apiUtil.toast('Successfully Void', 'success');
            }
            
          });
        });
      }}
    >
      <Box>
        <Grid item xs={12}>
            <CustomInput
                label={'Reasons'}
                className="input-label--horizontal"
                value={this.state.order_reason}
                multiline={true}
                rows={3}
                onChange={e => {
                    this.setState({
                      order_reason: e.target.value
                    })
                }}
            />
        </Grid>

      </Box>
    </CustomDialog>
  }

  removeDialog = () => {
    return <RemoveDialog
    modal={this.state.modal}
    onRemove={(isRemove) => {
      if (isRemove) {
        let data = this.state.data.filter(r => r.id !== this.state.modalItem);

        salesOrderApi.delete(this.state.modalItem).then(data => {
          if (data.data.status === true) {
            apiUtil.toast(data.data.message, 'success');
          } else {
            apiUtil.toast(data.data.message, 'warning');
          }
        }).catch(err => {
          apiUtil.toast(err.message, 'error');
        });

        this.setState({
          data: data
        });
      }

      this.setState({
        modal: false,
        modalItem: null
      });
    }}
    />
  }

  callDeleteApi = (id, callback = null) => {
    const  accessToken = localStorage.getItem("access_token");

    this.setState({
      isLoading: true
    }, () => {
      let param = {
      };

      wmsApi.delete('items/' + id, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              callback(error)
            });
          });
      });
    });
  }

  /*Bulkdelte DIALOG */
  customDialogBulkDelete = () => {
    return <CustomDialog 
      open={this.state.openBlukDeleteDialog}
      title={'Bulk Delete'}
      onClose={() => {
        this.setState({
          openBlukDeleteDialog: false,
          openDialogItem: null,
        });
      }}
      onOk={() => {
        let selectedRow = this.state.selectedRows;
        let ids = '';
        if(selectedRow.length > 0) {
          selectedRow.map((item, i) => { ids += item.id +','; });
        } else {
          apiUtil.toast('Select the items first', 'error');
          this.setState({
            openBlukDeleteDialog: false
          })
          return
        }
        ids = ids.slice(0, -1);
        this.callDeleteApi(ids, () => {
          this.setState({
            openBlukDeleteDialog: false,
            openDialogItem: null,
          }, () => {
            this.callReadApi();
            apiUtil.toast('Successfully Deleted', 'success');
          });
        });
      }}
    >
      <Box>Are you sure you want to delete selected items?</Box>
    </CustomDialog>
  }

  customDeleteDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Delete'}
      onClose={() => {
        this.setState({
          openDialog: false,
          openDialogItem: null,
        });
      }}
      onOk={() => {
        let row = this.state.openDialogItem;
        this.callDeleteApi(row, (err='') => {
          this.setState({
            openDialog: false,
            openDialogItem: null,
          }, () => {
            if (err ) {
              apiUtil.toast(err, 'error');
            } else {
              this.callReadApi();
              apiUtil.toast('Successfully Deleted', 'success');
            }
            
          });
        });
      }}
    >
      <Box>Are you sure you want to delete?</Box>
    </CustomDialog>
  }

  deleteData = id => {
    this.setState({
      openDialog: true,
      openDialogItem: id
    });
  }
  // deleteData = id => {
  //   this.setState({
  //     modal: true,
  //     modalItem: id
  //   });
  // }

  callImportApi = (row, callback = null) => {
    const  accessToken = localStorage.getItem("access_token");
    let param = {
      _json : row
    }

    this.setState({
      isLoading: true
    }, () => {
      wmsApi.create('create/sale_orders/multiple', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              apiUtil.toast(error, 'error');
            });
          });
      });
    });
  }

  importDialog = () => {
    return <ImportOrderDialog
      modal={this.state.importModal}
      onImport={(form) => {
        this.setState({
          importModal: false
        }, () => {
          this.callImportApi(form, (data) => {
            let all_jobs = (form && form.length > 0) ? form.length : 0;
            let failed_orders  = (data && data.failed_orders && data.failed_orders.length > 0) ? data.failed_orders.length : 0;
            let invalid_items = (data && data.invalid_items && data.invalid_items.length > 0) ? data.invalid_items.length : 0;
            let saved_orders  = (data && data.saved_orders  && data.saved_orders .length > 0) ? data.saved_orders .length : 0;
            
            if(failed_orders > 0){
              apiUtil.toast(failed_orders + ' of ' + all_jobs + ' orders have not successfully imported', 'error');
            }

            if(invalid_items > 0){
              apiUtil.toast(invalid_items + ' of ' + all_jobs + ' items does not match, orders have not successfully imported',  'error');
            }

            if(saved_orders > 0){
              apiUtil.toast(saved_orders + ' of ' + all_jobs + ' orders successfully imported', 'success');
            }

            this.getData();

            this.setState({
              importModal: false
            });
          });
        });
        console.log(form, "the excel data")
      }}
      onClose={() => {
        this.setState({
          importModal: false
        });
      }}
    />
  }

  /* TABLE */
  customTable = () => {
    return <Table 
      ref={this.refTable}
      className={'custom-table-list'}
      isLoading={this.state.isLoading}
      grouping={false}
      selection={true}
      page={this.state.page}
      total={this.state.total}
      limit={this.state.limit}
      onPageChange={(page,limit) => {
        this.setState({
          limit:limit,
          page:page
        }, () => {
          this.callReadApi();
        });
        }}
      data={this.state.data}
      onSelectionChange={(selectedRows) => {
        // console.log("selectedRows", selectedRows);
        this.setState({selectedRows})
      }}
      // Row={this.customRow}
      columns={[
        { 
            title: "Name ", 
            field: "name",
            cellStyle: {
              minWidth: 170,
              maxWidth: 170
            },
            render: (row, type) => {
              return (
                <div >
                  {/* {row.item_images.length > 0 ? <img src={row.item_images[0].image_url} width="30px" height="auto" style={{margin:"2px", marginRight: "5px"}}/> : <img src={defImg} width="30px" height="auto" style={{margin:"2px", marginRight: "5px"}}/>} */}
                  <a href={'/inbound-order/order-detail?id=' + row.id}>
                    {row.name}
                  </a>
                </div>
              );
            }
            
        },
      { 
          title: "SKU", 
          field: "sku",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
      },  
      { 
          title: "Stock On Hand", 
          field: "stock_on_hand",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
      },
      { 
        title: "Weight", 
        field: "weight",
        cellStyle: {
          minWidth: 150,
          maxWidth: 150
        },
      },
      { 
        title: "Committed Stock", 
        field: "committed_stock",
        cellStyle: {
          minWidth: 150,
          maxWidth: 150
        },
        render: (row, type) => {
          return (
            <div >
             {row.committed_stock ? row.committed_stock : 0}
            </div>
          );
        }
      },
      { 
        title: "Available For Sale", 
        field: "available_stock",
        cellStyle: {
          minWidth: 150,
          maxWidth: 150
        },
        render: (row, type) => {
          return (
            <div >
             {row.stock_on_hand - row.committed_stock}
            </div>
          );
        }
      },
      { 
        title: "Customer Name", 
        field: "client_name",
        render: (row, type) => {
              return (
                <div >
                 {row.customer ? row.customer.company_name : ''}
                </div>
              );
            }
      },
      { 
        title: "Warehouse", 
        field: "warehouse",
        cellStyle: {
          minWidth: 150,
          maxWidth: 150
        },
        render: (row, type) => {
          return (
            <div >
             {row.warehouse ? row.warehouse.name : ''}
            </div>
          );
        }
      },
      { 
          title: "Date", 
          field: "created_at",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          render: (row, type) => {
              if(type === 'row'){
                  return <Box>
                  {moment(row.created_at).format('DD-MMM-YYYY')}
                </Box>;
              } else {
                  return row;
              }
          }
      },
      {
        title: "Actions",
        field: "action",
        render: (row, type) => {
          return (
            <div className="btn-group">
              {this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Item Edit") && <a href={'/inbound-order/edit?id=' + row.id}>
                <EditIcon className="fix-icon-color" />
              </a> }
              {this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Item Delete") && <button type="button" onClick={() => this.deleteData(row.id)}>
                <DeleteIcon />
              </button>}
            </div>
          );
        }
      }
      ]}
    />
  }

  render() {
    

    return <Box className="dashboard-page">
      <StickyPageHeader>
      <Container>
        <Row className="d-flex align-items-center">
          <Col xs={12} lg={6}>
          <BreadCrumb />
          <h1 className="page-title">Inbound Order</h1>
          </Col>
          <Col xs={12} lg={6}>
          <div className='d-flex justify-content-end align-items-center'>
          <CustomDateRangePicker
            className={'select-dates-step mx-3'}
            range={this.state.rangeDate}
            onChange={(range) => {
              localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));

              this.setState({
                rangeDate: range,
                startDate: range.startDate,
                endDate: range.endDate
              }, () => {
                this.callReadApi()
              });
            }}
          />
        {this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Outbound Order Add") && <IconButton className="btn-theme btn-theme-1 py-2" label="Add New" icon="add" onClick={() => this.props.history.push('/inbound-order/create')} /> }
        <label class="dropdown me-3 py-2">
              <div class="dd-button-rr secondary">
              <MenuIcon width="26px" height="auto" />
              </div>

              <input type="checkbox" class="dd-input" id="test" />

              <ul class="dd-menu">
                  
                  {/* <li onClick={()=> this.setState({importModal:true})}><ImportIcon width="18px" height="auto" className="mx-2"/> Import Inbound</li> */}
                  {/* <li><a href="./import-sale-orders" style={{color: "#000"}}><ImportIcon width="18px" height="auto" className="mx-2"/> Import Sale Orders</a></li> */}
                  <li onClick={()=> this.callExportXLSApi()}><ExportIcon width="18px" height="auto" className="mx-2"/> Export CSV</li>
                  <li onClick={()=> this.callExportPDFApi()}><ExportIcon width="18px" height="auto" className="mx-2"/> Export PDF</li>
              </ul>
            </label>
            </div>
          </Col>
        </Row>
      </Container>
        
        <Row>
            <Col className="col-12 col-md-4 col-lg-4"></Col>
            <Col className="col-12 col-md-8 col-lg-8">
            
            </Col>
       </Row>
     
      </StickyPageHeader>

      <Card>
        <CardBody>
          <Row>
             <Col className="col-12 px-4 d-flex" >
               <div className="my-2 w-50">
               <Label >
                  Company Name
                </Label>
               </div>
               <div className="my-2 w-50 ms-3">
                  <Label >
                    Warehouse
                  </Label>
               </div>
            </Col>
            <Col className="col-12 px-4 d-flex">
              <div className="my-2 w-50 ">
                <Select  className="option-select" value={this.state.selectedCustomer} options={this.state.customerList} onChange={(e) => this.handleCustomerChange(e)} />
              </div>
              <div className="my-2 w-50 ms-3">
                <Select  className="option-select" value={{label: this.state.selectedWarehouse.name, value: this.state.selectedWarehouse.id}} options={this.state.warehouseList} onChange={(e) => this.handleWarehouseChange(e)} />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      
      <Card className="pt-0">
        <CardBody>
          <Row>
            <Col md="12">
              {/* <MUIDataTable
                className="sale-order-list"
                title={
                  <Typography variant="h6">
                    Inbound Order
                    {this.state.isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />}
                  </Typography>}
                data={this.state.data}
                columns={columns}
                options={options}
              /> */}
              {this.customTable()}
            </Col>
          </Row>
        </CardBody>
      </Card>
      {/* {this.removeDialog()} */}
      {this.customCompleteDialog()}
      {this.customVoidDialog()}
      {this.importDialog()}
      {this.customDeleteDialog()}
    </Box>;
  }
}

export default InboundOrderList;