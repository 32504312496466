
import React, { Component } from 'react';
// import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import { 
  Box,
  Grid,
  Container,
  InputLabel,
  Select,
  Divider,
  CircularProgress
} from '@material-ui/core';
import axios from 'axios';
import ldsApi from "../../api/ldsApi.jsx";
import apiUtil from "../../api/apiUtil.js";
import IconButton from "../../components/Button/IconButton";
import Icon from '@material-ui/core/Icon';
import CustomGoogleMapSearch from '../../components/GoogleMap/CustomGoogleMapSearch.jsx';
import CustomInput from '../../components/CustomFields/CustomInput';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import CustomLabel from '../../components/CustomFields/CustomLabel';
import CustomButton from '../../components/CustomFields/CustomButton';
import Card from '../../components/Card/Card.jsx';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import moment from 'moment';
import Table from '../../components/Table/Table.jsx';
import queryString from 'query-string';


class AddWareHouse extends Component {

  constructor(props){
    super(props);

    this.state = {
      jobTab: 0,
      jobTabTable: 0,

      dateRangePickerIsOpen: false,
      rangeDate: {
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
      },

      isLoadingStatus: false,
      status: [],
      tableStatus: [],
      statusSelected: null,
      order_status: null,
      isLoading: false,
      isLoadingParent: false,
      isStatLoading: false,
    //   grouping: true,
      // limit: apiUtil.getDefaultPageSize(),
      updateId: '',
      name: '',
      nameValidated: false,
      location:'',
      latitude: '',
      longitude:'',
      locationValidated: false,
      address:'',
      addressValidated: false,
      contactNumber: '',
      contactNumberValidated: false,
      postalCode: 0,
      postalCodeValidated: false,
      parentId:'',
      parent_name: [], 
      limit: 10,
      data: [],
      total: 0,
      page: 1,
      total_delivery: 0,
      openDialog: false,
      openDialogItem: null,

      openPreviewDialog: false,
      openPreviewDialogItem: null,

      stats : [],
      recent_activity : [],
      delivery_perday : []
    }
  }

  getWareHouse = (updateId) => {
    const {
      REACT_APP_WMS_API_URL
    } = process.env;

    this.setState({
        isLoading: true
      });
      const PATH = 'admin/api/';
      const api = "warehouses";
      let URL = REACT_APP_WMS_API_URL + PATH + api + '/' + updateId;
      let token = window.localStorage.getItem('access_token');

    let param = {

    };
    axios.get(
        URL,
        {
            params: param,
            data: param,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .catch(error => {
            try {
                
            } catch (err) {

            }
        }).then((response) => {
            if (response != null) {
                this.setState({
                  name:response.data.result.name,
                  location:response.data.result.location,
                  address:response.data.result.address,
                  postalCode:response.data.result.postal_code,
                  latitude:response.data.result.latitude,
                  longitude:response.data.result.longitude,
                  contactNumber:response.data.result.contact_number,
                  parentId:response.data.result.parent_warehouses_id,
                  isLoading: false,
              });
            }

        });
}

getParentWareHouse = () => {
    const {
      REACT_APP_WMS_API_URL
    } = process.env;

    this.setState({
        isLoadingParent: true
      });
      const PATH = 'admin/api/';
      const api = "warehouses";
      let URL = REACT_APP_WMS_API_URL + PATH + api;
      let token = window.localStorage.getItem('access_token');

    let param = {
        parent_warehouses:'all'
    };
    axios.get(
        URL,
        {
            params: param,
            data: param,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .catch(error => {
            try {
                this.setState({
                    isLoadingParent: false,
                });
            } catch (err) {
                this.setState({
                    isLoadingParent: false,
                });
            }
        }).then((response) => {
            if (response != null) {
                this.setState({
                  parent_name:response.data.result,
                  isLoadingParent: false,

              });
             
            }

        });
}

    async componentDidMount () {
    let urlQuery = queryString.parse(window.location.search);

    await  this.getParentWareHouse();
    if (urlQuery.id !== undefined) {
      this.setState({
        updateId: urlQuery.id
      });
    await  this.getWareHouse(urlQuery.id);
    } 
}

  handleSubmit = (e) => {
    e.preventDefault();
    // e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;
    let errorMessage = "";
    let status = 0;

    this.setState({
        nameValidated: false,
        skuValidated: false,
        warehouseValidated: false,
        locationValidated: false,
        postalCodeValidated: false,
        inbound_dateValidated: false,
    });

    let contactNumber = this.state.contactNumber;
    let postal_code = this.state.postalCode != '' ||  this.state.postalCode != null ? this.state.postalCode : 0;
    let phoneRegEx = /^[+]?\d+$/;

    if(this.state.name == '' || this.state.location == '' || postal_code == 0 || this.state.postalCode.length < 6 || !phoneRegEx.test(contactNumber) || contactNumber === '' || contactNumber.length < 8 || contactNumber.length > 13) {
    if(this.state.name === ''){
      apiUtil.toast('name can\'t be blank', 'error');
      this.setState({
        nameValidated: true
      });
    }

    if(this.state.location === ''){
      apiUtil.toast('Location can\'t be blank', 'error');
      this.setState({
        locationValidated: true
      });
    }
    if(this.state.postalCode === '' || this.state.postalCode === 0 || this.state.postalCode.length < 6 ){

      if(this.state.postalCode.length < 6) {
        apiUtil.toast('postal code must be 6 digit', 'error');
      }
     
        this.setState({
          postalCodeValidated: true
        });
      }

    if(!phoneRegEx.test(contactNumber) || contactNumber === '' || contactNumber.length < 8 || contactNumber.length > 13 ){
      apiUtil.toast('contact number is invalid', 'error');
      this.setState({
        contactNumberValidated: true,
        isLoading: false,
      });
      return
    } 
      this.setState({
        isLoading: false
    });
    } else {
      // alert('else')
      contactNumber = this.state.contactNumber.length === 8 ? '+65' +this.state.contactNumber : this.state.contactNumber ;
     
      let data = {
          name: this.state.name,
          location: this.state.location,
          address: this.state.address,
          postal_code: this.state.postalCode,
          contact_number: contactNumber,
          latitude: this.state.latitude,
          longitude: this.state.longitude,
          parent_warehouses_id: this.state.parentId,
        }
        
      if(this.state.updateId && this.state.updateId !== ""){
          const {
              REACT_APP_WMS_API_URL
            } = process.env;
          
            this.setState({
              isLoading: true,
            });

            const PATH = 'admin/api/';
            const api = "warehouses";
            const URL = REACT_APP_WMS_API_URL + PATH + api + '/' + this.state.updateId;
            let token = window.localStorage.getItem('access_token');
        
              axios.put(
                    URL,
                    data,
                    { 
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    })
                    .catch( error => {
                        try {
                            status = error.response.status;
                            apiUtil.toast(error, 'error');
                              if (error.response) {
                                // Request made and server responded
                                errorMessage = error.response.data.error;
                              } else if (error.request) {
                                // The request was made but no response was received
                                errorMessage = error.request;
                              } else {
                                // Something happened in setting up the request that triggered an Error
                                errorMessage = error.message;
                              }
                        } catch(err) {
                          apiUtil.toast(err, 'error');
                            errorMessage = 'Service Unavailable!'
                        }
                        this.setState({
                          isLoading: false,
                        });
                        return
                    }).then((response) => {
                      this.setState({
                        isLoading: false,
                      });
                      if(response.data.status == true) {
                        apiUtil.toast('Successfully Updated', 'success');
                        this.props.history.push({
                          pathname: '/warehouses',
                      })
                      }
                     
                    });
        } else {
          const {
              REACT_APP_WMS_API_URL
            } = process.env;
              
            const PATH = 'admin/api/';
            const api = "warehouses";
            const URL = REACT_APP_WMS_API_URL + PATH + api;
            let token = window.localStorage.getItem('access_token');
           
              axios.post(
                    URL,
                    data,
                    { 
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    })
                    .catch( error => {
                        try {
                            status = error.response.status;
                            apiUtil.toast(error.response.data.error, 'error');
                            this.props.history.push({
                              pathname: '/warehouses',
                            })
                              if (error.response) {
                                // Request made and server responded
                                // apiUtil.toast(error, 'error');
                                errorMessage = error.response.data.error;
                              } else if (error.request) {
                                // The request was made but no response was received
                                errorMessage = error.request;
                              } else {
                                // Something happened in setting up the request that triggered an Error
                                errorMessage = error.message;
                              }
                              return
                        } catch(err) {
                            errorMessage = 'Service Unavailable!'
                            apiUtil.toast(err, 'error');
                        }
                        this.setState({
                          isLoading: false,
                        });
                        return
                    }).then((response) => {
                      this.setState({
                        isLoading: false,
                        warehouseDialog: false,
                      });
                      
                      if(response.data.status == true) {
                        this.props.history.push({
                          pathname: '/warehouses',
                      })
                      apiUtil.toast('Successfully Created', 'success');
                      }
                    });
        }
    }
  }

    // ON CHANGE JOBSETP WHEN DELIVERY ADDRESS CHANGED
    deliveryAddressOnChangeHandler = (location) => {
      this.callGeoCoderAddressApi(location, (data) => {
          if(data){
            this.setState({
              latitude: data.lat,
              longitude: data.lng
            })
          }
      });
    }

      /* GEO FUNCTIONS API */
  callGeoCoderAddressApi = (address = '', callback = null) => {
    const accessToken  =  window.localStorage.getItem('access_token')

    this.setState({
      geocoderAddressIsLoading: true,
    }, () => {
      let params = {
        address: address,
      };

      ldsApi.create('geo_functions/geocoder_address', params, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              if(callback){
                callback(data.result);
                this.setState({
                  geocoderAddressIsLoading: false,
                })
              }
            } else {
              if(callback){
                callback(null);
                this.setState({
                  geocoderAddressIsLoading: false,
                })
              }
            }
          }, (error, type) => {
            if(callback){
              callback(null);
              this.setState({
                geocoderAddressIsLoading: false,
              })
            }
          });
      });
    });
  }


  AddWareHouse = () => {
      return <Box className={'job-details-step'} >
          <Grid container>
              <Grid item xs={12} p={4}>
                  <Grid item xs={12}>
                      <Container maxWidth={'xs'}>
                          <Box clone pt={5}>
                              <CustomInput
                                  label={'Name'}
                                  placeholder={'Enter Name here...'}
                                  value={this.state.name}
                                  error={this.state.nameValidated}
                                  required={true}
                                  onChange={(e) => {
                                      this.setState({
                                          name: e.target.value,
                                          nameValidated: (e.target.value !== '') ? false : true,
                                      });
                                  }}
                              />
                          </Box>
                      </Container>
                  </Grid>
                      <Grid item xs={12}>
                      <Container maxWidth={'xs'}>
                          <Box clone pt={5}>
                        <CustomGoogleMapSearch
                                label={'Location'}
                                placeholder={'Enter location here...'}

                                value={this.state.location}
                                error={this.state.locationValidated}
                                required={true}
                                onChange={(e) => {  
                                  this.deliveryAddressOnChangeHandler(e.target.value)  
                                    this.setState({
                                      location: e.target.value,
                                      locationValidated: (e.target.value != '' && e.target.value != null) ? false : true,
                                    });
                                }}
                                onPlacesChanged={(place, e) => {
                                  // this.deliveryAddressOnChangeHandler(place)
                                    let address = apiUtil.getGmapAddress(place);
                                    this.setState({
                                      // job_steps: [],
                                      location: address,
                                      latitude: place.geometry.location.lat(),
                                      longitude: place.geometry.location.lng(),
                                      locationValidated: (address !== '') ? false : true,
                                    });
                                    // this.deliveryAddressOnChangeHandler(address)
                                }}
                              />
                               </Box>
                      </Container>
                          </Grid>
                  {/* <Grid item xs={12}>
                      <Container maxWidth={'xs'}>
                          <Box clone pt={2}>
                              <CustomInput
                                  label={'Location'}
                                  placeholder={'Enter location here...'}
                                  value={this.state.location}
                                  error={this.state.locationValidated}
                                  required={true}
                                  onChange={(e) => {
                                      this.setState({
                                          location: e.target.value,
                                          locationValidated: (e.target.value !== '') ? false : true,
                                      });
                                  }}
                              /> */}
                          {/* <Grid item xs={12}>
                          <Container maxWidth={'xs'}>
                              <Box clone pt={2}>
                              <CustomGoogleMapSearch
                                  label={'Location'}
                                  placeholder={'Enter location here...'}
                                  value={this.state.location}
                                  error={this.state.locationValidated}
                                  required={true}
                                  onChange={(e) => {                                        
                                      this.setState({
                                          location: e.target.value,
                                          locationValidated: (e.target.value != '' && e.target.value != null) ? false : true,
                                      });
                                  }}
                                  onPlacesChanged={(place, e) => {
                                    // this.deliveryAddressOnChangeHandler(place)
                                    let address = apiUtil.getGmapAddress(place);
                                      this.setState({
                                        // job_steps: [],
                                        location: address,
                                        // latitude: place.geometry.location.lat(),
                                        // longitude: place.geometry.location.lng(),
                                        locationValidated: (address !== '') ? false : true,
                                      });
                                  }}
                                />
                          </Box>
                      </Container>
                          </Grid>  */}
                  <Grid item xs={12}>
                      <Container maxWidth={'xs'}>
                          <Box clone pt={2}>
                              <CustomInput
                                  label={'Postal Code'}
                                  placeholder={'Enter postal code here...'}
                                  value={this.state.postalCode}
                                  error={this.state.postalCodeValidated}
                                  type={'number'}
                                   onInput = {(e) =>{
                                              e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,6)
                                          }}
                                  onChange={(e) => {
                                      this.setState({
                                        postalCode: e.target.value,
                                      });
                                  }}
                              />
                          </Box>
                      </Container>
                  </Grid>
                  <Grid item xs={12}>
                      <Container maxWidth={'xs'}>
                          <Box clone pt={2}>
                              <CustomInput
                                  label={'Address'}
                                  placeholder={'Enter address here...'}
                                  error={this.state.addressValidated}
                                  value={this.state.address}
                                  onChange={(e) => {
                                      this.setState({
                                          address: e.target.value,
                                          addressValidated: (e.target.value !== '') ? false : true,
                                      });
                                  }}
                              />
                          </Box>
                      </Container>
                  </Grid>
                  <Grid item xs={12}>
                      <Container maxWidth={'xs'}>
                          <Box clone pt={2}>
                              <CustomInput
                                  label={'Contact Number'}
                                  placeholder={'Enter Contact Number here...'}
                                  error={this.state.contactNumberValidated}
                                  value={this.state.contactNumber}
                                  onChange={(e) => {
                                      this.setState({
                                          contactNumber: e.target.value,
                                      });
                                  }}
                              />
                          </Box>
                      </Container>
                  </Grid>
                  <Grid item xs={12}>
                  <Container maxWidth={'xs'}>
                          <Box pt={2}>
                                <Box className="custom-label-component">
                                  <InputLabel >Parent Warehouse</InputLabel>
                                </Box>
                                <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
                                  placeholder={'Enter job type here...'}
                                  value={this.state.parentId}
                                  native
                                  required={false}
                                  disableUnderline
                                  onChange={(e) => {
                                    this.setState({
                                        parentId: e.target.value,
                                        isFormChanged: true,
                                    });
                                }}
                                >
                                  <option value=""> Select parent Warehouse </option>
                                  {this.state.parent_name && this.state.parent_name.map((res) => {
                                      return <option value={res.id}>{res.name}</option>
                                  })}
                                </Select>   
                                 </Box>
                      </Container>    
                  </Grid>
                  <Grid item xs={12}>
                      <Container maxWidth={'xs'}>
                          <Box clone pt={2} mb={5}>
                              <CustomButton
                                  type={'submit'}
                                  color={'secondary'}
                                  isLoading={this.state.isLoading || this.state.geocoderAddressIsLoading}
                              ><Box pl={1} pr={1}>Submit</Box>
                              </CustomButton>
                          </Box>
                      </Container>
                  </Grid>
              </Grid>
          </Grid>
      </Box>
 }

  render() {
    const {stats, delivery_perday, total_delivery} = this.state
    return <Box>
      <StickyPageHeader>
        <Container>
          <BreadCrumb />
          <h1 className="page-title">{this.state.updateId != '' ? 'Edit Warehouse' : 'Add New Warehouse' }</h1>
          </Container>
      </StickyPageHeader>
      <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
      {
                  (!this.state.isLoading)
                  ?
        <Card className={'job-listing-step'}>
            {this.AddWareHouse()}
        </Card>
        :
        <Card>
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
            <CircularProgress size={35} />
          </Box>
        </Card>
      }
     </form>
    </Box>;
  }
}

export default AddWareHouse;