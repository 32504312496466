import React, { Component } from "react";

import { 
 Grid,
 Box,
 Tab,
 Tabs,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';


import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import { Row, Container, Card, CardBody, Col } from "reactstrap";

import apiUtil from "../../api/apiUtil";
import wmsApi from "../../api/wmsApi";
import DropdownMenu from "../../components/Dropdown/DropdownMenu";
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import Table from '../../components/Table/Table.jsx';

import moment from "moment";
import defImg from '../../assets/img/default_item.jpg';

class SaleReturnList extends Component {
    constructor(porps) {
        super(porps)
        this.state = {
            isLoading: false,
            page: 0,
            limit: 20,
            total: 0,
            data: [],
            state: 1,
            openDialog: false,
            openDialogItem: null,
            dataPending: [],
            totalPending: '',
            tempReturn: [{ 
              id : 1,
              date : '12-02-2022',
              sale_order : '0Q-A122',
              customer_name : 'cus',
              rma : 'RMA-0023',
              status : 'approved',
              receive_status : 'received',
              refund_status : ' ',
              returnd : ' ',
              amount_ref : 'SGD 12.33'
            },
            { 
              id : 2,
              date : '12-02-2022',
              sale_order : '0Q-A132',
              customer_name : 'cus 2',
              rma : 'RMA-0020',
              status : 'approved',
              receive_status : 'received',
              refund_status : ' ',
              returnd : ' ',
              amount_ref : 'SGD 12.33'
            }
        ]
        }
    }

    componentDidMount = () => {
        this.callReadApi()
        this.callReadApiPending()
    }

    
    callReadApi = (search = '') => {
        const  accessToken = localStorage.getItem("access_token");

        this.setState({
          isLoading: true
        }, () => {
          let param = {
            page: this.state.page,
            take: this.state.limit,
            sort: "latest",
            is_received: "true",
            search: search,
          };
    
          wmsApi.read('order_items', param, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                this.setState({
                  data: (data.result && data.result.length > 0) ? data.result : [],
                  total: data.total,
                  isLoading: false,
                });
              }, (error, type) => {
                this.setState({
                  data: [],
                  total: 0,
                  isLoading: false,
                });
              });
          });
        });
      }

      callReadApiPending = (search = '') => {
        const  accessToken = localStorage.getItem("access_token");

        this.setState({
          isLoading: true
        }, () => {
          let param = {
            page: this.state.page,
            take: this.state.limit,
            sort: "latest",
            is_received: "false",
            search: search,
          };
    
          wmsApi.read('order_items', param, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                this.setState({
                  dataPending: (data.result && data.result.length > 0) ? data.result : [],
                  totalPending: data.total,
                  isLoading: false,
                });
              }, (error, type) => {
                this.setState({
                  dataPending: [],
                  totalPending: 0,
                  isLoading: false,
                });
              });
          });
        });
      }


      callChangeStatusApi = (id, callback = null) => {
        const  accessToken = localStorage.getItem("access_token");
    
        this.setState({
          isLoading: true
        }, () => {
          let param = {
            is_received: true
          };
    
          wmsApi.update('order_items/' + id, param, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                this.setState({
                  isLoading: false,
                }, () => {
                  if(callback){
                    callback(data);
                  }
                });
              }, (error, type) => {
                this.setState({
                  isLoading: false,
                }, () => {
                  if(callback){
                    apiUtil.toast(error, 'error');
                    callback();
                  }
                });
              });
          });
        });
      }

      customReceivedDialog = () => {
        return <CustomDialog 
          open={this.state.openReceivedDialog}
          title={'Received'}
          onClose={() => {
            this.setState({
              openReceivedDialog: false,
              openDialogItem: null
            });
          }}
          onOk={() => {
            let row = this.state.openDialogItem;
            this.callChangeStatusApi(row,  (data) => {
              this.setState({
                openReceivedDialog: false,
                openDialogItem: null
              }, () => {
                this.callReadApi()
                this.callReadApiPending()
                if(data) {
                  apiUtil.toast('Successfully Received', 'success');
                }
                
              });
            });
          }}
        >
          <Box> Are your sure want to receive?</Box>
        </CustomDialog>
      }

    /* DIALOG */
    // customDialog = () => {
    //   return <CustomDialog 
    //     open={this.state.openDialog}
    //     title={'Delete'}
    //     onClose={() => {
    //       this.setState({
    //         openDialog: false,
    //         openDialogItem: null,
    //       });
    //     }}
    //     onOk={() => {
    //       let row = this.state.openDialogItem;
    //       this.callDeleteApi(row, () => {
    //         this.setState({
    //           openDialog: false,
    //           openDialogItem: null,
    //         }, () => {
    //           this.callReadApi();
    //           apiUtil.toast('Successfully Deleted', 'success');
    //         });
    //       });
    //     }}
    //   >
    //     <Box>Are you sure you want to delete?</Box>
    //   </CustomDialog>
    // }

    // deleteData = id => {
    //   this.setState({
    //     openDialog: true,
    //     openDialogItem: id
    //   });
    // }

       /* TABLE */
  customTable = () => {
    return <Table 
      ref={this.refTable}
      className={'custom-table-list custom-table-sale-return'}
      isLoading={this.state.isLoading}
      grouping={false}
      selection={false}
      page={this.state.page}
      total={this.state.total}
      limit={this.state.limit}
      onPageChange={(page,limit) => {
        this.setState({
          limit:limit,
          page:page
        }, () => {
          this.callReadApi();
        });
        }}
      data={this.state.data}
      onSelectionChange={(selectedRows) => {
        // console.log("selectedRows", selectedRows);
      }}
      // Row={this.customRow}
      columns={[
        
        { 
            title: "Date ", 
            field: "date",
            cellStyle: {
              minWidth: 200,
              maxWidth: 200
            },
            render: (row, type) => {
              return (
                <div >
                    {moment(row.updated_at).format('DD-MMM-YYYY')}
                </div>
              );
            }
            
        },
        // { 
        //     title: "RMA#", 
        //     field: "rma",
        //     render: (row, type) => {
        //     return <div>-</div>
        //     }
        // },  
        { 
            title: "Outbound Order", 
            field: "sale_order",
            cellStyle: {
              minWidth: 200,
              maxWidth: 200
            },
            render: (row, type) => {
              return <div> 
                    <a href={'/sales-order/order-detail?id=' + row.order_id}>
                      {row.order.reference_no}
                    </a>
                  </div>
               }
        },
        // { 
        //   title: "Customer Name", 
        //   field: "sale_order",
        //   render: (row, type) => {
        //     return <div>-</div>
        //      }
        // },
        { 
          title: "Status", 
          field: "status",
          render: (row, type) => {
                return <div className={row.is_received ? "text-success" : "text-primary" }> {row.is_received ? "Approved": "Not Approved"}</div>
             }
        },
        { 
            title: "Receive Status", 
            field: "receive_status",
            render: (row, type) => {
              return <div className={row.is_received ? "text-success" : "text-primary" }> {row.is_received ? "Received": "Pending"}</div>
           }
        },
      //   { 
      //     title: "Refund Status", 
      //     field: "refund_status",
      //     render: (row, type) => {
      //       return <div>-</div>
      //        }
      // },
      { 
        title: "Returned", 
        field: "returned",
        render: (row, type) => {
          return <div> {row.item_qty}</div>
           }
    },
    { 
      title: "Amount Ref", 
      field: "amount_ref",
      render: (row, type) => {
        return <div>{row.order.total_balance}</div>
         }
    }
    // ,
    // { 
    //   title: "Action", 
    //   field: "action",
    //   cellStyle: {
    //     minWidth: 200,
    //     maxWidth: 200
    //   },
    //   render: (row, type) => {
    //     return <Box>
    //       { row && row.is_received == true ? '' :
    //     <DropdownMenu
    //           text={'Actions'}
    //           className={'status-btn ' }
    //           rightIcon={<ArrowDownIcon />}
    //           color={"primary"}
    //           items={[{text: "Received" }]}
    //           onClick={(item) => {
                
    //               if (item.text == "Received") {
    //                 this.setState({
    //                   openReceivedDialog: true,
    //                   openDialogItem: row.id
    //               })
    //               }
              
    //           }}
    //         />
    //         }
    //       </Box>
    //      }
    // }
      ]}
    />
  }

  customTablePending = () => {
    return <Table 
      ref={this.refTable}
      className={'custom-table-list custom-table-sale-return'}
      isLoading={this.state.isLoading}
      grouping={false}
      selection={false}
      page={this.state.page}
      total={this.state.totalPending}
      limit={this.state.limit}
      onPageChange={(page,limit) => {
        this.setState({
          limit:limit,
          page:page
        }, () => {
          this.callReadApi();
        });
        }}
      data={this.state.dataPending}
      onSelectionChange={(selectedRows) => {
        // console.log("selectedRows", selectedRows);
      }}
      // Row={this.customRow}
      columns={[
        
        { 
            title: "Date ", 
            field: "date",
            cellStyle: {
              minWidth: 200,
              maxWidth: 200
            },
            render: (row, type) => {
              return (
                <div >
                    {moment(row.updated_at).format('DD-MMM-YYYY')}
                </div>
              );
            }
            
        },
        // { 
        //     title: "RMA#", 
        //     field: "rma",
        //     render: (row, type) => {
        //     return <div>-</div>
        //     }
        // },  
        { 
            title: "Outbound Order", 
            field: "sale_order",
            cellStyle: {
              minWidth: 200,
              maxWidth: 200
            },
            render: (row, type) => {
              return <div> 
                    <a href={'/sales-order/order-detail?id=' + row.order_id}>
                      {row.order.reference_no}
                    </a>
                  </div>
               }
        },
        // { 
        //   title: "Customer Name", 
        //   field: "sale_order",
        //   render: (row, type) => {
        //     return <div>-</div>
        //      }
        // },
        { 
          title: "Status", 
          field: "status",
          render: (row, type) => {
                return <div className={row.is_received ? "text-success" : "text-primary" }> {row.is_received ? "Approved": "Not Approved"}</div>
             }
        },
        { 
            title: "Receive Status", 
            field: "receive_status",
            render: (row, type) => {
              return <div className={row.is_received ? "text-success" : "text-primary" }> {row.is_received ? "Received": "Pending"}</div>
           }
        },
      //   { 
      //     title: "Refund Status", 
      //     field: "refund_status",
      //     render: (row, type) => {
      //       return <div>-</div>
      //        }
      // },
      { 
        title: "Returned", 
        field: "returned",
        render: (row, type) => {
          return <div> {row.item_qty}</div>
           }
    },
    { 
      title: "Amount Ref", 
      field: "amount_ref",
      render: (row, type) => {
        return <div>{row.order.total_balance}</div>
         }
    }
    ,
    { 
      title: "Actions", 
      field: "action",
      cellStyle: {
        minWidth: 200,
        maxWidth: 200
      },
      render: (row, type) => {
        return <Box>
          { row && row.is_received == true ? '' :
        <DropdownMenu
              text={'Actions'}
              className={'status-btn ' }
              rightIcon={<ArrowDownIcon />}
              color={"primary"}
              items={[{text: "Received" }]}
              onClick={(item) => {
                
                  if (item.text == "Received") {
                    this.setState({
                      openReceivedDialog: true,
                      openDialogItem: row.id
                  })
                  }
              
              }}
            />
            }
          </Box>
         }
    }
      ]}
    />
  }

  ExportCSV = () => {
    return ;
  }

    render () {
        return  <Box className="dashboard-page">
        <StickyPageHeader>
          <Container>
          <BreadCrumb />
          <h1 className="page-title">Outbound Return</h1>
          <div className="d-flex justify-content-end align-items-center pt-5 me-5">
          <div className={this.state.state ==1 ? "tab-style-sale px-4 active-tab pb-2" : "tab-style-sale px-4 pb-2"} onClick={() => this.setState({state: 1})}>Pending</div>
          <div className={this.state.state ==2 ? "tab-style-sale px-4 active-tab pb-2" : "tab-style-sale pb-2 px-4"} onClick={() => this.setState({state: 2})}>Received</div>
          </div>
          </Container>
        </StickyPageHeader>
        <Card>
        <CardBody>
        <Row>
            <Col md="12">
              {this.state.state == 1? 
              this.customTablePending():
              this.customTable() 
              }
            </Col>
          </Row>
              
        </CardBody>
        </Card>
        {this.customReceivedDialog()}
      </Box>
    }
}

export default SaleReturnList;