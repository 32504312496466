import axios from 'axios';

const { 
  REACT_APP_WMS_API_URL
} = process.env;

const reportApi = {
  getReport: async (request, type) => {
    let errorMessage = "";
    let params = "customer_id=" + request.customer_id;

    if (request.start_date !== "") {
      params += "&start_date=" + request.start_date;
    }

    if (request.end_date !== "") {
      params += "&end_date=" + request.end_date;
    }

    let URL = "";

    if (type === 'handling_fee') {
      URL = REACT_APP_WMS_API_URL + "admin/api/report/handling_fee?" + params;
    } else {
      URL = REACT_APP_WMS_API_URL + "admin/api/report/rental_fee?" + params;
    }

    let access_token = localStorage.getItem("access_token");

    const result = await axios.get(
      URL,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
    .catch( error => {
      errorMessage = error.message;
    });

    return { data: result, errorMessage: errorMessage };
  },

  getAging: async (request) => {
    let errorMessage = "";
    let params = "customer_id=" + request.customer_id;

    if (request.expiry !== "") {
      params += "&expiry=" + request.expiry;
    }

    const URL = REACT_APP_WMS_API_URL + "admin/api/report/handling_expiry?" + params;

    let access_token = localStorage.getItem("access_token");

    const result = await axios.get(
      URL,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
    .catch( error => {
      errorMessage = error.message;
    });

    return { data: result, errorMessage: errorMessage };
  },

  getMonthly: async (request) => {
    let errorMessage = "";
    let params = "start_date=" + request.start_date + "&end_date=" + request.end_date;

    const URL = REACT_APP_WMS_API_URL + "admin/api/report/monthly_summary?" + params;

    let access_token = localStorage.getItem("access_token");

    const result = await axios.get(
      URL,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
    .catch( error => {
      errorMessage = error.message;
    });

    return { data: result, errorMessage: errorMessage };
  }
}

export default reportApi;