import React, { Component } from 'react';

import { 
    Box,
    CircularProgress,
    Grid,
    Card,
    Container,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';
import { ReactComponent as BulbIcon } from '../../assets/img/icons/bulb.svg';
import { ReactComponent as BackIcon } from '../../assets/img/icons/back.svg';
import Table from '../../components/Table/Table.jsx';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import IconButton from "../../components/Button/IconButton";
import apiUtil from "../../api/apiUtil";
import wmsApi from "../../api/wmsApi";
import queryString from 'query-string';
import moment from 'moment'
import { Form, Button, Accordion } from 'react-bootstrap';
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={2}>
            {children}
          </Box>
        )}
      </div>
    );
}
const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`
    };
}

class ImportItem extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: '',
            isLoading: false,
            tabValue: 0,
            file: '',
            pgState: 1,
            skipCheck: true,
            name: '',
            brand: '',
            sku : '',
            type : '',
            weight: '',
            dimensions : '',
            inbound_date : '',
            cost_prices : '',
            selling_prices : '',
            opening_stock: 0,
            status : '',
            warehouse : '',
            overWriteCheck: false,
            tempItemOrder: [{ 
                id : 1,
                date : '12-02-2022',
                sale_order : '0Q-A122',
                customer_name : 'cus',
                quantity : '1.00',
                price : 'SGD1.00',
                total : 'SGD2.00',
                status : 'void'
            }]
        }
    }
    
    componentDidMount() {
        let urlQuery = queryString.parse(window.location.search);
        if (urlQuery.id !== undefined) {
            this.setState({
              dataId: urlQuery.id
            });
            this.callDetailsApi(urlQuery.id, (data) => {
                if(data) {
                    this.setState({
                        data
                    })
                }
            })
        }
    }

    callDetailsApi = (id = '', callback = null) => {
        const  accessToken = localStorage.getItem("access_token");

        this.setState({
            isLoading: true
        }, () => {
            wmsApi.read('items/' + id, null, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if(callback){
                    callback((data && data.result) ? data.result : null);
                    }
                });
                }, (error, type) => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if(callback){
                    callback(null, []);
                    }
                });
                });
            });
        });
    }

    handleChange = (event, newValue) => {
        this.setState({
            tabValue: newValue
        })
    }

    itemDetailPage = () => {
        return <>
        <Box mt={5}>
            <Grid container xs={12} className="card-style">
              <Grid item xs={8}>
                    <Typography>Download a <a href="#">sample file</a> and compare it to your import file to ensure you have the file perfect for the import.</Typography>
                    <Grid container xs={12} className="mt-5">
                        <Grid item xs={4} className="text-danger">Upload file*</Grid>
                        <Grid item xs={8}>
                            <input type="file" name="file" value={this.state.file} 
                            onChange={(e) => this.setState({file:e.target.value}) }/>
                            <Typography className="my-4 fs-6 text-muted">Maximum File Size: 5 MB  |  File Format: CSV or TSV or XLS</Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="my-4">
                        <Grid item xs={4} className="text-danger">Duplicate Handling:*</Grid>
                        <Grid item xs={8}>
                            <section className="mb-4">
                                <div className='d-flex pb-2 align-items-center'>
                                    <Form.Check
                                        type='radio'
                                        id='item'
                                        name='item'
                                        value={this.state.skipCheck}
                                        onChange={() => this.setState({skipCheck : !this.state.skipCheck})}
                                    />
                                    <Typography className="fs-6 fw-bold px-2">Skip</Typography>
                                </div>
                                <Typography className="text-muted">Retains the records in Zoho Inventory and does not sync the identical records from the import file.</Typography>
                            </section>
                            <section className="mb-4">
                                <div className='d-flex pb-2 align-items-center'>
                                    <Form.Check
                                        type='radio'
                                        name='item'
                                        id='item'
                                        value={this.state.overWriteCheck}
                                        onChange={() => this.setState({overWriteCheck : !this.state.overWriteCheck})}
                                    />
                                    <Typography className="fs-6 fw-bold px-2">Overwrite</Typography>
                                </div>
                                <Typography className="text-muted">Overwrites existing records in Zoho Inventory with the identical records in the import file.</Typography>
                            </section>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}>
                        <Grid item xs={4} className="text-danger">Character Encoding*</Grid>
                        <Grid item xs={8} >
                            <Form.Select aria-label="Default select example" className="w-50">
                                <option>select menu</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="my-5">
                        <Button variant="primary" disabled={this.state.file == ''? true : false}
                        onClick={() => this.setState({pgState: this.state.pgState + 1})}
                        >Next</Button>
                        <Button variant="outline-secondary" className='mx-4'>Cancel</Button>
                    </Grid>
              </Grid>
              <Grid item xs={4}>
                  <div className="border-left-tip">
                  <div className="d-flex align-items-center">
                    <BulbIcon width="22px" height="auto"/>
                    <Typography> &nbsp; Page Tips</Typography>
                  </div>
                  <div className="d-flex align-items-center">
                    <ul type="square" className="py-2">
                        <li className="py-2">If you have files in other formats, you can convert it to an accepted file format using any online/offline converter.</li>
                        <li className="py-2">You can configure your import settings and save them for future too!</li>
                    </ul>
                  </div>
                  </div>
              </Grid>
            </Grid>
        </Box>
        
        </>
    }

    itemImportPage = () => {
        return <>
        <Box mt={5}>
            <Grid container xs={12} className="card-style">
              <Grid item xs={12} className="ps-4">
                 <Typography className="pt-4">Your Selected File : {this.state.file}</Typography>
                 <Typography className="pt-4">The best match to each field on the selected file have been auto-selected.</Typography>
                 <Typography className="pt-4 fw-bold">Item Details</Typography>
                 <Grid container xs={6} className="pt-4">
                    <Grid item xs={6} className="text-muted">INVENTORY FIELD</Grid>
                    <Grid item xs={6} className="text-muted">IMPORTED FILE HEADERS</Grid>
                 </Grid>
                 <Grid container xs={6} className="pt-4">
                    <Grid item xs={6} className="">item name</Grid>
                    <Grid item xs={6} className="">
                    <Form.Select value={this.state.name} 
                    onChange={(e) => 
                        this.setState({
                            name : e.target.value
                        })}
                    >
                        <option value="1">Default select</option>
                        <option value="2">sample select 1</option>
                        <option value="3">sample select 2</option>
                    </Form.Select>
                    </Grid>
                 </Grid>
                 <Grid container xs={6} className="pt-4">
                    <Grid item xs={6} className="">brand</Grid>
                    <Grid item xs={6} className="">
                    <Form.Select value={this.state.brand} 
                    onChange={(e) => 
                        this.setState({
                            brand : e.target.value
                        })}
                    >
                        <option value="1">Default select</option>
                        <option value="2">sample select 1</option>
                        <option value="3">sample select 2</option>
                    </Form.Select>
                    </Grid>
                 </Grid>
                 <Grid container xs={6} className="pt-4">
                    <Grid item xs={6} className="">sku</Grid>
                    <Grid item xs={6} className="">
                    <Form.Select value={this.state.sku} 
                    onChange={(e) => 
                        this.setState({
                            sku : e.target.value
                        })}
                    >
                        <option value="1">Default select</option>
                        <option value="2">sample select 1</option>
                        <option value="3">sample select 2</option>
                    </Form.Select>
                    </Grid>
                 </Grid>
                 <Grid container xs={6} className="pt-4">
                    <Grid item xs={6} className="">type</Grid>
                    <Grid item xs={6} className="">
                    <Form.Select value={this.state.type} 
                    onChange={(e) => 
                        this.setState({
                            type : e.target.value
                        })}
                    >
                        <option value="1">Default select</option>
                        <option value="2">sample select 1</option>
                        <option value="3">sample select 2</option>
                    </Form.Select>
                    </Grid>
                 </Grid>
                 <Grid container xs={6} className="pt-4">
                    <Grid item xs={6} className="">weight</Grid>
                    <Grid item xs={6} className="">
                    <Form.Select value={this.state.weight} 
                    onChange={(e) => 
                        this.setState({
                            weight : e.target.value
                        })}
                    >
                        <option value="1">Default select</option>
                        <option value="2">sample select 1</option>
                        <option value="3">sample select 2</option>
                    </Form.Select>
                    </Grid>
                 </Grid>
                 <Grid container xs={6} className="pt-4">
                    <Grid item xs={6} className="">dimensions</Grid>
                    <Grid item xs={6} className="">
                    <Form.Select value={this.state.dimensions} 
                    onChange={(e) => 
                        this.setState({
                            dimensions : e.target.value
                        })}
                    >
                        <option value="1">Default select</option>
                        <option value="2">sample select 1</option>
                        <option value="3">sample select 2</option>
                    </Form.Select>
                    </Grid>
                 </Grid>
                 <Grid container xs={6} className="pt-4">
                    <Grid item xs={6} className="">inbound date</Grid>
                    <Grid item xs={6} className="">
                    <Form.Select value={this.state.inbound_date} 
                    onChange={(e) => 
                        this.setState({
                            inbound_date : e.target.value
                        })}
                    >
                        <option value="1">Default select</option>
                        <option value="2">sample select 1</option>
                        <option value="3">sample select 2</option>
                    </Form.Select>
                    </Grid>
                 </Grid>
                 <Grid container xs={6} className="pt-4">
                    <Grid item xs={6} className="">cost prices</Grid>
                    <Grid item xs={6} className="">
                    <Form.Select value={this.state.cost_prices} 
                    onChange={(e) => 
                        this.setState({
                            cost_prices : e.target.value
                        })}
                    >
                        <option value="1">Default select</option>
                        <option value="2">sample select 1</option>
                        <option value="3">sample select 2</option>
                    </Form.Select>
                    </Grid>
                 </Grid>
                 <Grid container xs={6} className="pt-4">
                    <Grid item xs={6} className="">selling prices</Grid>
                    <Grid item xs={6} className="">
                    <Form.Select value={this.state.selling_prices} 
                    onChange={(e) => 
                        this.setState({
                            selling_prices : e.target.value
                        })}
                    >
                        <option value="1">Default select</option>
                        <option value="2">sample select 1</option>
                        <option value="3">sample select 2</option>
                    </Form.Select>
                    </Grid>
                 </Grid>
                 <Grid container xs={6} className="pt-4">
                    <Grid item xs={6} className="">opening stock</Grid>
                    <Grid item xs={6} className="">
                    <Form.Select value={this.state.opening_stock} 
                    onChange={(e) => 
                        this.setState({
                            opening_stock : e.target.value
                        })}
                    >
                        <option value="1">Default select</option>
                        <option value="2">sample select 1</option>
                        <option value="3">sample select 2</option>
                    </Form.Select>
                    </Grid>
                 </Grid>
                 <Typography className="pt-4 fw-bold">Others</Typography>
                 <Grid container xs={6} className="pt-4">
                    <Grid item xs={6} className="text-muted">INVENTORY FIELD</Grid>
                    <Grid item xs={6} className="text-muted">IMPORTED FILE HEADERS</Grid>
                 </Grid>
                 <Grid container xs={6} className="pt-4">
                    <Grid item xs={6} className="">warehouse name</Grid>
                    <Grid item xs={6} className="">
                    <Form.Select value={this.state.warehouse} 
                    onChange={(e) => 
                        this.setState({
                            warehouse : e.target.value
                        })}
                    >
                        <option value="1">Default select</option>
                        <option value="2">sample select 1</option>
                        <option value="3">sample select 2</option>
                    </Form.Select>
                    </Grid>
                 </Grid>

                    <Grid container xs={12} className="my-5">
                        <Button variant="outline-secondary" className='me-5 d-flex justify-content-center align-items-center'
                        onClick={() => this.setState({pgState: this.state.pgState - 1})}><BackIcon width="18px" height="auto" className="me-2"/> Previous</Button>
                        <Button variant="primary" 
                        onClick={() => this.setState({pgState: this.state.pgState + 1})}
                        >Next</Button>
                        <Button variant="outline-secondary" className='mx-3'>Cancel</Button>
                    </Grid>
              </Grid>
            </Grid>
        </Box>
        
        </>
    }

    itemPreviewPage = () => {
        return <>
        <Box mt={5}>
            <Grid container xs={12} className="card-style">
              <Grid item xs={12}>
                  {/* will show as alert message here */}
                  <Typography className="bg-info p-3 rounded mx-5 text-light">3 of 7 Items in your file are ready to be imported.</Typography> 
                  <Accordion flush className="mx-5 mt-5">
                <Accordion.Item eventKey="0">
                    {/* success result of import items */}
                    <Accordion.Header>Items that are ready to be imported <span>( 3 )</span></Accordion.Header>
                    <Accordion.Body>
                        <ul type="disc">
                            <li>Items to be created ( 3 )</li>
                            <li>Items waiting to be overwritten ( 0 )</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    {/* skip result of import items */}
                    <Accordion.Header>No. of Records skipped <span>( 4 )</span></Accordion.Header>
                    <Accordion.Body>
                        <table className="table-skip">
                            <tr>
                                <td>2</td>
                                <td>Economy Plan</td>
                                <td>Row already exists</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Economy Plan</td>
                                <td>Row already exists</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Economy Plan</td>
                                <td>Row already exists</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Economy Plan</td>
                                <td>Row already exists</td>
                            </tr>
                        </table>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    {/* skip result of import items */}
                    <Accordion.Header>Unmapped Fields</Accordion.Header>
                </Accordion.Item>
                </Accordion>

                <Grid container xs={12} className="my-5 mx-5">
                        <Button variant="outline-secondary" className='me-5 d-flex justify-content-center align-items-center'
                        onClick={() => this.setState({pgState: this.state.pgState - 1})}><BackIcon width="18px" height="auto" className="me-2"/> Previous</Button>
                        <Button variant="primary" 
                        onClick={() => this.setState({})}
                        >Import</Button>
                        <Button variant="outline-secondary" className='mx-3'>Cancel</Button>
                    </Grid>

              </Grid>
            </Grid>
        </Box>
        
        </>
    }

    render() {
        return <Box><StickyPageHeader>
                <Container>
                    <BreadCrumb />
                    <h1 className="fs-5 px-4 mx-2 pt-3">
                        {this.state.pgState == 1 ? 'Items - Select File' : 
                        this.state.pgState == 2 ? 'Map Fields' :
                        this.state.pgState == 3 ? 'Preview' : 'Items - Select File'
                    }</h1><br />
                    <div className="d-flex align-items-center justify-content-start px-4 mx-2 mb-4">
                        <span className="number-wrap">1</span>
                        <span className="px-2">Configure</span>
                        <span style={this.state.pgState == 2 || this.state.pgState == 3 ? {display:'inline-block', width:'100px', borderBottom:'1px solid black', lineHeight:'0'} : {display:'inline-block', width:'100px', borderBottom:'1px solid #ccc', lineHeight:'0'}}>&nbsp;</span>
                        <span className={this.state.pgState == 2 || this.state.pgState == 3 ? "number-wrap" : "number-wrap-muted"}>2</span>
                        <span className={this.state.pgState == 2 || this.state.pgState == 3 ?"px-2" :  "text-muted px-2"}>Map Fields</span>
                        <span style={this.state.pgState != 3 ?{display:'inline-block', width:'100px', borderBottom:'1px solid #ccc', lineHeight:'0'} : {display:'inline-block', width:'100px', borderBottom:'1px solid black', lineHeight:'0'}}>&nbsp;</span>
                        <span className={this.state.pgState != 3 ? "number-wrap-muted":"number-wrap"}>3</span>
                        <span className={this.state.pgState != 3 ? "text-muted px-2":"px-2"}>Preview</span>
                    </div>
                </Container>
                </StickyPageHeader>
            <Container maxWidth="lg" >
                <Box mt={2}>
                    {
                    (!this.state.isLoading) ?
                        <Box mt={2} >
                            <Typography variant="h5"> {this.state.data.name}</Typography>
                            { this.state.pgState == 1 ? this.itemDetailPage()  :
                              this.state.pgState == 2 ? this.itemImportPage()  :
                              this.state.pgState == 3 ? this.itemPreviewPage() : this.itemDetailPage()}
                        </Box>
                        :
                        <Card>
                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
                                <CircularProgress size={35} />
                            </Box>
                        </Card>
                    }
                </Box>
            </Container>
      </Box>

    }
}

export default ImportItem;