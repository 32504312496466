import React, { Component } from 'react';

import { 
    Box,
    CircularProgress,
    Grid,
    Card,
    Container,
    Tab,
    Tabs,
    Typography,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Modal
} from '@material-ui/core';

import { Row, Label, Input, Col, Table, ButtonDropdown, Dropdown} from "reactstrap";
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import IconButton from "../../components/Button/IconButton";
import apiUtil from "../../api/apiUtil";
import wmsApi from "../../api/wmsApi";
import queryString from 'query-string';
import CustomDialog from '../../components/Dialog/CustomDialog';
import RemoveDialog from '../../components/Dialog/RemoveDialog';
import CustomInput from "../../components/CustomFields/CustomInput";
import moment from 'moment'
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as CalendarIcon } from '../../assets/img/icons/calendar.svg';
import CustomTable from '../../components/Table/Table.jsx';
import { RoleAccessService } from '../../data/role-access';

import salesOrderApi from "../../api/SalesOrder";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={2}>
            {children}
          </Box>
        )}
      </div>
    );
}
const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`
    };
}

let urlQuery = queryString.parse(window.location.search);
class SaleOrderDetails extends Component {

  rowData = [];
    constructor(props) {
        super(props)

          let grantedAccess = new RoleAccessService();
          this.state = {
            grantedAccess : grantedAccess,
            currentRole: window.localStorage.getItem('current_role') || null,
            data: '',
            isLoading: false,
            tabValue: 0,
            isChecked: false,
            voidDialog: false,
            cancelItemDialog: false,
            tempItemOrder: '',
            selectedRows: [],
            order_items: [],
            return_date: '',
            retrun_warehouse_name: ''
        }
    }
    
    componentDidMount() {
        if (urlQuery.id !== undefined) {
            this.setState({
              dataId: urlQuery.id
            });
            this.callDetailsApi(urlQuery.id, (data) => {
                if(data) {
                    this.setState({
                      tempItemOrder: data,
                      order_items: data.order_items
                    })
                }
            })
        }
    }

    callDetailsApi = (id = '', callback = null) => {
        const  accessToken = localStorage.getItem("access_token");

        this.setState({
            isLoading: true
        }, () => {
            wmsApi.read('orders/' + id, null, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if(callback){
                    callback((data && data.result) ? data.result : null);
                    }
                });
                }, (error, type) => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if(callback){
                    callback(null, []);
                    }
                });
                });
            });
        });
    }

    handleChange = (event, newValue) => {
        this.setState({
            tabValue: newValue
        })
    }

    callChangeStatusApi = (id, status, callback = null) => {
      const  accessToken = localStorage.getItem("access_token");
  
      this.setState({
        isLoading: true
      }, () => {
        let param = {
          order_status: status,
          order_reason: this.state.order_reason
        };
        let return_date = this.state.return_date 
        let retrun_warehouse_name = this.state.retrun_warehouse_name 

        if (return_date) {
          param.return_date = return_date
        }

        if (retrun_warehouse_name) {
          param.return_warehouse = retrun_warehouse_name
        }

  
        wmsApi.update('orders/change_status/' + id, param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(data);
                  this.callDetailsApi(id)
                }
              });
            }, (error, type) => {
              this.setState({
                isLoading: false,
              }, () => {
                apiUtil.toast(error, 'error');
                callback()
              });
            });
        });
      });
    }
    callCancelItemApi= (callback=null) =>{
      const  accessToken = localStorage.getItem("access_token");
  
      this.setState({
        isLoading: true
      }, () => {
        let param = {
          item_status: "Cancelled"
        };
  
      let selectedRow = this.state.selectedRows;
      let order_items = this.state.order_items.length > 0 ? this.state.order_items.filter(it => it.order_item_status.name == "Pending") : [];
      let ids = '';
      if(selectedRow.length < order_items.length ) {
        selectedRow.map((item, i) => { ids += item.id +','; });
      } else {
        apiUtil.toast('Void the order istead of cancelling all items', 'error');
        this.setState({
          cancelItemDialog: false,
          isLoading: false,
        })
        return
      }
      ids = ids.slice(0, -1);
        wmsApi.update('items/change_status/' + ids, param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                isLoading: false,
                cancelItemDialog: false,
              }, () => {
                if(callback){
                  callback();
                }
              });
            }, (error, type) => {
              this.setState({
                isLoading: false,
                cancelItemDialog: false,
              }, () => {
                if(callback){
                  apiUtil.toast(error, 'success');
                  callback();
                }
              });
            });
        });
      });
    }

    
    customCompleteDialog = () => {
      return <CustomDialog 
        open={this.state.completeDialog}
        title={'Complete'}
        onClose={() => {
          this.setState({
            completeDialog: false,
            openDialogItem: null,
            order_reason: null
          });
        }}
        onOk={() => {
          let row = this.state.dataId;
          this.callChangeStatusApi(row, "Completed", (data) => {
            this.setState({
              completeDialog: false,
              openDialogItem: null,
              order_reason: null
            }, () => {
              if(data) {
                apiUtil.toast('Successfully Completed', 'success');
              }
              this.callDetailsApi(urlQuery.id, (data) => {
                if(data) {
                    this.setState({
                      tempItemOrder: data,
                      order_items: data.order_items
                    })
                }
            })
              
            });
          });
        }}
      >
        <Box>
          <Grid item xs={12}>
              <CustomInput
                  label={'Reasons'}
                  className="input-label--horizontal"
                  value={this.state.order_reason}
                  multiline={true}
                  rows={3}
                  onChange={e => {
                      this.setState({
                        order_reason: e.target.value
                      })
                  }}
              />
          </Grid>
  
        </Box>
      </CustomDialog>
    }
  
    customVoidDialog = () => {
      return <CustomDialog 
        open={this.state.openVoidDialog}
        title={'Void'}
        onClose={() => {
          this.setState({
            openVoidDialog: false,
            openDialogItem: null,
            order_reason: null
          });
        }}
        onOk={() => {
          let row = this.state.dataId;
          this.callChangeStatusApi(row, "Void", (data) => {
            this.setState({
              openVoidDialog: false,
              openDialogItem: null,
              order_reason: null
            }, () => {
              if(data) {
                apiUtil.toast('Successfully Void', 'success');
              }
              this.callDetailsApi(urlQuery.id, (data) => {
                if(data) {
                    this.setState({
                      tempItemOrder: data,
                      order_items: data.order_items
                    })
                }
            })
            });
          });
        }}
      >
        <Box>
          <Grid item xs={12}>
              <CustomInput
                  label={'Reasons'}
                  className="input-label--horizontal"
                  value={this.state.order_reason}
                  multiline={true}
                  rows={3}
                  onChange={e => {
                      this.setState({
                        order_reason: e.target.value
                      })
                  }}
              />
          </Grid>
  
        </Box>
      </CustomDialog>
    }
  
    removeDialog = () => {
      return <RemoveDialog
      modal={this.state.deleteModal}
      onRemove={(isRemove) => {
        if (isRemove) {
          // let data = this.state.data.filter(r => r.id !== this.state.modalItem);
  
          salesOrderApi.delete(this.state.dataId).then(data => {
            if (data.data.status === true) {
              apiUtil.toast(data.data.message, 'success');
              this.callDetailsApi(urlQuery.id, (data) => {
                if(data) {
                    this.setState({
                      tempItemOrder: data,
                      order_items: data.order_items
                    })
                }
            })
            } else {
              apiUtil.toast(data.data.message, 'warning');
              this.callDetailsApi(urlQuery.id, (data) => {
                if(data) {
                    this.setState({
                      tempItemOrder: data,
                      order_items: data.order_items
                    })
                }
            })
            }
          }).catch(err => {
            apiUtil.toast(err.message, 'error');
          });
  
        
        }
  
        this.setState({
          deleteModal: false,
          modalItem: null
        });
      }}
      />
    }

    itemDetailPage = () => {
        return <>
        <Box mt={5}>
            <Grid container>
               <Grid item xs={12}>
                   <h2 className='fs-3'>OUTBOUND ORDER</h2>
                   <p>Ref No# {this.state.tempItemOrder && this.state.tempItemOrder.reference_no}</p>
                   <h2 className='mt-5 fs-4'>Status</h2>
                   <div className="border-status">
                       <div className="status-width d-flex"><p className="w-50 fw-bold">Order</p> <p >
                        {this.state.tempItemOrder && this.state.tempItemOrder.order_status && this.state.tempItemOrder.order_status.status == 'Confirmed' ? <div className='text-white py-2 px-4' style={{backgroundColor:'#AE0264'}}>CONFIRMED</div> : ''}
                        {this.state.tempItemOrder && this.state.tempItemOrder.order_status && this.state.tempItemOrder.order_status.status == 'Void' ? <div className='text-white py-2 px-4' style={{backgroundColor:'#EA5455'}}>Void</div> : ''}
                        {this.state.tempItemOrder && this.state.tempItemOrder.order_status && this.state.tempItemOrder.order_status.status == 'Returned' ? <div className='text-white py-2 px-4' style={{backgroundColor:'#FF6600'}}>RETURNED</div> : ''}
                        {this.state.tempItemOrder && this.state.tempItemOrder.order_status && this.state.tempItemOrder.order_status.status == 'Completed' ? <div className='text-white py-2 px-4' style={{backgroundColor:'#5CB85C'}}>COMPLETED</div> : ''}
                        </p></div>
                       <div className="status-width d-flex"><span className="w-50 fw-bold">Invoice</span> <span className="text-muted">-</span></div>
                       <div className="status-width d-flex"><span className="w-50 fw-bold">Payment</span> <span className="text-success">-</span></div>
                       <div className="status-width d-flex"><span className="w-50 fw-bold">Shipment</span> <span className="text-warning">-</span></div>
                   </div>
                   <div className="status-width-long d-flex mt-5"><p className="w-50 fw-bold">Reference#</p> <p className=''>{this.state.tempItemOrder && this.state.tempItemOrder.reference_no}</p></div>
                   <div className="status-width-long d-flex"><p className="w-50 fw-bold">ORDER DATE</p> <p className=''>{this.state.tempItemOrder && moment(this.state.tempItemOrder.handling_in_date).format('YYYY-MM-DD')}</p></div>
                   <div className="status-width-long d-flex"><p className="w-50 fw-bold">RECIPIENT NAME</p> <p className=''>{this.state.tempItemOrder && this.state.tempItemOrder.end_user ?this.state.tempItemOrder.end_user.end_user: '-' }</p></div>
                   <div className="status-width-long d-flex"><p className="w-50 fw-bold">DELIVERY METHOD</p> <p className=''>-</p></div>


                      <Table className="mb-5 item-table-header">
                        <thead>
                          <tr>
                            <th>ITEMS & DESCRIPTION</th>
                            <th>WAREHOUSE NAME</th>
                            <th>STATUS</th>
                            <th>QUANTITY</th>
                            {/* <th>Stock On Hand</th> */}
                            {/* <th>Comitted Stock</th> */}
                            <th>Selling Price</th>
                            <th>AMOUNT</th>
                          </tr>
                      </thead>
                        <tbody>
                        {/* item : "adsf 3",
                        order: "asdf 3",
                        warehouse: "evfy-data",
                        status : "sd",
                        rate : 3.00,
                        discount: 10,
                        amount : 200.00, */}
                        {this.state.tempItemOrder && this.state.tempItemOrder.order_items.map((item) => 
                      <tr>
                        <td><a href={'/items/item-detail?id=' + item.item_id}>{item.item && item.item.name ? item.item.name : '-' }</a></td>
                        <td><a href={'/warehouse/warehouse-detail?id=' + item.item.warehouse.id}>{item.item && item.item.warehouse && item.item.warehouse.name ? item.item.warehouse.name : "-" }</a></td>
                        <td>
                          {item && item.order_item_status && item.order_item_status.name == 'Returned' ? <div style={{color: 'red'}}>RETURNED</div> :
                           item.order_item_status.name != 'Returned' ? item.order_item_status.name : '-'}
                         </td>
                        <td>{item && item.item_qty}</td>
                        {/* <td>{ item.item && item.item.stock_on_hand ? item.item.stock_on_hand : '-' }</td> */}
                        {/* <td>{item.item.committed_stock}</td> */}
                        <td>{item.item && item.item.selling_prices ? item.item.selling_prices : '-' }</td>
                        <td>{item.item && item.item.selling_prices ? item && item.item_qty *  item.item.selling_prices :'-'}</td>
                      </tr>
                      )}
                      </tbody>
                      </Table>
                      <Row>
                        <Col md={6} xs={12} className="p-2 align-self-end">
                          <Label className="w-100 mb-2">Remarks</Label>
                          <Label className="w-100 mb-2">{this.state.tempItemOrder.remarks && this.state.tempItemOrder.remarks}</Label>
                        </Col>
                        <Col md={6} xs={12} className="p-2 bg-light rounded">
                          <Row className="d-flex justify-content-between p-3">
                            <Col>Sub Total</Col>
                            <Col className="text-end">
                              {this.state.tempItemOrder.total_balance}
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-between p-3">
                            <Col className="d-flex ">
                              <Label className="fw-bold">Total ( SGD )</Label>
                            </Col>
                            <Col className="text-end fw-bold">
                              {this.state.tempItemOrder.total_balance}
                            </Col>
                          </Row>
                        </Col>
                      </Row>


               </Grid>
            </Grid>
            
        </Box>
        
        </>
    }

    getDetails = () => {
        return <>
        <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={3}>
        <Tabs value={this.state.tabValue} onChange={this.handleChange} aria-label="basic tabs example">
          <Tab label="Order Details" {...a11yProps(0)} />
          {/* <Tab label="History" {...a11yProps(1)} /> */}
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      {/* <TabPanel value={this.state.tabValue} index={0}> */}
        {this.itemDetailPage()}
      {/* </TabPanel> */}
      <TabPanel value={this.state.tabValue} index={1}>
        {/* {this.itemHistory()} */}
      </TabPanel>
      {/* <TabPanel value={this.state.tabValue} index={2}>
        Item Three
      </TabPanel> */}
    </Box>
        </>
    }

    handleCloseVoid = () => {
        this.setState({
            voidDialog: false,
        })
    }

    voidModal = () => {
        return <Modal
        open={this.state.voidDialog}
        onClose={this.handleCloseVoid}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="d-flex align-self-center justify-content-center"
      >
        <Box className="bg-white w-50 d-flex justify-content-center align-self-center rounded">
            <div className="text-center p-5">
          <Typography id="modal-modal-title" variant="h6" component="h2" className="text-center">
          Enter a reason for marking this transaction as Void.
          </Typography>
          <textarea row="5" className='w-100'>
          </textarea>
          <div className="w-100 ">
              <button className="btn btn-info text-white me-3">Void it</button>
              <button className="btn btn-secondary " onClick={this.handleCloseVoid}>Cancel</button>
          </div>
          </div>
        </Box>
      </Modal>
    }


    handleCloseCancelItem = () => {
        this.setState({
            cancelItemDialog: false,
        })
    }

    handleOnChange = () => {
        this.setState({
            isChecked : !this.state.isChecked,
        })
      };

    customOrderItemTable = () => {
      return <CustomTable 
        ref={this.refTable}
        className={'custom-table-list'}
        isLoading={this.state.isLoading}
        paging={false}
        selection={true}
        data={this.state.order_items.filter(it => it.order_item_status.name == "Pending")}
        onSelectionChange={(selectedRows) => {
          
          this.setState({
            selectedRows : selectedRows
          },() => {
            this.rowData=selectedRows
          } )
        }}
        // Row={this.customRow}
        columns={[
          
          { 
              title: "Item Name ", 
              field: "name",
              render: (row, type) => {
                return (
                  <div >{row.item && row.item.name ? row.item.name : ''}
                  </div>
                );
              }
              
          },
          { 
              title: "PURCHASE ORDER#", 
              field: "order_id",
          }, 
          { 
              title: "Warehouse Name", 
              field: "warehouse_name",
              render: (row, type) => {
                if(type === 'row'){
                    return <Box>
                    {row.item.warehouse.name}
                  </Box>;
                } else {
                    return row;
                }
            }
          },
          { 
              title: "STATIUS", 
              field: "status",
              render: (row, type) => {
                  if(type === 'row'){
                      return <Box>
                      {row.order_item_status.name}
                    </Box>;
                  } else {
                      return row;
                  }
              }
          },
          {
            title: "QUANTITY",
            field: "action",
            render: (row, type) => {
              if(type === 'row'){
                  return <Box>
                  {row.item_qty}
                </Box>;
              } else {
                  return row;
              }
          }
          }
        ]}
      />
    }
    cancelItemModal = () => {
        return <Modal
        open={this.state.cancelItemDialog}
        onClose={this.handleCloseCancelItem}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="d-flex align-self-center justify-content-center"
      >
        <Box className="bg-white w-75 justify-content-center align-self-center rounded">
        <Typography className="bg-light fw-bolder fs-5 p-3">Cancel Items</Typography>

        <Typography id="modal-modal-title" className="text-start px-3 mt-3">
          Choose the items and the quantity to be canceled
        </Typography>

        
                {this.customOrderItemTable()}
                <div className="mx-3"><strong>Note:</strong> The drop shipped line items from the order will not be listed here.</div>

                <div className="bg-warning px-3 py-3 my-3"><input
                    type="checkbox"
                    id="topping"
                    name="topping"
                    value="Paneer"
                    checked={this.state.isChecked}
                    onChange={this.handleOnChange}
                /> I understand that the back ordered items will be dissociated from their respective purchase orders once they’re cancelled.</div>
            <div className="w-100 m-3">
              <button className="btn btn-info text-white me-3" disabled={this.state.isChecked == true && this.state.selectedRows.length > 0 ? false : true}
              onClick={this.callCancelItemApi}
              >Proceed</button>
              <button className="btn btn-secondary " onClick={this.handleCloseCancelItem}>Cancel</button>
          </div>
        </Box>
      </Modal>
    }

    handleSaleRetrun = () => {
      this.setState({
        openSaleRetrun: false,
        retrun_warehouse_name: null,
        return_date: null,
      })
    }

    wareHouseList = () => {

      let order_items = this.state.tempItemOrder && this.state.tempItemOrder.order_items.length > 0 ? this.state.tempItemOrder.order_items : []
      let warehouseList =  order_items.map((item) => item.item && item.item.warehouse.name ?item.item.warehouse.name : '')
      let unquieWarehouseList = [...new Set(warehouseList)]
      return <>
      {unquieWarehouseList.length > 0 ?
      unquieWarehouseList.map((data)=>{
          
         return <option value={data}> {data}</option>
      }) : "" }
      </>
  }
    saleReturnModal = () => {
      return <Modal
      open={this.state.openSaleRetrun}
      onClose={this.handleSaleRetrun}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="d-flex align-self-center justify-content-center"
      >
        <Box className=" w-50 bg-white justify-content-center align-self-center rounded">
        <Typography className="bg-light fw-bolder fs-5 p-3">Sales Return</Typography>

        
                
                <div className=" px-3 py-3 my-3">
                <Grid item xs={12}>
                  <CustomInput
                      label={'Return Date'}
                      className="input-label--horizontal"
                      value={this.state.return_date}
                      type={"date"}
                      endAdornment={(obj) => {
                          return <Box
                              onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                              }}
                          >
                              <CalendarIcon />
                          </Box>
                      }}
                      onChange={e => {
                          this.setState({
                            return_date: e.target.value
                          })
                      }}
                  />
                </Grid>

                <Grid item xs={12}>
                    <Box clone pt={2}>
                      <Box>
                      <InputLabel >Warehouse</InputLabel>
                        <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px", backgroundColor: 'transparent'}}
                          placeholder={'Enter warehouse here...'}
                          native
                          disableUnderline
                          onChange={(e) => {
                            this.setState({
                                retrun_warehouse_name: e.target.value
                            });
                        }}
                        >
                          <option value=""> Select Warehouse </option>
                          {this.wareHouseList()}
                          
                        </Select>

                      </Box>
                        
                    </Box>
                </Grid>

                <Grid item xs={12}>
                  <CustomInput
                      label={'Reasons'}
                      className="input-label--horizontal"
                      value={this.state.order_reason}
                      multiline={true}
                      rows={3}
                      onChange={e => {
                          this.setState({
                            order_reason: e.target.value
                          })
                      }}
                  />
                </Grid>
                </div>
            <div className="w-100 m-3">
              <button className="btn btn-info text-white me-3" disabled={this.state.order_reason && this.state.retrun_warehouse_name  &&  this.state.return_date? false : true}
              onClick={() => {
                    let row = this.state.dataId;
                    this.callChangeStatusApi(row, "Returned",  (data) => {
                      this.setState({
                        openSaleRetrun: false,
                        openDialogItem: null,
                        retrun_warehouse_name: null,
                        return_date: null,
                        order_reason: null
                      }, () => {
                        if(data) {
                          apiUtil.toast('Successfully Returned', 'success');
                        }
                        this.callDetailsApi(urlQuery.id, (data) => {
                          if(data) {
                              this.setState({
                                tempItemOrder: data,
                                order_items: data.order_items
                              })
                          }
                        })
                      });
                    });
                  }}
              >Proceed</button>
              <button className="btn btn-secondary " onClick={this.handleSaleRetrun}>Cancel</button>
          </div>
        </Box>
      </Modal>
    }

    render() {
        return <Box><StickyPageHeader>
              <Container>
                <BreadCrumb />
                <h1 className="page-title">Order Detail</h1>
                <div className='d-flex justify-content-end align-items-center'>
                {this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Outbound Order Add") && <IconButton className="btn-theme btn-theme-1" label="Add New" icon="add" onClick={() => this.props.history.push('./create')} /> }
                {this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Outbound Order Edit") && (this.state.tempItemOrder.order_status && this.state.tempItemOrder.order_status.status == "Confirmed" ) && <IconButton className="btn-theme btn-theme-1" label="Edit " icon="edit" onClick={() => this.props.history.push('./edit?id='+this.state.dataId)} /> }
               
                <label class="dropdown me-3">

                    <div class="dd-button">
                    Actions
                    </div>

                    <input type="checkbox" class="dd-input" id="test" />

                    <ul class="dd-menu">
                    {/* {this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Change Outbound Order Status") &&<li onClick={() => {
                            this.setState({
                                cancelItemDialog: true,
                            })
                        }}>Cancel Items</li> } */}
                        {this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Change Outbound Order Status") && this.state.tempItemOrder && this.state.tempItemOrder.order_status && this.state.tempItemOrder.order_status.status == "Completed" && <li onClick={() => {
                            this.setState({
                              openSaleRetrun: true,
                            })
                        }}>Outbound Return</li>}
                        {/* {this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Change Outbound Order Status") && <li onClick={() => {
                            this.setState({
                                openVoidDialog: true,
                            })
                        }}>Void</li> } */}
                        {this.state.tempItemOrder && this.state.tempItemOrder.order_status && this.state.tempItemOrder.order_status.status == "Returned"  ? '':
                        this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Change Outbound Order Status") && <li onClick={() => {
                            this.setState({
                                completeDialog: true,
                            })
                        }}>Complete</li> }
                        {this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Outbound Order Delete") && <li onClick={() => {
                            this.setState({
                                deleteModal: true,
                            })
                        }}>Delete</li> }
                    </ul>

                </label>
                </div>
                </Container>
            </StickyPageHeader>
            <Container maxWidth="lg" >
                <Box mt={2}>
                    {
                  (!this.state.isLoading)
                  ?
                  <Box mt={2} >
                   <Typography variant="h5"> {this.state.data.name}</Typography>
                   {this.itemDetailPage()}
                   {/* {this.getDetails()} */}
                  </Box>
                  :
                  <Card>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
                      <CircularProgress size={35} />
                    </Box>
                  </Card>
                }
                </Box>
                {this.voidModal()}
                {this.cancelItemModal()}
                {this.customCompleteDialog()}
                {this.customVoidDialog()}
                {this.removeDialog()}
                {this.saleReturnModal()}
            </Container>
      </Box>

    }
}

export default SaleOrderDetails;