import React, { Component } from "react";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { 
 Grid,
 Box,
} from '@material-ui/core';
import { ReactComponent as MenuIcon } from '../../assets/img/icons/menu.svg';

import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import { Row, Card, CardBody, Col, Container } from "reactstrap";
import { ReactComponent as ExportIcon } from '../../assets/img/icons/export.svg';
import customerApi from "../../api/Customer";
import apiUtil from "../../api/apiUtil";
import wmsApi from "../../api/wmsApi";
import DropdownMenu from "../../components/Dropdown/DropdownMenu";
import CustomButton from "../../components/CustomFields/CustomButton";
import Table from '../../components/Table/Table.jsx';
import Select from 'react-select';
import GroupButton from "../../components/GroupButton/GroupButton";
import CustomDateRangePicker from "../../components/CustomFields/CustomDateRangePicker";
import { RoleAccessService } from '../../data/role-access';

import moment from "moment";

class GeneralReport extends Component {
    constructor(porps) {
        super(porps)
        let grantedAccess = new RoleAccessService();
        this.state = {
            grantedAccess : grantedAccess,
            currentRole: window.localStorage.getItem('current_role') || null,
            reportTab: 0,
            rangeDate: {
            startDate: moment().startOf('month'),
            endDate: moment().endOf('month'),
            },
            render_table: "item_movement",
            selectedTable: "Item Movement Summery",
            isLoading: false,
            page: 0,
            limit: 20,
            total: 0,
            data: [],
            customerList: [],
            selectedCustomer: {
              id: '',
              name: ''
            },
            openDialog: false,
            openDialogItem: null,
        }
    }

    componentDidMount = () => {
        this.callReadApi(this.state.render_table)
        if(this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Customer Browse")) {
          this.getCustomer();
        }
        
    }

    
    callReadApi = (search = '') => {
        const  accessToken = localStorage.getItem("access_token");
        let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
        let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

        this.setState({
          isLoading: true,
        }, () => {
          let param = {
              start_date: startDate,
              end_date: endDate,
              customer_id: this.state.selectedCustomer? this.state.selectedCustomer.id : ''
          };
    
          // if (search == 'sale_by_customer') {
          //   param['sale_by_customer'] = true
          // }
          // if (search == 'sale_by_item') {
          //   param['sale_by_item'] = true
          // }
          if (search == 'item_movement' ) {
            param['inventory_summary'] = true
          }
          if (search == 'sale_by_return') {
            param['sale_by_return'] = true
          }
          if (search == 'inbound_order') {
            param['inbound_order'] = true
          }
          if (search == 'outbound_order') {
            param['outbound_order'] = true
          }
          if(search == 'item_movement') {
            wmsApi.read('report/items', param, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                this.setState({
                  data: (data.result && data.result.length > 0) ? data.result : [],
                  total: data.total,
                  isLoading: false,
                });
              }, (error, type) => {
                this.setState({
                  data: [],
                  total: 0,
                  isLoading: false,
                });
              });
          });
          } else {
            wmsApi.read('report/sales', param, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                this.setState({
                  data: (data.result && data.result.length > 0) ? data.result : [],
                  total: data.total,
                  isLoading: false,
                });
              }, (error, type) => {
                this.setState({
                  data: [],
                  total: 0,
                  isLoading: false,
                });
              });
          });
          }
        });
      }

      getCustomer = async () => {
        this.setState({ isLoading: true });
        const { accessToken } = this.context;
        let params = {
        
        };
    
        await customerApi.get(params, accessToken).then(data => {
          if (data.errorMessage !== '') {
            apiUtil.toast(data.errorMessage, 'error');
          } else {
            let customerList = []
            if (data.data.length > 0) {
              customerList.push({
                value: null,
                label: 'Select Warehouse',
                name: 'Select Warehouse'
              })
              data.data.forEach((val) => {
                customerList.push({
                  value: val.id,
                  label: val.company_name,
                  name: val.company_code
                });
              });
            }
            this.setState({
              customerList: customerList,
              isLoading: false,
            });
          }
        }).catch(error => console.log(error));
      }

      handleCustomerChange = (event) => {
        this.setState({
          selectedCustomer: {
            id: event.value,
            name: event.company_name,
            label: event.company_code
          }
        }, () => {
          this.callReadApi(this.state.render_table);
        });
      }

       /* TABLE */
  // customTable = () => {
  //   return <Table 
  //     ref={this.refTable}
  //     className={'custom-table-list custom-table-sale-return'}
  //     isLoading={this.state.isLoading}
  //     grouping={false}
  //     selection={false}
  //     page={this.state.page}
  //     total={this.state.total}
  //     limit={this.state.limit}
  //     paging={false}
  //     data={this.state.data}
  //     onSelectionChange={(selectedRows) => {
  //       // console.log("selectedRows", selectedRows);
  //     }}
  //     // Row={this.customRow}
  //     columns={[
        
  //       { 
  //           title: "Name ", 
  //           field: "company_name",
  //           render: (row, type) => {
  //             return (
  //               <div >
  //                   {row.customer? row.customer.company_name : ''}
  //               </div>
  //             );
  //           }
            
  //       },  
  //       { 
  //           title: "Invoice Count", 
  //           field: "order_count",
  //       },
  //       { 
  //         title: "Sales", 
  //         field: "amount"
  //       }
  //     ]}
  //   />
  // }
    /* TABLE */
    customTable = () => {
      return <Table 
        ref={this.refTable}
        className={'custom-table-list custom-table-sale-return'}
        isLoading={this.state.isLoading}
        grouping={false}
        selection={false}
        page={this.state.page}
        total={this.state.total}
        limit={this.state.limit}
        data={this.state.data}
        // paging={false}
        onPageChange={(page,limit) => {
          this.setState({
            limit:limit,
            page:page
          }, () => {
            this.callReadApi();
          });
          }}
        onSelectionChange={(selectedRows) => {
          // console.log("selectedRows", selectedRows);
        }}
        Row={this.customRow}
        columns={[
          
          { 
              title: "Item Name ", 
              field: "name",
              
          },  
          { 
              title: "SKU", 
              field: "sku",
          },
          { 
            title: "Warehouse Name", 
            field: "sale_order",
            render: (row, type) => {
              return <div>{row.warehouse ? row.warehouse.name : ''}</div>
               }
          },
          { 
            title: "Quantity In", 
            field: "opening_stock",
          },
          { 
              title: "Quantity Out", 
              field: "quantity_out",
          },
          { 
            title: "Stock On Hand", 
            field: "stock_on_hand",
        },
        { 
          title: "Committed Stock", 
          field: "committed_stock",
          render: (row, type) => {
            return (
              <div >
               {row.committed_stock ? row.committed_stock : 0}
              </div>
            );
          }
        },
        { 
          title: "Available for Sale", 
          field: "available_sale"
        }
        ]}
      />
    }

  orderTable = () => {
    return <Table 
      ref={this.refTable}
      className={'custom-table-list custom-table-sale-return'}
      isLoading={this.state.isLoading}
      grouping={false}
      selection={false}
      page={this.state.page}
      total={this.state.total}
      limit={this.state.limit}
      paging={false}
      data={this.state.data}
      onSelectionChange={(selectedRows) => {
        // console.log("selectedRows", selectedRows);
      }}
      // Row={this.customRow}
      columns={[
        
        { 
            title: "Reference No ", 
            field: "reference_no",
            
            
        },  
        { 
            title: "Date", 
            field: "date",
            render: (row, type) => {
              return (
                <div >
                    {row.handling_in_date? moment(row.handling_in_date).format('DD-MMM-YYYY') : ''}
                </div>
              );
            }
        },
        { 
          title: "Total Weight", 
          field: "total_weight",
          render: (row, type) => {
            let qty_total = 0.00;
            row.order_items && row.order_items.map ((item)=>
              qty_total += parseFloat(item.item && item.item.weight ? item.item.weight: 0 )
            )
            return (
              <div >
                  {qty_total}
              </div>
            );
          }
        },
        { 
          title: "Recipient Name", 
          field: "recipient_name",
          render: (row, type) => {
            return (
              <div >
                  {row.customer ? row.customer.company_name : ''}
              </div>
            );
          }

        },
        { 
          title: "Order Status", 
          field: "order_status",
          render: (row, type) => {
            return (
              <div >
                  {row.order_status ? row.order_status.status : ''}
              </div>
            );
          }
        }
      ]}
    />
  }

  inboundTable = () => {
    return <Table 
      ref={this.refTable}
      className={'custom-table-list custom-table-sale-return'}
      isLoading={this.state.isLoading}
      grouping={false}
      selection={false}
      page={this.state.page}
      total={this.state.total}
      limit={this.state.limit}
      paging={false}
      data={this.state.data}
      onSelectionChange={(selectedRows) => {
        // console.log("selectedRows", selectedRows);
      }}
      // Row={this.customRow}
      columns={[
        
        { 
            title: "Name", 
            field: "reference_no",
            render: (row, type) => {
              return (
                <div >
                    {row.name? row.name : ''}
                </div>
              );
            }
        },  
        { 
            title: "Date", 
            field: "date",
            render: (row, type) => {
              return (
                <div >
                    {row.created_at? moment(row.created_at).format('DD-MMM-YYYY') : ''}
                </div>
              );
            }
        },
        { 
          title: "Total Weight", 
          field: "total_weight",
          render: (row, type) => {
            return (
              <div >
                  {row.weight? row.weight : ''}
              </div>
            );
          }
        },
        { 
          title: "Recipient Name", 
          field: "recipient_name",
          render: (row, type) => {
            return (
              <div >
                  {row.customer ? row.customer.company_name : ''}
              </div>
            );
          }

        },
        { 
          title: "Inbound Date", 
          field: "recipient_name",
          render: (row, type) => {
            return (
              <div >
                  {row.inbound_date ? moment(row.inbound_date).format('DD-MMM-YYYY') : ''}
              </div>
            );
          }

        },
        // { 
        //   title: "Order Status", 
        //   field: "order_status",
        //   render: (row, type) => {
        //     return (
        //       <div >
        //           {row.order_status ? row.order_status.status : ''}
        //       </div>
        //     );
        //   }
        // }
      ]}
    />
  }

  saleItemTable = () => {
    return <Table 
      ref={this.refTable}
      className={'custom-table-list custom-table-sale-return'}
      isLoading={this.state.isLoading}
      grouping={false}
      selection={false}
      page={this.state.page}
      total={this.state.total}
      limit={this.state.limit}
      paging={false}
      data={this.state.data}
      onSelectionChange={(selectedRows) => {
        // console.log("selectedRows", selectedRows);
      }}
      // Row={this.customRow}
      columns={[
        
        { 
            title: "Item Name ", 
            field: "name",
            render: (row, type) => {
              return (
                <div >
                    {row.item ? row.item.name : ''}
                </div>
              );
            }
            
        },
        { 
          title: "SKU", 
          field: "status",
          render: (row, type) => {
                return <div >{row.item ? row.item.sku : ''}</div>
             }
        },
        { 
            title: "Quantity Sold", 
            field: "sold_qty",
        },
        { 
          title: "Amount", 
          field: "amount",
          render: (row, type) => {
                return <div >{row.item ? row.sold_qty * row.item.selling_prices : ''}</div>
             }
        },
      ]}
    />
  }

  saleReturnTable = () => {
    return <Table 
      ref={this.refTable}
      className={'custom-table-list custom-table-sale-return'}
      isLoading={this.state.isLoading}
      grouping={false}
      selection={false}
      page={this.state.page}
      total={this.state.total}
      limit={this.state.limit}
      paging={false}
      data={this.state.data}
      onSelectionChange={(selectedRows) => {
        // console.log("selectedRows", selectedRows);
      }}
      // Row={this.customRow}
      columns={[
        
        { 
            title: "Date ", 
            field: "date",
            render: (row, type) => {
              return (
                <div >
                    {moment(row.handling_in_date).format('DD-MMM-YYYY')}
                </div>
              );
            }
            
        } ,
        { 
            title: "Outbound Order", 
            field: "sale_order",
            render: (row, type) => {
              return <div> 
                    <a href={'/sales-order/order-detail?id=' + row.id}>
                      {row.reference_no}
                    </a>
                  </div>
               }
        },
        { 
          title: "Client Name", 
          field: "sale_order",
          render: (row, type) => {
            return <div>{row.customer ? row.customer.company_name : ''}</div>
             }
        },
        { 
          title: "Amount Invoiced", 
          field: "total_balance",
        },
        { 
          title: "Status", 
          field: "status",
          render: (row, type) => {
                return <div className={row.order_items && row.order_items[0].is_received ? "text-success" : "text-primary" }> {row.order_items && row.order_items[0].is_received ? "Approved": "Not Approved"}</div>
             }
        },
        { 
            title: "Receive Status", 
            field: "receive_status",
            render: (row, type) => {
              return <div className={ row.order_items && row.order_items[0].is_received ? "text-success" : "text-primary" }> {row.order_items && row.order_items[0].is_received ? "Received": "Not Received"}</div>
           }
        }
      ]}
    />
  }

  callExportXLSApi = () => {
    const  accessToken = localStorage.getItem("access_token");

    this.setState({
        downloadIsLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
      let render_table = this.state.render_table
      let param = {
        start_date: startDate,
        end_date: endDate,
        customer_id: this.state.selectedCustomer? this.state.selectedCustomer.id : ''
      };

      // if (render_table == 'sale_by_customer') {
      //   param['sale_by_customer'] = true
      // }
      // if (render_table == 'sale_by_item') {
      //   param['sale_by_item'] = true
      // }

      if (render_table == 'sale_by_return') {
        param['sale_by_return'] = true
      }
      if (render_table == 'inbound_order') {
        param['inbound_order'] = true
      }
      if (render_table == 'outbound_order') {
        param['outbound_order'] = true
      }
      if (render_table == 'item_movement' ) {
        param['inventory_summary'] = true
      }
      if(render_table == 'item_movement' ) {
        wmsApi.exportXLS('admin/api/export/report_items', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (response) => {
            apiUtil.saveToFile(response, `${render_table} ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.xls`);
    
            this.setState({
              downloadIsLoading: false,
            });
          }, (error, type) => {
            this.setState({
              downloadIsLoading: false,
            });
          });
      });
      } else {
      wmsApi.exportXLS('admin/api/export/report_sales', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (response) => {
            apiUtil.saveToFile(response, `${render_table} ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.xls`);
    
            this.setState({
              downloadIsLoading: false,
            });
          }, (error, type) => {
            this.setState({
              downloadIsLoading: false,
            });
          });
      });
    }
    });
  }

    /* EXPORT API */
    callExportPDFApi = () => {
    let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
          let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());
          let pdf = new jsPDF("p", "mm", "a4");
          let render_table = this.state.render_table
          var col = [];
          if (render_table == 'sale_by_customer') {
            col = ["Name", "Invoice count", "sales"];
          }
          if (render_table == 'item_movement') {
            col = ["Item Name","SKU", "Warehouse Name", "Quantity In", "Quantity Out", "Stock On Hand", "Committed Stock ", "Availavbel for Sale" ];
          }
          else if (render_table == 'sale_by_item') {
            col = ["Item Name", "SKU", "Qunatity Sold", "Amount"];
          }
          else if (render_table == 'sale_by_return') {
            col = ["Date", "Outbound Order", "Client Name","Amount Invoiced", "status", "Receive Status"];
          } 
          else if (render_table == 'inbound_order') {
            col = ['Name', 'Date', 'Total Weight', 'Recipient Name', 'Inbound Date'];
          }
          else if (render_table == 'outbound_order') {
            col = ["Reference No","Date", "Total Weight", "Recipient Name", "Order Status"];
          } 
          
          var rows = [];

            let data = this.state.data;
            data.forEach(element => { 
            var temp = [];  
            if (render_table == 'sale_by_customer') {
              temp = [element.customer.company_name, element.order_count, element.amount];
            }
            if (render_table == 'item_movement') {
              temp = [element.name, element.sku, element.warehouse?element.warehouse.name: '',element.opening_stock, element.quantity_out, element.stock_on_hand, element.committed_stock, element.available_sale ];
            }
            else if (render_table == 'sale_by_item') {
              temp = [element.item ? element.item.name : element.name, element.item ? element.item.sku : element.sku, element.sold_qty, element.sold_qty * element.item ? element.item.selling_prices: 0];
            }
            else if (render_table == 'sale_by_return') {
              temp = [moment(element.created_at).format('YYYY-MM-DD'), element.reference_no, element.customer.company_name, element.total_balance, element.order_items[0].is_received ? "Approved": "Not Approved" , element.order_items[0].is_received ? "Received": "Not Received"];
            } 
            else if (render_table == 'inbound_order') {
              temp = [element.name, moment(element.created_at).format('YYYY-MM-DD'), element.weight, element.customer? element.customer.company_name: "", moment(element.inbound_date).format('YYYY-MM-DD')];
            }
            else if (render_table == 'outbound_order') {
              let total_Weight = 0;
              element.order_items.map((item) => {
              if(item.item && item.item.hasOwnProperty('weight')) {
                total_Weight += parseFloat(item.weight)
                }
                if(isNaN(total_Weight)){
                  total_Weight = 0;
                }
              }
              )
              temp = [element.reference_no, moment(element.created_at).format('YYYY-MM-DD'), element.order_items? total_Weight : '', element.customer? element.customer.company_name: "", element.order_status? element.order_status.status: ""];
            }  
            rows.push(temp);
    
        });        
    
            pdf.autoTable(col, rows, { 
              // startY: 10,
              margin: { horizontal: 10 },
              styles: { overflow: "linebreak" },
              bodyStyles: { valign: "top" },
              columnStyles: { email: { columnWidth: "wrap" } },
              theme: "striped",
              // showHead: "everyPage",
              didDrawPage: function (data) {
                // Header
                pdf.setFontSize(16);
                pdf.setTextColor("#161C22");
        
                // Footer
                let str = "" + pdf.internal.getNumberOfPages();
                pdf.setFontSize(10);
        
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                let pageSize = pdf.internal.pageSize;
                let pageHeight = pageSize.height
                  ? pageSize.height
                  : pageSize.getHeight();
                // pdf.text("Footer text", data.settings.margin.left, pageHeight - 10);
                pdf.text(575, 830, str);
              } });

              if (render_table == 'sale_by_customer') {
                pdf.save(`sale_by_customer ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.pdf`);
              }
              else if (render_table == 'sale_by_item') {
                pdf.save(`sale_by_item ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.pdf`);
              }
              else if (render_table == 'sale_by_return') {
                pdf.save(`sale_by_return ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.pdf`);
              }
              else if (render_table == 'inbound_order') {
                pdf.save(`inbound_order ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.pdf`);
              }
              else if (render_table == 'outbound_order') {
                pdf.save(`outbound_order ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.pdf`);
              } else {
                pdf.save(`${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.pdf`);
              }
            // pdf.save(`Sale Orders ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.pdf`);
  }
  /* END EXPORT API */

    render () {
        let tabs = ['Item Movement', 'Inventory']
        return  <Box className="dashboard-page">
        <StickyPageHeader>
          <Container>
          <BreadCrumb />
          <Row>
            <Col className="col-12 col-md-12 col-lg-4"><h1 className="page-title">Item Movement Reports</h1></Col>
            <Col className="col-12 col-md-12 col-lg-8">
              <Row>
            <Col className="col-12 col-md-6 col-lg-5 d-flex justify-content-center align-items-center">
            <DropdownMenu
                  text={this.state.selectedTable}
                  className={'status-btn ms-3'}
                  variant = 'primary'
                  rightIcon={<ArrowDownIcon />}
                  color={"none"}
                  items={[
                    // {text:"Sales by Customer", table:"sale_by_customer"}, 
                    // {text:"Sales by Item", table:"sale_by_item"}, 
                    {text:"Item Movement Summery", table:"item_movement"},
                    {text:"Outbound Return History", table:"sale_by_return"},
                    {text:"Inbound Order", table:"inbound_order"},
                    {text:"Outbound Order", table:"outbound_order"}
                  ]}
                  onClick={(item) => {
                    this.setState({
                      render_table: item.table,
                      selectedTable: item.text
                    }, () => {
                      this.callReadApi(item.table)
                    })
                  }}
                />
          </Col>
          <Col className="col-12 col-md-6 col-lg-7 d-flex justify-content-evenly align-items-center">
          <CustomDateRangePicker
              className={'select-dates-step w-75'}
              range={this.state.rangeDate}
              onChange={(range) => {
                localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));

                this.setState({
                  rangeDate: range,
                  startDate: range.startDate,
                  endDate: range.endDate
                },() => {
                  this.callReadApi(this.state.render_table);
                });
              }}
            />
             <label class="dropdown me-3 py-2">
              <div class="dd-button-rr secondary">
              <MenuIcon width="26px" height="auto" />
              </div>

              <input type="checkbox" class="dd-input" id="test" />

              <ul class="dd-menu">
                  <li onClick={()=> this.callExportXLSApi()}><ExportIcon width="18px" height="auto" className="mx-2"/> Export CSV</li>
                  <li onClick={()=> this.callExportPDFApi()}><ExportIcon width="18px" height="auto" className="mx-2"/> Export PDF</li>
              </ul>
            </label>
            </Col>
            </Row>
         </Col>
          </Row>
            <Box clone pr={1} ml={4} mt={3}>
                <Grid xs={12} lg={10}>
                    <GroupButton
                    className={'head-tabs'}
                    color={'secondary'}
                    selected={this.state.reportTab}
                    buttons={tabs}
                    onClick={(selected, btn) => {
                        if(selected === 1){
                        this.props.history.push('/report/inventory');
                        }
                    }}
                    />
                </Grid>
            </Box>
            </Container>
        </StickyPageHeader>
        {/* {this.state.render_table == "sale_by_customer" && <Box mt={3}>
            <Box style={{"textAlign": "center"}}>
              <h3 className="report-header">Sales By Customer</h3>
              <p>from {moment(this.state.rangeDate.startDate).format('DD-MMM-YYYY')} to {moment(this.state.rangeDate.endDate).format('DD-MMM-YYYY')}</p>
            </Box>
            <Card>
                <CardBody>
                    <Row>
                        <Col md="12">
                        {this.customTable()}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Box>}
        {this.state.render_table == "sale_by_item" && <Box mt={3}>
            <Box style={{"textAlign": "center"}}>
              <h1 className="report-header">Sales By Item</h1>
              <p>from {moment(this.state.rangeDate.startDate).format('DD-MMM-YYYY')} to {moment(this.state.rangeDate.endDate).format('DD-MMM-YYYY')}</p>
            </Box>
            <Card>
                <CardBody>
                    <Row>
                        <Col md="12">
                        {this.saleItemTable()}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Box>} */}
         {this.state.render_table == "item_movement" && <Box mt={3}>
            <Box style={{"textAlign": "center"}}>
              <h1 className="report-header">Item Movement Summery</h1>
              <p>from {moment(this.state.rangeDate.startDate).format('DD-MMM-YYYY')} to {moment(this.state.rangeDate.endDate).format('DD-MMM-YYYY')}</p>
            </Box>
            <Card>
                <CardBody>
                    <Row>
                    <Col xs="12" md="6" lg="6" className="d-flex align-items-center">
                        <div>
                        Customer Name
                        </div>
                        <div className="ms-2 my-1 w-50">
                          <Select className="option-select" value={{label: this.state.selectedCustomer.name, value: this.state.selectedCustomer.id}} options={this.state.customerList} onChange={(e) => this.handleCustomerChange(e)} />
                        </div>
                      </Col>
                        <Col md="12">
                        {this.customTable()}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Box>}
        {this.state.render_table == "sale_by_return" && <Box mt={3}>
            <Box style={{"textAlign": "center"}}>
              <h1 className="report-header">Sales By Return</h1>
              <p>from {moment(this.state.rangeDate.startDate).format('DD-MMM-YYYY')} to {moment(this.state.rangeDate.endDate).format('DD-MMM-YYYY')}</p>
            </Box>
            <Card>
                <CardBody>
                    <Row>
                    <Col xs="12" md="6" lg="6" className="d-flex align-items-center">
                          <div>
                          Customer Name
                          </div>
                          <div className="ms-2 my-1 w-50">
                            <Select className="option-select" value={this.state.selectedCustomer} options={this.state.customerList} onChange={(e) => this.handleCustomerChange(e)} />
                          </div>
                        </Col>
                        <Col md="12">
                        {this.saleReturnTable()}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Box>}
        {(this.state.render_table == "outbound_order")  && <Box mt={3}>
            <Box style={{"textAlign": "center"}}>
              <h1 className="report-header">{this.state.selectedTable}</h1>
              <p>from {moment(this.state.rangeDate.startDate).format('DD-MMM-YYYY')} to {moment(this.state.rangeDate.endDate).format('DD-MMM-YYYY')}</p>
            </Box>
            <Card>
                <CardBody>
                    <Row>
                    <Col xs="12" md="6" lg="6" className="d-flex align-items-center">
                        <div>
                        Customer Name
                        </div>
                        <div className="ms-2 my-1 w-50">
                          <Select className="option-select" value={this.state.selectedCustomer} options={this.state.customerList} onChange={(e) => this.handleCustomerChange(e)} />
                        </div>
                      </Col>
                        <Col md="12">
                        {this.orderTable()}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Box>}
        {(this.state.render_table == "inbound_order")  && <Box mt={3}>
            <Box style={{"textAlign": "center"}}>
              <h1 className="report-header">{this.state.selectedTable}</h1>
              <p>from {moment(this.state.rangeDate.startDate).format('DD-MMM-YYYY')} to {moment(this.state.rangeDate.endDate).format('DD-MMM-YYYY')}</p>
            </Box>
            <Card>
                <CardBody>
                    <Row>
                        <Col md="12">
                        <Col xs="12" md="6" lg="6" className="d-flex align-items-center">
                          <div>
                          Customer Name
                          </div>
                          <div className="ms-2 my-1 w-50">
                            <Select className="option-select" value={this.state.selectedCustomer.name} options={this.state.customerList} onChange={(e) => this.handleCustomerChange(e)} />
                          </div>
                        </Col>
                        {this.inboundTable()}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Box>}
        
      </Box>
    }
}

export default GeneralReport;